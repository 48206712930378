import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Form, Input, Row, Col, Button, Select, notification, Switch } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { getRoleAssign, postRoleAssign, putRoleAssign } from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";

const RoleAssignForm = () => {
  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  
  const selectedRoleAssign = useSelector(
    (state) => state.roleAssign.state.selectedRoleAssign
  );
  const [BRANCH_NAME] = [selectedRoleAssign?.BRANCH_NAME.split(",")];
  console.log(BRANCH_NAME);
  const [DEPARTMENT] = [selectedRoleAssign?.DEPARTMENT.split(",")];
  console.log(DEPARTMENT);
  const { Option } = Select;
  const validationSchema = Yup.object({
    ROLE_ID: Yup.string().required("Required"),
    EMP_ID: Yup.string().required("Required"),
    STATUS: Yup.boolean().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedRoleAssign) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedRoleAssign]);

  const roleIdList = useSelector(
    (state) => state.employee.data.roleIdList?.data
  );
  const empIDList = useSelector((state) => state.employee.data.employeeList?.data);
  const departmentIdList = useSelector(
    (state) => state.employee.data.departmentIdList?.data
  );
  const branchIdList = useSelector(
    (state) => state.employee.data.branchIdList?.data
  );

  const initialValues = selectedRoleAssign ||{
    ROLE_ID: selectedRoleAssign?.ROLE_ID || "",
    EMP_ID: selectedRoleAssign?.EMP_ID || "",
    DEPARTMENT_ID: [],
    BRANCH_ID: [],
    STATUS: selectedRoleAssign?.STATUS || true,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const action = edit
        ? await dispatch(putRoleAssign(values))
        : await dispatch(postRoleAssign(values));

      if (action.payload.status < 300) {
        dispatch(toggleNewDialog(false));
        dispatch(getRoleAssign());
        if (action.payload.data.code === 200) {
          api.success({
            message: "Form Submitted Successfully.",
          });
        } else if (action.payload.data.code === 304) {
          api.error({
            message: "Already Exist",
          });
      }
     } 
     else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form
              className=""
              onFinish={handleSubmit}
              onFinishFailed={(errorInfo) => {
                console.error("Failed:", errorInfo);
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.ROLE_ID && errors.ROLE_ID ? errors.ROLE_ID : ""}
                    validateStatus={
                      touched.ROLE_ID && errors.ROLE_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Role Name</span>
                    <Select
                      placeholder="Select Role Name"
                      onChange={(value) => setFieldValue("ROLE_ID", value)}
                      value={values.ROLE_ID}
                      className="rounded"
                    >
                      {roleIdList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {type.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.EMP_ID && errors.EMP_ID ? errors.EMP_ID : ""}
                    validateStatus={
                      touched.EMP_ID && errors.EMP_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Employee Name</span>
                    <Select
                      placeholder="Select Employee Name"
                      onChange={(value) => setFieldValue("EMP_ID", value)}
                      value={values.EMP_ID}
                      className="rounded"
                    >
                      {empIDList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {`${type.FIRST_NAME} ${type.MIDDLE_NAME} ${type.LAST_NAME}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? errors.DEPARTMENT_ID
                        : ""
                    }
                    validateStatus={
                      touched.DEPARTMENT_ID && errors.DEPARTMENT_ID
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Department Name</span>
                    <Select
                    defaultValue={DEPARTMENT}
                      mode="multiple"
                      placeholder="Select Department Name"
                      onChange={(value) => setFieldValue("DEPARTMENT_ID", value)}
                      // value={values.DEPARTMENT_ID}
                      className="h-[40px] rounded"
                    >
                      {departmentIdList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {type.DEPARTMENT}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.BRANCH_ID && errors.BRANCH_ID ? errors.BRANCH_ID : ""}
                    validateStatus={
                      touched.BRANCH_ID && errors.BRANCH_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Branch</span>
                    <Select
                    defaultValue={BRANCH_NAME}
                      mode="multiple"
                      placeholder="Select Branch"
                      onChange={(value) => setFieldValue("BRANCH_ID", value)}
                      // value={values.BRANCH_ID}
                      className="h-[40px] rounded"
                    >
                      {branchIdList?.map((type) => (
                        <Option key={type.ID} value={type.ID} className="text-gray-400">
                          {type.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Status</span>
                    <div style={{ marginTop: 8 }}>
                      <Switch
                        defaultChecked
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button onClick={handleCancel} type="primary" className="mr-4">
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                      {edit ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RoleAssignForm;
