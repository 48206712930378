import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { getCustomer } from "../store/dataSlice";

const ComponentSearch = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // const handleSubmit = async () => {
  //   try {
  //     const action = await dispatch(
  //       getCustomer({ SEARCH_FILTER: searchQuery })
  //     );

  //     if (action.payload.code === 200) {
  //       getCustomer();
  //     }
  //   } catch (error) {
  //     console.error("An unexpected error occurred:", error);
  //   }
  // };








  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails[0]?.EMP_ID;

  const filterData1 = JSON.parse(localStorage.getItem("filterData"));
  let BRANCH_ID;
  let DEPARTMENT_ID;
  if (ROLE_ID == 10) {
    BRANCH_ID = filterData1[0]?.BRANCH_ID.split(",")?.map(Number);
    DEPARTMENT_ID = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
  }

  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it


  const handleSubmit = async () => {
    if (ROLE_ID == 4) {

      try {
        const action = await dispatch(
          getCustomer({
            SEARCH_FILTER: searchQuery,
            REPORTING_HEAD_ID: [EmpId],
          })
        );

        if (action.payload.code === 200) {
          getCustomer({ REPORTING_HEAD_ID: [EmpId] });
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }

    } else if (ROLE_ID == 10) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);


      try {
        const action = await dispatch(
          getCustomer({
            SEARCH_FILTER: searchQuery,
            BRANCH_ID: branchIds,
            DEPARTMENT_ID: departmentids,

          })
        );

        if (action.payload.code === 200) {
          getCustomer({
            BRANCH_ID: branchIds,
            DEPARTMENT_ID: departmentids,
          });
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }



    } else if (ROLE_ID == 9) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      const branchIds = filterData?.[0]?.BRANCH_ID?.split(",")?.map(Number);
      const departmentids =
        filterData?.[0]?.DEPARTMENT_ID?.split(",")?.map(Number);


      try {
        const action = await dispatch(
          getCustomer({
            SEARCH_FILTER: searchQuery,
            DEPARTMENT_ID: departmentids,
            BRANCH_ID: branchIds,
          })
        );

        if (action.payload.code === 200) {
          getCustomer({
            DEPARTMENT_ID: departmentids,
            BRANCH_ID: branchIds,
          });
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }





    } else {


      try {
        const action = await dispatch(
          getCustomer({ SEARCH_FILTER: searchQuery })
        );

        if (action.payload.code === 200) {
          getCustomer();
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    }
  };




  return (
    <div className="flex items-center">
      <input
        type="text"
        placeholder="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        className="px-2 py-2 border focus:outline-none focus:border-blue-500 rounded-s"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <div
        className="bg-[#096CAE] text-white mr-4 px-2.5 py-[11.4px] rounded-e cursor-pointer"
        onClick={handleSubmit}
      >
        <FaSearch className="text-white " />
      </div>
    </div>
  );
};

export default ComponentSearch;
