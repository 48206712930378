import { combineReducers } from "@reduxjs/toolkit";
import state from "./stateSlice";
import data from "./dataSlice";

const Cartreducer = combineReducers({
  state,
  data,
});

export default Cartreducer;
