import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetAllExpenseType } from "../../../services/ExpenseTypeService";

export const getExpenseType = createAsyncThunk(
  "expenseType/data/get",
  async (data) => {
    const response = await apiGetAllExpenseType(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const dataSlice = createSlice({
  name: "expenseTypeList/data",
  initialState: {
    loading: false,
    expenseTypeList: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.expenseTypeList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpenseType.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseTypeList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getExpenseType.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getExpenseType.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { updateProductList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
