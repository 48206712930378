import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "assetrequestList/state",
  initialState: {
    deleteConfirmation: false,
    selectedAssetRequest: "",
    newDialog: false,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedAssetRequest: (state, action) => {
      state.selectedAssetRequest = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedAssetRequest,
  toggleNewDialog,
} = stateSlice.actions;

export default stateSlice.reducer;
