import ApiService from "./ApiService";

export async function apiGetAllAccount(data) {

  return ApiService.fetchData({
    url: "/api/account/get",
    method: "post",
    data,
  });
}

export async function apiGetAllGroup(data) {

  return ApiService.fetchData({
    url: "/api/accountGroup/get",
    method: "post",
    data,
  });
}

export async function apipostAccount(data) {
  return ApiService.fetchData({
    url: "/api/account/create",
    method: "post",
    data,
  });
}

export async function apiputAccount(data) {
  return ApiService.fetchData({
    url: "/api/account/update",
    method: "put",
    data,
  });
}