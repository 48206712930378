import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetAllRoleAssign,apiPostRoleAssign,apiPutRoleAssign } from "../../../services/RoleAssignService";

export const getRoleAssign = createAsyncThunk(
  "roleAssign/data/getRoleAssign",
  async (data) => {
    const response = await apiGetAllRoleAssign(data);
    return response.data;
  }
);
export const postRoleAssign = createAsyncThunk(
  "roleAssign/data/postRoleAssign",
  async (data) => {
    const response = await apiPostRoleAssign(data);
    return response;
  }
);
export const putRoleAssign = createAsyncThunk(
  "roleAssign/data/putRoleAssign",
  async (data) => {
    const response = await apiPutRoleAssign(data);
    return response;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "roleAssignList/data",
  initialState: {
    loading: false,
    roleAssignList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.branchList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoleAssign.fulfilled, (state, action) => {
        state.loading = false;
        state.roleAssignList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getRoleAssign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRoleAssign.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(postRoleAssign.fulfilled, (state, action) => {})
      .addCase(putRoleAssign.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
