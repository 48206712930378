import { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { FaUser, FaSearch, FaFilter } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import ClientForm from "../Client/components/ClientForm";
import ClientTable from "../Client/components/ClientTable";
import { injectReducer } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedClient } from "./store/stateSlice";
import Clientreducer from "./store/index";
import { getClient } from "./store/dataSlice";
import ClientSearch from "./components/ClientSearch";
injectReducer("client", Clientreducer);

const Client = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.client.state.newDialog);
  console.log(dialog);
  const selectedClient = useSelector(
    (state) => state.client.state.selectedClient
  );
  console.log(selectedClient);
  const handleRefresh = () => {
    dispatch(getClient());
  };

  const onDialog = () => {
    dispatch(toggleNewDialog(true));
    dispatch(setSelectedClient(null));
  };
  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false));
    handleRefresh();
  };
  useEffect(() => {
    handleRefresh();
  }, []);

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Client</div>
          <div className="flex">
            <div className="flex items-center">
              <ClientSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Client</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {selectedClient ? "Edit Client" : "Add New Client"}
              </span>
            }
            open={dialog}
            footer={null}
            onCancel={handleCloseModal}
          >
            <ClientForm handleRefresh={handleRefresh}/>
          </Modal>
        </div>

        <ClientTable handleRefresh={handleRefresh} />
      </div>
    </>
  );
};
export default Client;
