import React, { useEffect } from "react";

import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import SubmitForm from "./Components/SubmitForm";
import FormTable from "./Components/FormTable";
import { injectReducer } from "../../store";
import Formreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedForm } from "./store/stateSlice";
import { getForm } from "./store/dataSlice";
import FormSearch from "./Components/FormSearch";

injectReducer("form", Formreducer);


const Main_Form = () => {
  const dialog = useSelector((state) => state.form.state.newDialog);

  const AddEdit = useSelector((state) => state.form.state.selectedForm);
  const aaa = String(dialog);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedForm(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false));

  };


  const handleRefresh = () => {
    dispatch(getForm());
  };


  useEffect(() => {
    handleRefresh();
  }, [dispatch]);

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Form Detail</div>
          <div className="flex">
            <div className="flex items-center">
              <FormSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Form</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Form" : "Add New Form"}

              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <SubmitForm />
          </Modal>
        </div>


        <FormTable handleRefresh={handleRefresh} />


      </div>
    </>
  );
};

export default Main_Form;
