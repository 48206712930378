import axios from "axios";
// import appConfig from '../configs/app.config'
// import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '../constants/api.constant'
// import { PERSIST_STORE_NAME } from '../constants/app.constant'
// import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: "https://hrm.vkrepo.in",

  // "https://aa5f-2409-40c2-1050-63b6-21b0-b4bf-a794-48ec.ngrok-free.app",
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem("token");

    let accessToken = rawPersistData ? rawPersistData : null;

    // if (accessToken) {
    config.headers["token"] = `${accessToken}`;
    // }
    config.headers["apikey"] = "w3rvs587hbdt9ugs6YvD3K0bgDtPl";

    // ("w9OAxS4zZXSvub0rVCZf5mt4v6K66pj2");

    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => {
    if (response.data.code === 403) {
      localStorage.removeItem("UserData");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    //console.log(response.data);
    return response;
  },
  (error) => {
    const { response } = error;

    // if (response && unauthorizedCode.includes(response.status)) {
    //   store.dispatch(onSignOutSuccess());
    // }

    return Promise.reject(error);
  }
);

export default BaseService;
