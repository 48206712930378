import React, { useEffect, useState } from "react";
import Employeetargetreducer from "./store";
import { injectReducer } from "../../store";
import { useSelector, useDispatch } from "react-redux";


import { FaFilter } from "react-icons/fa";
import EmployeereportTableTable from "./Components/EmployeereportTable";
import { toggleNewDialog } from "./store/stateSlice";
injectReducer("employeereport", Employeetargetreducer);

const Employeereport = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.employeereport.state.newDialog);


  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6 mt-10">
          <div className="text-xl font-bold !text-[#414141]">
            Employee Target Report
          </div>
          <div className="flex">
            <div className="flex items-center">
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
            </div>
          </div>
        </div>

        <EmployeereportTableTable />
      </div>
    </>
  );
};

export default Employeereport;
