import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { FaFilter } from "react-icons/fa";

import ExpenseTypeTable from "./components/ExpenseTypeTable";
import ExpenseTypeSearch from "./components/ExpenseTypeSearch";

import { injectReducer } from "../../store";

import ExpenseTypereducer from "./store";

import { useSelector, useDispatch } from "react-redux";

injectReducer("expenseType", ExpenseTypereducer);

const ExpenseType = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Leave Type</div>
          <div className="flex">
            <div className="flex items-center">
              <ExpenseTypeSearch />
            </div>
          </div>
        </div>
        <ExpenseTypeTable />
      </div>
    </>
  );
};

export default ExpenseType;
