import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllCart,
  apiPostCart,
  apiPutCart,
} from "../../../services/CartService";

export const getCart = createAsyncThunk("cart/data/get", async (data) => {
  const response = await apiGetAllCart(data);
  return response.data;
});

export const postCart = createAsyncThunk("cart/data/post", async (data) => {
  const response = await apiPostCart(data);
  return response.data;
});

export const putCart = createAsyncThunk("cart/data/put", async (data) => {
  const response = await apiPutCart(data);
  return response.data;
});

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const dataSlice = createSlice({
  name: "cartList/data",
  initialState: {
    loading: false,
    cartList: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.stateList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.fulfilled, (state, action) => {
        state.loading = false;
        state.stateList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCart.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(postCart.fulfilled, (state, action) => {})
      .addCase(putCart.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
