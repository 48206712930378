import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import { Form, Input, Dropdown, Upload, Row, Col, Button, Switch, Select, notification, } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
    postAccountgroup,
    putAccountgroup,
    GetAllAccountgroup,

} from "../store/dataSlice";
import { DatePicker, Space } from "antd";

const { TextArea } = Input;
const AssetForm = ({ handleRefresh }) => {
    const [showSaveNext, setShowSaveNext] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const selectedAccountgroup = useSelector(
        (state) => state.accountgroup.state.selectedAccountgroup
    );

    const assetgroupList = useSelector((state) => state?.accountgroup?.data?.accountgroupList?.data);

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const { Option } = Select;
    const validationSchema = Yup.object({
        NAME: Yup.string().required("Required"),
        STATUS: Yup.boolean().required("Required"),
        NAME_MR: Yup.string().required("Required"),
        PARENT_GROUP_ID: Yup.string().required("Required"),
       
    });


    useEffect(() => {
        if (selectedAccountgroup) {
            setEdit(true);
        } else {
            setEdit(false);
        }
    }, [selectedAccountgroup]);
    useEffect(() => {
        dispatch(GetAllAccountgroup())
     }, []);
 


    const initialValues = selectedAccountgroup || {
        NAME: "",
        STATUS: true,
        NAME_MR: "",
        PARENT_GROUP_ID: "",
       
    };
    const handleCancel = () => {
        dispatch(toggleNewDialog(false));
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        //console.log("Form values:", values);
        try {
            const action = edit
                ? await dispatch(putAccountgroup(values))
                : await dispatch(postAccountgroup(values));

            if (action.payload.code < 300) {
                dispatch(GetAllAccountgroup());

                dispatch(toggleNewDialog(false));
                api.success({
                    message: "Form Submitted Successfully.",
                });



            } else {
                console.error(
                    "Error occurred during form submission:",
                    action.payload.error
                );
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        } finally {
            setSubmitting(false);
        }
    };




    return (
        <>
            <div className="mt-4">
                {/* <h2 className="mb-2">Add Distributor</h2> */}
                {contextHolder}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, touched, errors, handleChange, setFieldValue, submitForm, handleBlur }) => (
                        <FormikForm>


                            <Row gutter={16}>
                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={
                                            touched.NAME && errors.NAME
                                                ? errors.NAME
                                                : ""
                                        }
                                        validateStatus={
                                            touched.NAME && errors.NAME
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs">Name</span>

                                        <Input
                                            name="NAME"
                                            placeholder="Name"
                                            value={values.NAME}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={
                                            touched.NAME_MR && errors.NAME_MR
                                                ? errors.NAME_MR
                                                : ""
                                        }
                                        validateStatus={
                                            touched.NAME_MR && errors.NAME_MR
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs">Name Mr</span>

                                        <Input
                                            name="NAME_MR"
                                            placeholder="Name mr"
                                            value={values.NAME_MR}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />

                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.PARENT_GROUP_ID && errors.PARENT_GROUP_ID ? errors.PARENT_GROUP_ID : ""}
                                        validateStatus={touched.PARENT_GROUP_ID && errors.PARENT_GROUP_ID ? "error" : undefined}
                                    >
                                        <span className="text-xs">Parent Group</span>
                                        <Select
                                            showSearch
                                            placeholder="Select PARENT GROUP name"
                                            onChange={(value) => setFieldValue("PARENT_GROUP_ID", value)}
                                            value={values.PARENT_GROUP_ID}
                                            className="h-[40px] rounded"
                                        >
                                            {assetgroupList?.map((parentgroup) => (
                                                <Option
                                                    key={parentgroup.ID}
                                                    value={parentgroup.ID}
                                                    className="text-gray-400"
                                                >
                                                    {parentgroup.NAME}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>


                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col "
                                        help={
                                            touched.STATUS && errors.STATUS
                                                ? errors.STATUS
                                                : ""
                                        }
                                        validateStatus={
                                            touched.STATUS && errors.STATUS
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs ">Status</span>

                                        {/* <Switch
                                            checked={values.STATUS}
                                            onChange={(checked) => setFieldValue("STATUS", checked)}
                                            defaultChecked
                                            style={{ padding: "8px" }}

                                        /> */}
                                        <div style={{ marginTop: 8 }}>
                                            <Switch
                                                checked={values.STATUS}
                                                onChange={(checked) => setFieldValue("STATUS", checked)}
                                                defaultChecked
                                                style={{ width: 50 }}
                                            />
                                        </div>



                                        {/* setFieldValue is a function provided by Formik, a popular library for managing
                                         forms in React. It allows you to programmatically set the value of a field in the
                                          form state. This is particularly useful when you need to update the value of a field 
                                          based on some external input or event, rather than user input directly within a form field. */}


                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item className="flex justify-end">
                                        <Button
                                            onClick={handleCancel}
                                            type="primary"
                                            className="mr-4"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        // disabled={isSubmitting}
                                        >
                                            {edit ? "Update" : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default AssetForm;
