import React from "react";

import { Button, Modal, Select, DatePicker, Radio, Space } from "antd";

import { LuPlus } from "react-icons/lu";

import { MdOutlineRealEstateAgent } from "react-icons/md";

import CartTable from "./components/CartTable";
import CartForm from "./components/CartForm";

import { injectReducer } from "../../store";

import Cartreducer from "./store";

import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedDistributor } from "./store/stateSlice";

injectReducer("cart", Cartreducer);

const Cart = () => {
  const dispatch = useDispatch();

  const dialog = useSelector((state) => state.cart.state.newDialog);

  const selectedDistributor = useSelector(
    (state) => state.cart.state.selectedDistributor
  );

  const onDialog = () => {
    dispatch(setSelectedDistributor(null));
    dispatch(toggleNewDialog(true));
  };

  //   const handleCloseModal = () => {
  //     dispatch(toggleNewDialog(false));
  //   };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Cart</div>
          {/* <div className="flex">
            <div className="flex items-center">
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Cart</p>
              </Button>
            </div>
          </div> */}
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MdOutlineRealEstateAgent className="mr-2" />
                {selectedDistributor ? "Edit Cart" : "Add New Cart"}
              </span>
            }
            open={dialog}
            footer={null}
            // onCancel={handleCloseModal}
          >
            {/* <CartForm /> */}
          </Modal>
        </div>
        <CartTable />
      </div>
    </>
  );
};

export default Cart;
