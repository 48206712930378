import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'organisationList/state',
    initialState: {
        deleteConfirmation: false,
        selectedOrganisation: '',
        selectedOrganisationImage: '',
        newDialog: false,
        imageDialog: false,

    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedOrganisation: (state, action) => {
            state.selectedOrganisation = action.payload
        },
        setSelectedOrganisationImage: (state, action) => {
            state.selectedOrganisationImage = action.payload
        },
        toggleNewDialog: (state, action) => {
            state.newDialog = action.payload
        },
        toggleImageBox: (state, action) => {
            state.imageDialog = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedOrganisation, toggleNewDialog, toggleImageBox, setSelectedOrganisationImage } =
    stateSlice.actions

export default stateSlice.reducer