import { combineReducers } from "@reduxjs/toolkit";
import state from "./stateSlice";
import data from "./dataSlice";

const Notificationreducer = combineReducers({
  state,
  data,
});

export default Notificationreducer;
