import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'itemList/state',
    initialState: {
        deleteConfirmation: false,
        selectedItem: '',
        selectedItemImage: '',
        newDialog: false,
        imageDialog: false,

    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload
        },
        setSelectedItemImage: (state, action) => {
            state.selectedItemImage = action.payload
        },
        toggleNewDialog: (state, action) => {
            state.newDialog = action.payload
        },
        toggleImageBox: (state, action) => {
            state.imageDialog = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedItem, toggleNewDialog, setSelectedItemImage, toggleImageBox } =
    stateSlice.actions

export default stateSlice.reducer


