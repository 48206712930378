import ApiService from "./ApiService";

export async function apiGetAllForm(data) {
    //console.log("params", data);
    return ApiService.fetchData({
        url: "/api/form/get",
        method: "post",
        data,
    });
}



export async function apipostForm(data) {
    return ApiService.fetchData({
        url: "/api/form/create",
        method: "post",
        data,
    });
}

export async function apiputForm(data) {
    return ApiService.fetchData({
        url: "/api/form/update",
        method: "put",
        data,
    });
}
