import ApiService from "./ApiService";

export async function apiGetAllEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/get",
    method: "post",
    data,
  });
}

export async function apiGetAllEmployeeId(params) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    params,
  });
}
export async function apiGetAllStateId(params) {
  return ApiService.fetchData({
    url: "/api/state/getStateData",
    method: "post",
    params,
  });
}
export async function apiGetAllBranchId(params) {
  return ApiService.fetchData({
    url: "/api/branch/getBranchData",
    method: "post",
    params,
  });
}
export async function apiGetAllDesignationId(params) {
  return ApiService.fetchData({
    url: "/api/designation/get",
    method: "post",
    params,
  });
}
export async function apiGetAllRoleId(params) {
  return ApiService.fetchData({
    url: "/api/role/get",
    method: "post",
    params,
  });
}

export async function apiGetAllDepartmentId(params) {
  return ApiService.fetchData({
    url: "/api/department/getDepartmentData",
    method: "post",
    params,
  });
}
export async function apiGetAllOrganisation(params) {
  return ApiService.fetchData({
    url: "/api/organisation/getOrganisationData",
    method: "post",
    params,
  });
}
export async function apiGetAllEmployeeType(params) {
  return ApiService.fetchData({
    url: "/api/employeeType/get",
    method: "post",
    params,
  });
}

export async function apiGetCalenderData(data) {
  return ApiService.fetchData({
    url: "/api/attendenceCalender/getCalenderData",
    method: "post",
    data,
  });
}


export async function apipostEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/createEmployee",
    method: "post",
    data,
  });
}

export async function apiputEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/update",
    method: "put",
    data,
  });
}


