import React, { useEffect, useState } from "react";
import {
  Avatar,
  Flex,
  Typography,
  Dropdown,
  Menu,
  Modal,
  notification,
  Tooltip,
  Input,
  Form,
} from "antd";
import { HiOutlineLogout } from "react-icons/hi";
import { TbPasswordMobilePhone } from "react-icons/tb";
import styled from "styled-components";
import admin from "../../assets/images/role/admin.png";
import user from "../../assets/images/user/leader.jpg";
import employee from "../../assets/images/role/employee.png";
import leader from "../../assets/images/role/leader.png";
import logout from "../../view/login/Logout";
import ChangePassword from "../../view/login/ChangePassword";
import { useDispatch } from "react-redux";
import { getMenu } from "../Sidebar/store/dataSlice";
import { useNavigate } from "react-router-dom";
import { GiCycle } from "react-icons/gi";

export const CustomHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
    useState(false);

  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const userData2 = JSON.parse(localStorage.getItem("UserData"));
  const EMP_ID = userData2[0].EMP_ID;
  const EMP_NAME = userData2[0].NAME;

  const showChangePasswordModal = () => {
    setIsChangePasswordModalVisible(true);
  };

  const handleCancelChangePasswordModal = () => {
    setIsChangePasswordModalVisible(false);
  };
  const menu = (
    <StyledMenu>
      <Menu.Item
        key="2"
        icon={<TbPasswordMobilePhone style={{ fontSize: "18px" }} />}
        onClick={showChangePasswordModal}
      >
        Change Password
      </Menu.Item>

      <Menu.Item
        key="1"
        icon={<HiOutlineLogout style={{ fontSize: "18px" }} />}
        onClick={logout}
      >
        Logout
      </Menu.Item>
    </StyledMenu>
  );

  useEffect(() => {
    const savedRoleId = localStorage.getItem("ROLE_ID");
    const defaultRole = roleDetails[0].ROLE_DETAILS.find(
      (role) => role.ROLE_ID === (savedRoleId ? Number(savedRoleId) : 1)
    );

    if (defaultRole) {
      dispatch(getMenu({ ROLE_ID: defaultRole.ROLE_ID, EMP_ID }));
    }
  }, [dispatch, roleDetails, EMP_ID]);

  useEffect(() => {
    const modalShown = localStorage.getItem('modalShown');
    if (!modalShown && roleDetails && roleDetails[0].ROLE_DETAILS.length > 1) {
      setIsModalVisible(true);
      localStorage.setItem('modalShown', 'true');
    }
  }, []);

  const handleClick = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRoleSelect = async (roleId, roleName) => {
    localStorage.setItem("ROLE_ID", roleId);
    try {
      const menuResponse = await dispatch(
        getMenu({ ROLE_ID: roleId, EMP_ID })
      ).unwrap();
      const menuData = menuResponse.data;
      console.log(menuData);
      if (menuData && menuData.length > 0) {
        const dashboardItem = menuData.find(
          (item) => item.link === "/dashboard"
        );
        const targetItem =
          dashboardItem || menuData.find((item) => item.SEQ_NO === 0);

        console.log(targetItem);

        if (targetItem) {
          if (targetItem.link === "#") {
            const firstChildLink =
              targetItem.children && targetItem.children.length > 0
                ? targetItem.children[0].link
                : null;
            if (firstChildLink) {
              navigate(firstChildLink);
            } else {
              showAccessDeniedModal();
            }
          } else {
            navigate(targetItem.link);
          }
        } else {
          showAccessDeniedModal();
        }
      } else {
        showAccessDeniedModal();
      }
      api.success({
        message: `Successfully updated to ${roleName}`,
      });
    } catch (error) {
      api.error({
        message: "Error",
        description: "Failed to update the menu based on the selected role.",
      });
    }

    setIsModalVisible(false);
  };

  const showAccessDeniedModal = () => {
    Modal.error({
      title: "Access Denied",
      content: "You do not have access to this role.",
      onOk: () => {
        // localStorage.removeItem("UserData");
        // localStorage.removeItem("ROLE_ID");
        navigate("/login");
      },
    });
  };

  const roleImages = {
    Admin: admin,
    Employee: employee,
    Leader: leader,
    "HR Manager": leader,
  };

  return (
    <Flex align="center" justify="end">
      {contextHolder}
      <Flex align="center" justify="end">
        <Tooltip title="Click to choose roles">
          <div
            className="rounded-full w-10 mr-2 cursor-pointer"
            onClick={handleClick}
          >
            <GiCycle className="text-4xl" />
          </div>
        </Tooltip>
        <StyledModal
          title={<StyledModalTitle>Select Your Role</StyledModalTitle>}
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          footer={null}
        >
          <ModalContent>
            {roleDetails[0].ROLE_DETAILS.map(({ ROLE_ID, ROLE_NAME }) => (
              <RoleContainer
                key={ROLE_ID}
                onClick={() => handleRoleSelect(ROLE_ID, ROLE_NAME)}
              >
                <img
                  src={roleImages[ROLE_NAME] || admin}
                  alt={ROLE_NAME}
                  className="role-image"
                />
                <RoleLabel>{ROLE_NAME}</RoleLabel>
              </RoleContainer>
            ))}
          </ModalContent>
        </StyledModal>

        <Flex
          direction="column"
          justify="center"
          align="center"
          ml={2}
          className="border px-6 py-3 m-2"
        >
          <div className="flex flex-col">
            <Typography.Text strong>{EMP_NAME}</Typography.Text>
            <Typography.Text className="text-green-500">
              {
                roleDetails[0].ROLE_DETAILS.find(
                  (role) =>
                    role.ROLE_ID === Number(localStorage.getItem("ROLE_ID"))
                )?.ROLE_NAME
              }
            </Typography.Text>
          </div>

          <StyledDropdown overlay={menu} trigger={["click"]}>
            <StyledAvatarContainer>
              <img src={user} alt="" className="avatar" />
            </StyledAvatarContainer>
          </StyledDropdown>
        </Flex>
      </Flex>

      <Modal
        title="Change Password"
        visible={isChangePasswordModalVisible}
        onCancel={handleCancelChangePasswordModal}
        footer={null}
      >
        <ChangePassword onCancel={handleCancelChangePasswordModal} />
      </Modal>
    </Flex>
  );
};

const StyledDropdown = styled(Dropdown)``;

const StyledAvatarContainer = styled.div`
  width: 45px;
  height: 45px;
  background-color: #f0f0f0;
  border: 2px solid #1890ff;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-left: 20px;

  .avatar {
    width: 100%;
    height: 100%;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-title {
    text-align: center;
    width: 100%;
  }
`;

const StyledModalTitle = styled.div`
  width: 100%;
  text-align: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RoleContainer = styled.div`
  text-align: center;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 2px solid #eee;
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
    border-color: #1890ff;
  }

  .role-image {
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-bottom: 10px;
  }
`;

const RoleLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const StyledMenu = styled(Menu)`
  border-radius: 8px;
  margin-top: 6px !important;
  padding: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
