import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  notification,
  Switch,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  getItemCategory,
  postItemCategory,
  putItemCategory,
} from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";

const ItemCategoryForm = () => {
  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const selectedItemCategory = useSelector(
    (state) => state.itemCategory.state.selectedItemCategory
  );

  const validationSchema = Yup.object({
    NAME: Yup.string().required("Required"),
    DESCRIPTION: Yup.string().required("Required"),
    STATUS: Yup.string().required("Required"),
    SHORT_CODE: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedItemCategory) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedItemCategory]);

  const initialValues = selectedItemCategory || {
    NAME: "",
    DESCRIPTION: "",
    STATUS: true,
    SHORT_CODE: "",
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    try {
      const action = edit
        ? await dispatch(putItemCategory(values))
        : await dispatch(postItemCategory(values));

      if (action.payload.status < 300) {
        dispatch(toggleNewDialog(false));
        dispatch(getItemCategory());
        //console.log("Form submitted successfully!");
        api.success({
          message: "Form Submitted Successfully.",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {/* <h2 className="mb-2">Add Distributor</h2> */}
        {contextHolder}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              className=""
              onFinish={handleSubmit}
              onFinishFailed={(errorInfo) => {
                console.error("Failed:", errorInfo);
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.NAME && errors.NAME ? errors.NAME : ""}
                    validateStatus={
                      touched.NAME && errors.NAME ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Name</span>
                    <Input
                      name="NAME"
                      placeholder="Name"
                      value={values.NAME}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? errors.DESCRIPTION
                        : ""
                    }
                    validateStatus={
                      touched.DESCRIPTION && errors.DESCRIPTION
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">DESCRIPTION</span>
                    <Input
                      name="DESCRIPTION"
                      placeholder="Description"
                      value={values.DESCRIPTION}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.SHORT_CODE && errors.SHORT_CODE
                        ? errors.SHORT_CODE
                        : ""
                    }
                    validateStatus={
                      touched.SHORT_CODE && errors.SHORT_CODE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Short Code</span>
                    <Input
                      name="SHORT_CODE"
                      placeholder="SHORT CODE"
                      value={values.SHORT_CODE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                className="flex flex-col"
                help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                validateStatus={
                  touched.STATUS && errors.STATUS ? "error" : undefined
                }
              >
                <span className="text-xs">Status</span>

                <div style={{ marginTop: 8 }}>
                  <Switch
                    defaultChecked
                    checked={values.STATUS}
                    onChange={(checked) => setFieldValue("STATUS", checked)}
                    style={{ width: 50 }}
                  />
                </div>
              </Form.Item>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      type="primary"
                      className="mr-4"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {edit ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ItemCategoryForm;
