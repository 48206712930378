import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { FaFilter } from "react-icons/fa";

import LeaveTypeTable from "./components/LeaveTypeTable";
import LeaveTypeSearch from "./components/LeaveTypeSearch";

import { injectReducer } from "../../store";

import LeaveTypereducer from "./store";

import { useSelector, useDispatch } from "react-redux";

injectReducer("leaveType", LeaveTypereducer);

const LeaveType = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Leave Type</div>
          <div className="flex">
            <div className="flex items-center">
              <LeaveTypeSearch />
            </div>
          </div>
        </div>
        <LeaveTypeTable />
      </div>
    </>
  );
};

export default LeaveType;
