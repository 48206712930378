import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux"
import BaseService from '../../../services/BaseService';



import axios from "axios";

const ItemImage = () => {


    const img = useSelector((state) => state.item.state.selectedItemImage);


    const [imageUrls, setImageUrls] = useState({});

    const fetchImage = async () => {

        const config = {
            // headers: {
            //     apikey: "hjh4653dsiivy457468asdfe",
            //     token: localStorage.getItem("user"),
            // },
            responseType: 'blob',
        };

        try {
            const response = await axios.get(
                `${BaseService.defaults.baseURL}/static/itemImg/${img.IMAGE_URL_1}`,
                config
            );

            const url = URL.createObjectURL(response.data);
            // //console.log("image response = ", response)

            setImageUrls((prevUrls) => ({ ...prevUrls, [img.IMAGE_URL_1]: url }));
        } catch (error) {
            console.error("Error fetching image:", error);
        }
    };


    useEffect(() => {
        fetchImage();


    }, [img]);



    return (
        <>







            <div className="mt-5">

                {/* {JSON.stringify(img)} */}


                <center>

                    <img
                        src={imageUrls[img.IMAGE_URL_1]}
                        alt="Organisation Image Not Found.."
                        style={{ width: '50%', height: "50%", objectFit: "cover" }}
                    />
                </center>


            </div >





        </>
    );
};

export default ItemImage;