import React from "react";
import { Card } from "antd";
import { useSelector } from "react-redux";

const TaskCard = () => {
  const employeeTaskReport = useSelector(
    (state) => state.task.data.reportList?.countData
  );

  //console.log(employeeTaskReport);

  if (!Array.isArray(employeeTaskReport)) {
    return null;
  }

  return (
    <>
      {employeeTaskReport?.map((item, index) => (
        <div key={index} className="flex gap-10 flex-wrap justify-center">
          <Card
            style={{
              width: 150,
              height: 70,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className=" rounded-xl bg-blue-600  text-white text-center "
          >
            <p className="text-xl font-semibold  ">Total</p>
            {/* <hr style={{width:'100px'}} className='border border-black  '/> */}
            <p className="text-xl  ">{item.TOTAL}</p>
          </Card>
          <Card
            style={{
              width: 150,
              height: 70,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className=" rounded-xl text-center text-white bg-red-600"
          >
            <p className="text-xl  font-semibold">Pending</p>
            <p className="text-xl ">{item.TOTAL_PENDING}</p>
          </Card>
          <Card
            style={{
              width: 150,
              height: 70,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className=" rounded-xl text-center text-white bg-orange-600"
          >
            <p className="text-xl  font-semibold">Completed</p>
            <p className="text-xl  ">{item.TOTAL_COMPLETED}</p>
          </Card>
          <Card
            style={{
              width: 150,
              height: 70,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className=" rounded-xl text-white bg-lime-600"
          >
            <p className="text-xl text-center font-semibold">Hold</p>
            <p className="text-xl text-center">{item.TOTAL_HOLD}</p>
          </Card>
          <Card
            style={{
              width: 150,
              height: 70,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className=" rounded-xl text-white bg-indigo-600"
          >
            <p className="text-xl text-center font-semibold">Progress</p>
            <p className="text-xl text-center ">{item.TOTAL_INPROGRESS}</p>
          </Card>
        </div>
      ))}
    </>
  );
};

export default TaskCard;
