import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Input, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getpriceListDetails, setTableData, putPrice } from "../store/dataSlice";
import { setSelectedPrice, toggleNewDialog } from "../store/stateSlice";
import { RiArrowGoBackFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
const { Search } = Input;
const PriceTable = () => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state?.price?.data?.priceListDetails?.data);
    const selectedPrice = useSelector((state) => state.price.state.selectedPrice);
    const [api, contextHolder] = notification.useNotification();

    const [rates, setRates] = useState({});
    const [changes, setChanges] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

        if (data) {
            const initialRates = data.reduce((acc, item) => {
                acc[item.ID] = item.RATE;
                return acc;
            }, {});
            setRates(initialRates);
            const initialChanges = data.reduce((acc, item) => {
                acc[item.ID] = 0;
                return acc;
            }, {});
            setChanges(initialChanges);
        }
    }, [data]);

    const handleRateChange = (e, record) => {
        const { value } = e.target;
        // const isChanged = value !== record.RATE ? 1 : 0;
        // let isChanged = 0;
        // if (value !== record.RATE) {
        //     isChanged = 1;

        //     setChanges({
        //         ...changes,
        //         [record.ID]: isChanged,
        //     });
        // }
        setRates({
            ...rates,
            [record.ID]: value,
        });

    };

    // const handleSubmit = (record) => {
    //     const updatedRate = rates[record.ID];

    //     if (updatedRate !== record.RATE) {
    //         dispatch(putPrice({ ...record, RATE: updatedRate, IS_CHANGED: 1 }));
    //     }
    //     else {
    //         dispatch(putPrice({ ...record, RATE: updatedRate }));
    //     }

    // };





    const handleSubmit = async (record) => {
        try {
            const updatedRate = rates[record.ID];
            let action = null;
            if (updatedRate !== record.RATE) {
                action = await dispatch(putPrice({ ...record, RATE: updatedRate, IS_CHANGED: 1 }));
                if (action && action.payload && action.payload.code < 300) {
                    // //console.log("Form submitted successfully!");
                    dispatch(getpriceListDetails({ ORDER_ID: selectedPrice.ID }));
                    // //console.log("Form submitted successfully!");
                    api.success({
                        message: "Rate Updated successfully.",
                    });
                } else {
                    console.error(
                        "Error occurred during form submission:",
                        action.payload ? action.payload.error : 'Unknown error'
                    );
                    api.error({
                        message: "Error occurred during updating rate.",
                    });
                }
            } else {
                // action = await dispatch(putPrice({ ...record, RATE: updatedRate }));
                api.info({
                    message: "your new price is same as old price.",
                });
                dispatch(getpriceListDetails({ ORDER_ID: selectedPrice.ID }));
                // if (action && action.payload && action.payload.code < 300) {
                //     //console.log("Form submitted successfully!");
                //     dispatch(getpriceListDetails({ ORDER_ID: selectedPrice.ID }));
                //     //console.log("Form submitted successfully!");
                //     api.info({
                //         message: "your new value is same as old value.",
                //     });
                // } else {
                //     console.error(
                //         "Error occurred during form submission:",
                //         action.payload ? action.payload.error : 'Unknown error'
                //     );
                //     api.error({
                //         message: "Error occurred during updating rate.",
                //     });
                // }
            }

        } catch (error) {
            console.error("An unexpected error occurred:", error);
        }
    };




    const handleTableChange = (pagination) => {
        const { current, pageSize } = pagination;
        dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
    };

    const fetchData = useCallback(() => {
        if (selectedPrice) {
            dispatch(getpriceListDetails({ ORDER_ID: selectedPrice.ID }));
        }
    }, [dispatch, selectedPrice]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = [
        {
            title: <span className="text-gray-500">ITEM_NAME</span>,
            dataIndex: "ITEM_NAME",
            width: 240,
        },
        {
            title: <span className="text-gray-500">IS_CHANGED</span>,
            dataIndex: "IS_CHANGED",
            // render: (_, record) => <span>{changes[record.ID]}</span>,
        },
        {
            title: <span className="text-gray-500">Rate</span>,
            dataIndex: "RATE",
            render: (_, record) => (
                <Input
                    name="RATE"
                    placeholder="Item Name"
                    value={rates[record.ID]}
                    type="number"
                    min="0"
                    onChange={(e) => handleRateChange(e, record)}
                    className="!rounded"
                    style={{ padding: "8px" }}
                />
            ),
        },
        {
            title: <span className="text-gray-500">Action</span>,
            dataIndex: "action",
            render: (_, record) => (
                <Button
                    type="primary"
                    className="mr-4"
                    onClick={() => handleSubmit(record)}
                >
                    Update
                </Button>
            ),
        },
    ];





    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];






    // const [searchQuery, setSearchQuery] = useState("");

    // //console.log(searchQuery);
    // const handleSearchChange = (event) => {
    //     setSearchQuery(event.target.value);
    // };

    // const handleSerch = async () => {
    //     try {
    //         const action = await dispatch(
    //             dispatch(getpriceListDetails({ SEARCH_FILTER: searchQuery }))


    //         );

    //         // if (action.payload.code === 200) {
    //         //     handleRefresh && handleRefresh();
    //         // }
    //     } catch (error) {
    //         console.error("An unexpected error occurred:", error);
    //     }
    // };


    return (
        <>
            {contextHolder}

            <div className="bg-white m-4 p-8 rounded-xl">

                <div className="flex justify-between mb-6">


                    <div className="text-xl   !text-[#414141]">

                        <span className="font-bold ">
                            Price List Of -
                        </span>

                        <span className="ml-3" >
                            {monthNames[selectedPrice.MONTH - 1]} {JSON.stringify(selectedPrice.YEAR)}
                        </span>

                    </div>
                    <div className="flex">
                        <div className="flex items-center">
                            {/* <ItemSearch /> */}

                            {/* <Search placeholder="input search text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onSearch={handleSerch}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        handleSubmit();
                                    }
                                }} enterButton /> */}

                            <Button
                                style={{
                                    backgroundColor: "#096CAE",
                                    color: "#ffff",
                                    display: "flex",
                                    padding: "18px",
                                    borderRadius: "6px",
                                }}
                                onClick={() => navigate('/price')}
                            >
                                <RiArrowGoBackFill />

                                <p>Go Back</p>

                            </Button>
                        </div>
                    </div>






                </div>
                <Table
                    columns={columns}
                    dataSource={data}
                    bordered
                    scroll={{ x: 1300 }}
                    pagination={{
                        onChange: handleTableChange,
                        onShowSizeChange: handleTableChange,
                    }}
                />
            </div >
        </>
    );
};

export default PriceTable;
