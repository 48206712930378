import ApiService from "./ApiService";

export async function apiGetAllRole(data) {

  return ApiService.fetchData({
    url: "/api/role/get",
    method: "post",
    data,
  });
}

export async function apipostRole(data) {
  return ApiService.fetchData({
    url: "/api/role/create",
    method: "post",
    data,
  });
}

export async function apiputRole(data) {
  return ApiService.fetchData({
    url: "/api/role/update",
    method: "put",
    data,
  });
}

export async function apiRoleDetails(data) {
  return ApiService.fetchData({
    url: "/api/roleDetails/getData",
    method: "post",
    data,
  });
}

export async function apiSendRole(data) {
  return ApiService.fetchData({
    url: "/api/roleDetails/addBulk",
    method: "post",
    data,
  });
}




