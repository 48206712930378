import ApiService from "./ApiService";

export async function apiGetAllBranch(data) {

  return ApiService.fetchData({
    url: "/api/branch/get",
    method: "post",
    data,
  });
}

export async function apipostBranch(data) {
  return ApiService.fetchData({
    url: "/api/branch/create",
    method: "post",
    data,
  });
}

export async function apiputBranch(data) {
  return ApiService.fetchData({
    url: "/api/branch/update",
    method: "put",
    data,
  });
}


