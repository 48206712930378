import ApiService from "./ApiService";

export async function apiGetAllEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/get",
    method: "post",
    data,
  });
}

export async function apiGetLocation(data) {
  return ApiService.fetchData({
    url: "/api/location/get",
    method: "post",
    data,
  });
}
export async function apiGetAllEmployeeId(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}

export async function apiGetAllBranchId(data) {
  return ApiService.fetchData({
    url: "/api/branch/getBranchData",
    method: "post",
    data,
  });
}



export async function apiGetAllDepartmentId(data) {
  return ApiService.fetchData({
    url: "/api/department/getDepartmentData",
    method: "post",
    data,
  });
}


