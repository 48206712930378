import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import { Form, Input, Row, Col, Button, Switch, notification, } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
    postDesignation,
    putDesignation,
    getDesignation,
} from "../store/dataSlice";

import { DatePicker, Space } from "antd";
import moment from "moment";

// import { apigetGstType } from "../../../services/DistributorService";

const { TextArea } = Input;


const DesignationForm = ({ handleRefresh }) => {
    const [showSaveNext, setShowSaveNext] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const selectedDesignation = useSelector(
        (state) => state.designation.state.selectedDesignation
    );





    const validationSchema = Yup.object({
        DESIGNATION: Yup.string().required("Required"),
        STATUS: Yup.boolean().required("Required"),
        DESCRIPTION: Yup.string().required("Required"),



        // status: Yup.boolean().required("Required"),
    });


    useEffect(() => {
        if (selectedDesignation) {
            setEdit(true);
        } else {
            setEdit(false);
        }
    }, [selectedDesignation]);

    // useEffect(() => {
    //     const fetchGSTType = async () => {
    //         try {
    //             const response = await apigetGstType();

    //             if (response.status === 200) {
    //                 const responseData = response.data;
    //                 //console.log(responseData);
    //             } else {
    //                 console.error();
    //             }
    //         } catch (err) {
    //             //console.log(err);
    //         }
    //     };

    //     fetchGSTType();
    // }, []);

    const initialValues = selectedDesignation || {

        DESIGNATION: "",
        STATUS: true,
        DESCRIPTION: "",

    };



    const handleCancel = () => {
        dispatch(toggleNewDialog(false));
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        //console.log("Form values:", values);
        try {
            const action = edit
                ? await dispatch(putDesignation(values))
                : await dispatch(postDesignation(values));
            // //console.log('action = ', action);
            if (action.payload.code < 300) {


                // handleRefresh && handleRefresh();
                // if (!showSaveNext) {
                //   dispatch(toggleNewDialog(false));
                // } else {
                //   resetForm();
                // }
                //console.log("Form submitted successfully!");
                dispatch(getDesignation());
                //console.log("Form submitted successfully!");
                dispatch(toggleNewDialog(false));
                api.success({
                    message: "Form Submitted Successfully.",
                });



            } else {
                console.error(
                    "Error occurred during form submission:",
                    action.payload.error
                );
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        } finally {
            setSubmitting(false);
        }
    };




    return (
        <>
            <div className="mt-4">
                {/* <h2 className="mb-2">Add Distributor</h2> */}
                {contextHolder}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, touched, errors, handleChange, setFieldValue, submitForm, handleBlur }) => (
                        <FormikForm>
                            <Row gutter={16}>
                                <Col span={23}>


                                    <Form.Item
                                        className="flex flex-col"
                                        help={
                                            touched.DESIGNATION && errors.DESIGNATION
                                                ? errors.DESIGNATION
                                                : ""
                                        }
                                        validateStatus={
                                            touched.DESIGNATION && errors.DESIGNATION
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs">  Designation Name</span>
                                        {/* {JSON.stringify(values.DESIGNATION)} */}
                                        <Input
                                            name="DESIGNATION"
                                            placeholder="Designation Name.."
                                            value={values.DESIGNATION}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>




                            </Row>









                            <Row gutter={16}>


                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={
                                            touched.DESCRIPTION && errors.DESCRIPTION
                                                ? errors.DESCRIPTION
                                                : ""
                                        }
                                        validateStatus={
                                            touched.DESCRIPTION && errors.DESCRIPTION
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs">Description</span>

                                        <TextArea
                                            rows={4}
                                            placeholder="You Can Add Maximum 512 Characters.."
                                            maxLength={6000}
                                            value={values.DESCRIPTION}
                                            onChange={(e) => setFieldValue("DESCRIPTION", e.target.value)}
                                        />

                                    </Form.Item>
                                </Col>

                            </Row>



                            <Row gutter={16}>
                                <Col span={12}>



                                    <Form.Item
                                        className="flex flex-col "
                                        help={
                                            touched.STATUS && errors.STATUS
                                                ? errors.STATUS
                                                : ""
                                        }
                                        validateStatus={
                                            touched.STATUS && errors.STATUS
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs ">Status</span>

                                        {/* <Switch
                                            checked={values.STATUS}
                                            onChange={(checked) => setFieldValue("STATUS", checked)}
                                            defaultChecked
                                            style={{ padding: "8px" }}

                                        /> */}
                                        <div style={{ marginTop: 8 }}>
                                            <Switch
                                                checked={values.STATUS}
                                                onChange={(checked) => setFieldValue("STATUS", checked)}
                                                defaultChecked
                                                style={{ width: 50 }}
                                            />
                                        </div>



                                        {/* setFieldValue is a function provided by Formik, a popular library for managing
                                         forms in React. It allows you to programmatically set the value of a field in the
                                          form state. This is particularly useful when you need to update the value of a field 
                                          based on some external input or event, rather than user input directly within a form field. */}


                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item className="flex justify-end">
                                        <Button
                                            onClick={handleCancel}
                                            className="mr-4 py-4 px-6 border border-blue-500"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        // disabled={isSubmitting}
                                        >
                                            {edit ? "Update" : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default DesignationForm;
