import React, { useEffect } from "react";

import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import ServiceForm from "./Components/ServiceForm";
import ServiceTable from "./Components/ServiceTable";
import { injectReducer } from "../../store";
import Servicereducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedService } from "./store/stateSlice";
import { getService } from "./store/dataSlice";
import ServiceSearch from "./Components/ServiceSearch";

injectReducer("service", Servicereducer);

const Service = () => {
  const dialog = useSelector((state) => state.service.state.newDialog);

  const AddEdit = useSelector((state) => state.service.state.selectedService);
  const aaa = String(dialog);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedService(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); // Close the modal
  };

  const handleRefresh = () => {
    dispatch(getService());
  };

  useEffect(() => {
    handleRefresh();
  }, [dispatch]);

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">
            Service Detail
          </div>
          <div className="flex">
            <div className="flex items-center">
              <ServiceSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Service</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Service" : "Add New Service"}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <ServiceForm />
          </Modal>
        </div>

        <ServiceTable handleRefresh={handleRefresh} />
      </div>
    </>
  );
};

export default Service;
