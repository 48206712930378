import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetEmployeeDetails } from "../../../services/EmployeeDetailsService";

export const getEmployeeDetails = createAsyncThunk(
  "employee/data/getEmployeeDetails",
  async (data) => {
    const response = await apiGetEmployeeDetails(data);
    return response.data;
  }
);

export const getExcelDetails = createAsyncThunk(
  "employee/data/getExcelDetails",
  async (data) => {
    const response = await apiGetEmployeeDetails(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  MONTH: "",
  YEAR: "",
};

const dataSlice = createSlice({
  name: "employeeDetailList/data",
  initialState: {
    loading: false,
    employeeDetailsList: [],
    excelArray: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.employeeList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeDetailsList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getEmployeeDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEmployeeDetails.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getExcelDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.excelArray = action.payload;
        state.tableData.total = action.payload.count;
      });
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
