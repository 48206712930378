import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Form, Input, Row, Col, Button ,Switch,Select,notification} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
 getBranch,
  postBranch,
  putBranch,
} from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";


const BranchForm = () => {
  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const { Option } = Select;
  const selectedBranch = useSelector(
    (state) => state.branch.state.selectedBranch
  );

  const validationSchema = Yup.object({
    NAME: Yup.string().required("Required"),
    ADDRESS: Yup.string().required("Required"),
    ORG_ID: Yup.string().required("Required"),
    // LONGITUDE: Yup.string().required("Required"),
    // LATITUDE: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedBranch) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedBranch]);
  const organisationList = useSelector(
    (state)=> state.employee.data.organisationList.data
  );


  const initialValues = selectedBranch || {
    NAME: "",
    ADDRESS: "",
    ORG_ID: "",
    LONGITUDE: "",
    LATITUDE: "",
  };
  const handleSubmit = async (values,{setSubmitting}) => {
    //console.log(values);
    try {
      const action = edit
        ? await dispatch(putBranch(values))
        : await dispatch(postBranch(values));

      if (action.payload.status < 300) {
        dispatch(toggleNewDialog(false));
        dispatch(getBranch());
        //console.log("Form submitted successfully!");
        api.success({
          message: "Form Submitted Successfully.",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {/* <h2 className="mb-2">Add Distributor</h2> */}
        {contextHolder}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form
              className=""
              onFinish={handleSubmit}
              onFinishFailed={(errorInfo) => {
                console.error("Failed:", errorInfo);
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.NAME && errors.NAME
                        ? errors.NAME
                        : ""
                    }
                    validateStatus={
                      touched.NAME && errors.NAME
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Name</span>
                    <Input
                      name="NAME"
                      placeholder="Name"
                      value={values.NAME}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.LATITUDE && errors.LATITUDE
                        ? errors.LATITUDE
                        : ""
                    }
                    validateStatus={
                      touched.LATITUDE && errors.LATITUDE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Latitude</span>
                    <Input
                      name="LATITUDE"
                      placeholder="Latitude"
                      value={values.LATITUDE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.ADDRESS && errors.ADDRESS ? errors.ADDRESS : ""
                    }
                    validateStatus={
                      touched.ADDRESS && errors.ADDRESS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Address</span>
                    <Input
                      name="ADDRESS"
                      placeholder="Address"
                      value={values.ADDRESS}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.ORG_ID && errors.ORG_ID ? errors.ORG_ID : ""
                    }
                    validateStatus={
                      touched.ORG_ID && errors.ORG_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Organization Name</span>
                    <Select
                        showSearch
                        placeholder="Select Organisation name"
                        onChange={(value) =>
                          handleChange({ target: { name: "ORG_ID", value } })
                        }
                        value={values.ORG_ID}
                        // filterOption={filterOption}
                        className="h-[40px] rounded"
                      >
                        {organisationList?.map((type) => (
                          <Option
                            key={type.ID}
                            value={type.ID}
                            className="text-gray-400"
                          >
                            {type.ORGANISATION}
                          </Option>
                        ))}
                      </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.LONGITUDE && errors.LONGITUDE
                        ? errors.LONGITUDE
                        : ""
                    }
                    validateStatus={
                      touched.LONGITUDE && errors.LONGITUDE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Longitude</span>
                    <Input
                      name="LONGITUDE"
                      placeholder="Longitude"
                      value={values.LONGITUDE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.LATITUDE && errors.LATITUDE
                        ? errors.LATITUDE
                        : ""
                    }
                    validateStatus={
                      touched.LATITUDE && errors.LATITUDE
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Latitude</span>
                    <Input
                      name="LATITUDE"
                      placeholder="Latitude"
                      value={values.LATITUDE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                
                </Row>
                <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col "
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={touched.STATUS && errors.STATUS ? "error" : undefined}
                  >
                    <span className="text-xs">STATUS</span>
                    <div className="flex items-center">
                      <Switch
                        name="STATUS"
                        checked={values.STATUS}
                        onChange={(checked) => {
                          handleChange({
                            target: { name: "STATUS", value: checked },
                          });
                        }}
                        onBlur={handleBlur}
                        className="!rounded-full custom-switch"
                        style={{ padding: "8px 5px" }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      type="primary"
                      className="mr-4"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {edit ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BranchForm;
