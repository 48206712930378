import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getNotification, putNotification } from "../store/dataSlice";
import { setSelectedNotification, toggleNewDialog } from "../store/stateSlice";
import { setTableData } from "../store/dataSlice";

const NotificationTable = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.Notification?.data?.NotificationList?.data);

  console.log(data);

  const onEdit = async (record) => {
    dispatch(setSelectedNotification(record));
    dispatch(toggleNewDialog(true));
  };

  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putNotification(updatedRecord));
    dispatch(getNotification());
  };

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.Notification.data.tableData
  );

  const getStatusStyle = (status) => {
    switch (status) {
      case "U":
        return { color: "yellow" };
      case "R":
        return { color: "green" };
      default:
        return {};
    }
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const fetchData = useCallback(() => {
    dispatch(getNotification({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Title</span>,
      dataIndex: "TITLE",
      width: 200,
    },
    {
      title: <span className="text-gray-500">Message</span>,
      dataIndex: "MESSAGE",
    },
    // {
    //   title: <span className="text-gray-500">Sender Emp Name</span>,
    //   dataIndex: "SENDER_EMP_NAME",
    // },
    {
      title: <span className="text-gray-500">Receiver Emp Name</span>,
      dataIndex: "RECEIVER_EMP_NAME",
    },
    {
      title: <span className="text-gray-500">Read Status </span>,
      dataIndex: "READ_STATUS",
      render: (status) => <span style={getStatusStyle(status)}>{status === "U" ? "Unread" : "Read"}</span>,
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Switch
          checked={record.STATUS === 1}
          onChange={() => onSwitch(record)}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{ x: 1300 }}
        pagination={{
          current: pageIndex,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20],
          onChange: handleTableChange,
          onShowSizeChange: handleTableChange,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default NotificationTable;
