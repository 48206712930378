import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import { Form, Input, Row, Col, Button, Switch, notification, Select } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import { postExpense, putExpense, getExpense, getExpenseTypeId } from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";
import Expense from "..";

const { TextArea } = Input;

const ExpenseForm = ({ handleRefresh }) => {
    const { Option } = Select;
    const [showSaveNext, setShowSaveNext] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const selectedExpense = useSelector((state) => state.expense.state.selectedExpense);

    const validationSchema = Yup.object({
        DATE: Yup.string().required("Required"),
        AMOUNT: Yup.string().required("Required"),
        EXPENSE_TYPE_ID: Yup.string().required("Required"),
        LOCATION: Yup.string().required("Required"),
        DESCRIPTION: Yup.string().required("Required"),
        PARTY_NAME: Yup.string().required("Required"),
        BILL_URL: Yup.string().required("Required"),
        STATUS: Yup.boolean().required("Required"),
    });

    useEffect(() => {
        if (selectedExpense) {
            setEdit(true);
        } else {
            setEdit(false);
        }
    }, [selectedExpense]);

    const initialValues = selectedExpense || {
        DATE: "",
        AMOUNT: "",
        EXPENSE_TYPE_ID: "",
        LOCATION: "",
        DESCRIPTION: "",
        PARTY_NAME: "",
        BILL_URL: "",
        STATUS: true,
    };

    const handleCancel = () => {
        dispatch(toggleNewDialog(false));
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        //console.log("Form values:", values);
        try {
            const action = edit
                ? await dispatch(putExpense(values))
                : await dispatch(postExpense(values));
            if (action.payload.code < 300) {
                dispatch(getExpense());
                dispatch(toggleNewDialog(false));
                api.success({ message: "Form Submitted Successfully." });
            } else {
                console.error("Error occurred during form submission:", action.payload.error);
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        } finally {
            setSubmitting(false);
        }
    };


    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const expenseIdType = useSelector((state) => state?.expense?.data?.expenseTypeIdList?.data);

    useEffect(() => {
        dispatch(getExpenseTypeId());

    }, []);



    return (
        <>
            <div className="mt-4">
                {contextHolder}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, touched, errors, handleChange, setFieldValue, submitForm, handleBlur }) => (
                        <FormikForm>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.DATE && errors.DATE ? errors.DATE : ""}
                                        validateStatus={touched.DATE && errors.DATE ? "error" : undefined}
                                    >
                                        <span className="text-xs">Date</span>
                                        <DatePicker
                                            className="!rounded w-full"
                                            name="DATE"
                                            placeholder="Date"
                                            value={values.DATE ? moment(values.DATE) : null}
                                            onChange={(date, dateString) => setFieldValue("DATE", dateString)}
                                            onBlur={handleBlur}
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.AMOUNT && errors.AMOUNT ? errors.AMOUNT : ""}
                                        validateStatus={touched.AMOUNT && errors.AMOUNT ? "error" : undefined}
                                    >
                                        <span className="text-xs">Amount</span>
                                        <Input
                                            name="AMOUNT"
                                            placeholder="Amount.."
                                            value={values.AMOUNT}
                                            type="number"
                                            min="0"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.EXPENSE_TYPE_ID && errors.EXPENSE_TYPE_ID ? errors.EXPENSE_TYPE_ID : ""}
                                        validateStatus={touched.EXPENSE_TYPE_ID && errors.EXPENSE_TYPE_ID ? "error" : undefined}
                                    >
                                        <span className="text-xs">Expense Type</span>
                                        {/* <Input
                                            name="EXPENSE_TYPE_ID"
                                            placeholder="Expense Type .."
                                            value={values.EXPENSE_TYPE_ID}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        /> */}









                                        <Select
                                            showSearch
                                            placeholder="Select State"
                                            onChange={(value) =>
                                                handleChange({
                                                    target: { name: "EXPENSE_TYPE_ID", value },
                                                })
                                            }
                                            value={values.EXPENSE_TYPE_ID || undefined}
                                            filterOption={filterOption}
                                            className="h-[40px] rounded"
                                        >
                                            {expenseIdType?.map((x) => (
                                                <Option
                                                    key={x.ID}
                                                    value={x.ID}
                                                    className="text-gray-400"
                                                >
                                                    {x.NAME}
                                                </Option>
                                            ))}
                                        </Select>


                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.LOCATION && errors.LOCATION ? errors.LOCATION : ""}
                                        validateStatus={touched.LOCATION && errors.LOCATION ? "error" : undefined}
                                    >
                                        <span className="text-xs">Location</span>
                                        <Input
                                            name="LOCATION"
                                            placeholder="Location .."
                                            value={values.LOCATION}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.DESCRIPTION && errors.DESCRIPTION ? errors.DESCRIPTION : ""}
                                        validateStatus={touched.DESCRIPTION && errors.DESCRIPTION ? "error" : undefined}
                                    >
                                        <span className="text-xs">Description</span>
                                        <Input
                                            name="DESCRIPTION"
                                            placeholder="Description"
                                            value={values.DESCRIPTION}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.PARTY_NAME && errors.PARTY_NAME ? errors.PARTY_NAME : ""}
                                        validateStatus={touched.PARTY_NAME && errors.PARTY_NAME ? "error" : undefined}
                                    >
                                        <span className="text-xs">Party Name</span>
                                        <Input
                                            name="PARTY_NAME"
                                            placeholder="Party Name.."
                                            value={values.PARTY_NAME}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.BILL_URL && errors.BILL_URL ? errors.BILL_URL : ""}
                                        validateStatus={touched.BILL_URL && errors.BILL_URL ? "error" : undefined}
                                    >
                                        <span className="text-xs">Bill Url</span>
                                        <Input
                                            name="BILL_URL"
                                            placeholder="Bill Url"
                                            value={values.BILL_URL}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                                        validateStatus={touched.STATUS && errors.STATUS ? "error" : undefined}
                                    >
                                        <span className="text-xs">Status</span>
                                        <div style={{ marginTop: 8 }}>
                                            <Switch
                                                checked={values.STATUS}
                                                onChange={(checked) => setFieldValue("STATUS", checked)}
                                                defaultChecked
                                                style={{ width: 50 }}
                                            />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item className="flex justify-end">
                                        <Button
                                            onClick={handleCancel}
                                            className="mr-4 text-blue-500 border-blue-500"
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit">
                                            {edit ? "Update" : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default ExpenseForm;
