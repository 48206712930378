import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'holidaystate',
    initialState: {
        DateDialog: false,
        selectedHoliday: "",

    },
    reducers: {
        toggleNewDate: (state, action) => {
            state.DateDialog = action.payload
        },
        setSelectedHoliday: (state, action) => {
            state.selectedHoliday = action.payload
        },
    },
})

export const { setSelectedHoliday, toggleNewDate } =
    stateSlice.actions

export default stateSlice.reducer