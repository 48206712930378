import ApiService from "./ApiService";

export async function apiGetAllLeaveType(data) {
  return ApiService.fetchData({
    url: "/api/leaveType/get",
    method: "post",
    data,
  });
}

export async function apiPostLeaveType(data) {
  return ApiService.fetchData({
    url: "/api/leaveType/create",
    method: "post",
    data,
  });
}

export async function apiPutLeaveType(data) {
  return ApiService.fetchData({
    url: "/api/leaveType/update",
    method: "put",
    data,
  });
}
