import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  Select,
  notification,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
  getDepartment,
  getState,
  postDepartment,
  putDepartment,
} from "../store/dataSlice";

const DepartmentForm = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const { Option } = Select;

  const [api, contextHolder] = notification.useNotification();

  const selectedDistributor = useSelector(
    (state) => state.department.state.selectedDistributor
  );

  const getstate = useSelector((state) => state.department.data.stateList.data);
  //console.log(getstate);

  const validationSchema = Yup.object({
    DEPARTMENT: Yup.string().required("Required"),
    LOCATION: Yup.string().required("Required"),
    TEHSIL: Yup.string().required("Required").max(10, "Max length is 10"),
    ADDRESS: Yup.string().required("Required"),
    COUNTRY: Yup.string().required("Required"),
    STATUS: Yup.string().required("Required"),
    DISTRICT: Yup.string().required("Required"),
    DAYIN_TIME: Yup.string().required("Required"),
    DAYOUT_TIME: Yup.string().required("Required"),
    INTERVAL_TIME: Yup.string().required("Required"),
    LATEMARK_TIME: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedDistributor) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedDistributor]);

  useEffect(() => {
    dispatch(getState());
  }, []);

  const initialValues = selectedDistributor || {
    DEPARTMENT: "",
    LOCATION: "",
    ADDRESS: "",
    TEHSIL: "",
    COUNTRY: "",
    STATUS: true,
    DISTRICT: "",
    DAYIN_TIME: "",
    DAYOUT_TIME: "",
    INTERVAL_TIME: "",
    LATEMARK_TIME: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const action = edit
        ? await dispatch(putDepartment(values))
        : await dispatch(postDepartment(values));

      if (action.payload.code === 200) {
        dispatch(getDepartment());

        api.success({
          message: "Form Submitted Successfully.",
        });

        dispatch(toggleNewDialog(false));
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
      return action.payload.code;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  // const handleSaveAndClose = async (submitForm) => {
  //   console.log("submitform", submitForm);
  //   submitForm().then((actionCode) => {
  //     console.log("action code", actionCode);
  //     if (actionCode === 200) {
  //       handleCancel();
  //     }
  //   });
  // };

  return (
    <>
      <div className="mt-4">
        {contextHolder}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            submitForm,
            setFieldValue,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DEPARTMENT && errors.DEPARTMENT
                        ? errors.DEPARTMENT
                        : ""
                    }
                    validateStatus={
                      touched.DEPARTMENT && errors.DEPARTMENT
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Department</span>
                    <Input
                      name="DEPARTMENT"
                      placeholder="Department"
                      value={values.DEPARTMENT}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.LOCATION && errors.LOCATION ? errors.LOCATION : ""
                    }
                    validateStatus={
                      touched.LOCATION && errors.LOCATION ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Location</span>
                    <Input
                      name="LOCATION"
                      placeholder="Location"
                      value={values.LOCATION}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={10}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.TEHSIL && errors.TEHSIL ? errors.TEHSIL : ""}
                    validateStatus={
                      touched.TEHSIL && errors.TEHSIL ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Tehsil</span>
                    <Input
                      name="TEHSIL"
                      placeholder="Tehsil"
                      value={values.TEHSIL}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DISTRICT && errors.DISTRICT ? errors.DISTRICT : ""
                    }
                    validateStatus={
                      touched.DISTRICT && errors.DISTRICT ? "error" : undefined
                    }
                  >
                    <span className="text-xs">District</span>
                    <Input
                      name="DISTRICT"
                      placeholder="District"
                      value={values.DISTRICT}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.ADDRESS && errors.ADDRESS ? errors.ADDRESS : ""
                    }
                    validateStatus={
                      touched.ADDRESS && errors.ADDRESS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Address</span>
                    <Input
                      name="ADDRESS"
                      placeholder="Address"
                      value={values.ADDRESS}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.COUNTRY && errors.COUNTRY ? errors.COUNTRY : ""
                    }
                    validateStatus={
                      touched.COUNTRY && errors.COUNTRY ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Country</span>
                    <Input
                      name="COUNTRY"
                      placeholder="Country"
                      value={values.COUNTRY}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DAYIN_TIME && errors.DAYIN_TIME
                        ? errors.DAYIN_TIME
                        : ""
                    }
                    validateStatus={
                      touched.DAYIN_TIME && errors.DAYIN_TIME
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Day In Time</span>
                    <TimePicker
                      name="DAYIN_TIME"
                      placeholder="Select Time"
                      value={
                        values.DAYIN_TIME
                          ? dayjs(values.DAYIN_TIME, "HH:mm:ss")
                          : null
                      }
                      onChange={(time, timeString) => {
                        setFieldValue("DAYIN_TIME", timeString);
                      }}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ width: "100%", padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.LATEMARK_TIME && errors.LATEMARK_TIME
                        ? errors.LATEMARK_TIME
                        : ""
                    }
                    validateStatus={
                      touched.LATEMARK_TIME && errors.LATEMARK_TIME
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Latemark Time</span>
                    <TimePicker
                      name="LATEMARK_TIME"
                      placeholder="Select Time"
                      value={
                        values.LATEMARK_TIME
                          ? dayjs(values.LATEMARK_TIME, "HH:mm:ss")
                          : null
                      }
                      onChange={(time, timeString) => {
                        setFieldValue("LATEMARK_TIME", timeString);
                      }}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ width: "100%", padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.INTERVAL_TIME && errors.INTERVAL_TIME
                        ? errors.INTERVAL_TIME
                        : ""
                    }
                    validateStatus={
                      touched.INTERVAL_TIME && errors.INTERVAL_TIME
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Interval Time </span>
                    <TimePicker
                      name="INTERVAL_TIME"
                      placeholder="Select Time"
                      value={
                        values.INTERVAL_TIME
                          ? dayjs(values.INTERVAL_TIME, "HH:mm:ss")
                          : null
                      }
                      onChange={(time, timeString) => {
                        setFieldValue("INTERVAL_TIME", timeString);
                      }}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ width: "100%", padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DAYOUT_TIME && errors.DAYOUT_TIME
                        ? errors.DAYOUT_TIME
                        : ""
                    }
                    validateStatus={
                      touched.DAYOUT_TIME && errors.DAYOUT_TIME
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Day Out Time</span>
                    <TimePicker
                      name="DAYOUT_TIME"
                      placeholder="Select Time"
                      value={
                        values.DAYOUT_TIME
                          ? dayjs(values.DAYOUT_TIME, "HH:mm:ss")
                          : null
                      }
                      onChange={(time, timeString) => {
                        setFieldValue("DAYOUT_TIME", timeString);
                      }}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ width: "100%", padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="flex flex-col "
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">STATUS</span>
                    <div className="flex items-center">
                      <Switch
                        name="STATUS"
                        checked={values.STATUS}
                        onChange={(checked) => {
                          handleChange({
                            target: { name: "STATUS", value: checked },
                          });
                        }}
                        onBlur={handleBlur}
                        className="!rounded-full custom-switch"
                        style={{ padding: "8px 5px" }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <div className="flex relative">
                      <Button
                        onClick={handleCancel}
                        className="mr-4 border border-blue-500 text-blue-500"
                      >
                        Cancel
                      </Button>
                      <div className="flex items-center">
                        {!edit && (
                          <Button
                            type="primary"
                            className="!p-2"
                            // onClick={() => handleSaveAndClose(submitForm)}

                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                      {edit && (
                        <Button type="primary" htmlType="submit">
                          Update
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DepartmentForm;
