import React, { useEffect } from "react";

import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import EmployeeTypeForm from "./components/EmployeeTypeForm";
import EmployeeTypeTable from "./components/EmployeeTypeTable";
import { injectReducer } from "../../store";
import EmployeeTypereducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedEmployeeType } from "./store/stateSlice";
import { getEmployeeType } from "./store/dataSlice";
import EmployeeTypeSearch from "./components/EmployeeTypeSearch";

injectReducer("employeeType", EmployeeTypereducer);

const EmployeeType = () => {
  const dialog = useSelector((state) => state.employeeType.state.newDialog);

  const AddEdit = useSelector(
    (state) => state.employeeType.state.selectedEmployeeType
  );
  const aaa = String(dialog);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedEmployeeType(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); // Close the modal
  };

  const handleRefresh = () => {
    dispatch(getEmployeeType());
  };

  useEffect(() => {
    handleRefresh();
  }, [dispatch]);

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">
            Employee Type Details
          </div>
          <div className="flex">
            <div className="flex items-center">
              <EmployeeTypeSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Employee Type</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit EmployeeType" : "Add New EmployeeType"}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <EmployeeTypeForm />
          </Modal>
        </div>

        <EmployeeTypeTable handleRefresh={handleRefresh} />
      </div>
    </>
  );
};

export default EmployeeType;
