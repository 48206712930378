import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getItem, putItem } from "../store/dataSlice";
import { setSelectedItem, toggleNewDialog, toggleImageBox, setSelectedItemImage } from "../store/stateSlice";
import { setTableData, } from "../store/dataSlice";
import { FaImage, FaRegImage } from "react-icons/fa6";

import axios from "axios";

const DesignationTable = ({ handleRefresh }) => {
    const dispatch = useDispatch();


    const data = useSelector(
        (state) => state?.item?.data?.itemList?.data
    );


    // //console.log("data", data);

    const onEdit = async (record) => {
        dispatch(setSelectedItem(record));
        dispatch(toggleNewDialog(true));
    };




    const onSwitch = async (record) => {
        const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
        await dispatch(putItem(updatedRecord));
        dispatch(getItem());
    };










    const { pageIndex, pageSize, total } = useSelector(

        (state) => state.item.data.tableData
    );


    const handleTableChange = (pagination) => {

        const { current, pageSize } = pagination;

        // //console.log("pageIndex", pageIndex, "pageSize", pageSize, "total", total);

        dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
    };

    const fetchData = useCallback(() => {
        dispatch(getItem({ pageIndex, pageSize }));
    }, [dispatch, pageIndex, pageSize]);


    useEffect(() => {
        fetchData();
    }, [pageIndex, pageSize, handleRefresh]);







    const onEditImage = (record) => {

        dispatch(setSelectedItemImage(record));
        dispatch(toggleImageBox(true));
    };












    // const [imageUrls, setImageUrls] = useState({});

    // const fetchImage = async (imageName) => {
    //     //console.log("image name = ", imageName)
    //     const config = {
    //         headers: {
    //             apikey: "hjh4653dsiivy457468asdfe",
    //             token: localStorage.getItem("user"),
    //         },
    //         responseType: 'blob',
    //     };

    //     try {
    //         const response = await axios.get(
    //             `${BaseService.defaults.baseURL}/static/itemImg/${imageName}`,
    //             config
    //         );

    //         const url = URL.createObjectURL(response.data);
    //         //console.log("image response = ", response)

    //         setImageUrls((prevUrls) => ({ ...prevUrls, [imageName]: url }));
    //     } catch (error) {
    //         console.error("Error fetching image:", error);
    //     }
    // };



    // useEffect(() => {
    //     if (data) {
    //         data.forEach((record) => {

    //             if (record.IMAGE_URL_1 && !imageUrls[record.IMAGE_URL_1]) {
    //                 fetchImage(record.IMAGE_URL_1);
    //             }
    //         });
    //     }
    // }, [data]);



    const columns = [
        {
            title: <span className="text-gray-500">Action</span>,
            dataIndex: "action",
            fixed: "left",
            width: 130, // Adjusted to ensure the title fits
            render: (_, record) => (
                <div className="flex items-center">


                    <span
                        onClick={() => onEdit(record)}
                        className="text-2xl ml-2 text-[#096CAE] cursor-pointer">
                        <MdEdit />

                    </span>

                    <span
                        onClick={() => onEditImage(record)}

                        className="text-2xl ml-8 text-red-500 cursor-pointer"
                    >

                        <FaRegImage />

                    </span>

                </div>
            ),
        },

        {
            title: <span className="text-gray-500">Name</span>,
            dataIndex: "NAME",
            width: 150,

        },
        {
            title: <span className="text-gray-500">Base Unit</span>,
            dataIndex: "BASE_UNIT_NAME",
            width: 150,

        },

        {
            title: <span className="text-gray-500">Category</span>,
            dataIndex: "CATEGORY_NAME",
            width: 150,

        },
        {
            title: <span className="text-gray-500">Production Price</span>,
            dataIndex: "PRODUCTION_PRICE",
            width: 140,




        },
        {

            title: <span className="text-gray-500">Sale Price</span>,
            dataIndex: "SALE_PRICE",
            width: 100,

        },
        {
            title: <span className="text-gray-500">description</span>,
            dataIndex: "DESCRIPTION",
            width: 300,


        },
        {
            title: <span className="text-gray-500">Unit</span>,
            dataIndex: "UNIT_NAME",
            width: 100,

        },
        {
            title: <span className="text-gray-500">Opening Stock</span>,
            dataIndex: "OPENING_STOCK",
            width: 150,



        },
        {

            title: <span className="text-gray-500">Expiry Days</span>,
            dataIndex: "EXPIRY_DAYS",
            width: 150,

        },
        // {
        //     title: <span className="text-gray-500">IMAGE_URL_1</span>,
        //     dataIndex: "IMAGE_URL_1",



        // },

        {

            title: <span className="text-gray-500">Short Code</span>,
            dataIndex: "SHORT_CODE",
            width: 150,


        },


        {
            title: <span className="text-gray-500">Status</span>,
            dataIndex: "STATUS",
            fixed: "right",
            width: 100,


            render: (_, record) => (
                <Switch
                    checked={record.STATUS === 1}
                    onChange={() => onSwitch(record)}
                />
            ),
        },

    ];

    return (
        <>






            <Table
                columns={columns}
                dataSource={data}
                bordered
                scroll={{
                    x: 1300,
                }}
                pagination={{
                    current: pageIndex,
                    pageSize: pageSize,
                    total: total,
                    showSizeChanger: true,
                    pageSizeOptions: [5, 10, 20],
                    onChange: handleTableChange,
                    onShowSizeChange: handleTableChange,
                }}
                onChange={handleTableChange}
            />



        </>
    );
};

export default DesignationTable;
