import React, { useEffect, useState } from "react";
import { Button, Table, Pagination, Switch, Modal } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getRole, setTableData, getRoleDetails } from "../store/dataSlice";
import { setSelectedRole, toggleNewDialog } from "../store/stateSlice";
import { GiCharacter } from "react-icons/gi";
import RoleAssignTable from "./RoleAssignTable";
import { SiReacthookform } from "react-icons/si";
const RoleTable = ({ handleRefresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const loading = useSelector((state) => state.role.data.loading);
  //console.log(loading);
  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.role.data?.tableData);
  const { pageIndex = 1, pageSize = 10 } = tableData || {};

  const fetchData = async () => {
    await dispatch(getRole({ pageIndex, pageSize }));
  };

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, handleRefresh]);

  const data = useSelector((state) => state.role.data.roleList?.data);

  const onEdit = (record) => {
    dispatch(setSelectedRole(record));
    dispatch(toggleNewDialog(true));
  };
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    //console.log(current);
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const onAssign = (record) => {
    dispatch(getRoleDetails({ ROLE_ID: record.ID }));
    dispatch(setSelectedRole(record));
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>
            <span
              onClick={() => onAssign(record)}
              className="text-2xl ml-2 text-red-500 cursor-pointer"
            >
              <SiReacthookform />
            </span>
          </div>
        </>
      ),
    },

    {
      title: <span className="text-gray-500">Name</span>,
      dataIndex: "NAME",
      width: 350,

      // render: (_, record) => ${record.FIRST_NAME} ${record.LAST_NAME},
    },

    {
      title: <span className="text-gray-500">Description</span>,
      dataIndex: "DESCRIPTION",
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      width: 150,

      render: (text, record) => {
        const handleChange = (checked) => {
          //console.log("Switch Changed to:", checked ? 1 : 0);
        };

        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <div class="spinner"></div>
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          onChange={handleTableChange}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
        />
      )}
      <Modal
        width={800}
        title="RoleAssign"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        modelOpen={isModalOpen}
      >
        <RoleAssignTable handleCancel={handleCancel} />
      </Modal>
      <style>
        {`
        .spinner {
          width: 56px;
          height: 56px;
          background: radial-gradient(farthest-side,  rgba(71, 75, 255, 0.9) 92%, rgba(0, 0, 0, 0)) 50% 0,
                radial-gradient(farthest-side, rgba(71, 75, 255, 0.4) 92%, rgba(0, 0, 0, 0)) 50% 100%,
                radial-gradient(farthest-side, rgba(71, 75, 255, 0.6) 92%, rgba(0, 0, 0, 0)) 100% 50%,
                radial-gradient(farthest-side, rgba(71, 75, 255, 0.9) 92%, rgba(0, 0, 0, 0)) 0 50%;
          background-size: 13.4px 13.4px;
          background-repeat: no-repeat;
          animation: spinner-kh173p 1s infinite ;
        }

        @keyframes spinner-kh173p {
          to {
              transform: rotate(.5turn);
          }
        }
  `}
      </style>
    </>
  );
};

export default RoleTable;
