import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getAssetRequest, putAssetRequest } from "../store/dataSlice";
import { setSelectedAssetRequest, toggleNewDialog } from "../store/stateSlice";
import { setTableData } from "../store/dataSlice";

const AssetRequestTable = () => {
  const dispatch = useDispatch();

  const data = useSelector(
    (state) => state?.assetrequest?.data?.assetrequestList?.data
  );

  const onEdit = async (record) => {
    dispatch(setSelectedAssetRequest(record));
    dispatch(toggleNewDialog(true));
  };

  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putAssetRequest(updatedRecord));
    dispatch(getAssetRequest());
  };

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.assetrequest.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const fetchData = useCallback(() => {
    dispatch(getAssetRequest({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <div className="flex items-center">
          {record.APPROVAL_STATUS === "P" && (
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>
          )}
        </div>
      ),
    },
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "EMPLOYEE_NAME",
      width: 240,
    },
    {
      title: <span className="text-gray-500">Category Name</span>,
      dataIndex: "ASSET_CATEGORY_NAME",
    },
    {
      title: <span className="text-gray-500">Asset Name</span>,
      dataIndex: "ASSET_NAME",
    },
    {
      title: <span className="text-gray-500">DATE</span>,
      dataIndex: "DATE",
    },
    {
      title: <span className="text-gray-500">REQUESTED DATE</span>,
      dataIndex: "REQUESTED_DATE",
    },
    {
      title: <span className="text-gray-500">DESCRIPTION</span>,
      dataIndex: "DESCRIPTION",
    },
    {
      title: <span className="text-gray-500">Changes</span>,
      dataIndex: "APPROVAL_STATUS",
      width: 150,
      render: (text) => (
        <>
          {text === "P" && (
            <div className="text-[#ffff00] font-medium w-[120px] w-500px bg-opacity-10 py-2 bg-[rgb(252,248,26)] rounded-md">
              <center>Pending</center>
            </div>
          )}
          {text === "A" && (
            <div className="text-[#13611a] font-medium w-[120px] w-500px bg-opacity-10 py-2 bg-[#51b15c] rounded-md">
              <center>Approved</center>
            </div>
          )}
          {text === "R" && (
            <div className="text-[#e93354] font-medium w-[120px] w-500px bg-opacity-10 py-2 bg-[#e42245] rounded-md">
              <center>Rejected</center>
            </div>
          )}
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Switch
          checked={record.STATUS === 1}
          onChange={() => onSwitch(record)}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      scroll={{
        x: 1300,
      }}
      pagination={{
        current: pageIndex,
        pageSize: pageSize,
        total: total,
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 20],
        onChange: handleTableChange,
        onShowSizeChange: handleTableChange,
      }}
      onChange={handleTableChange}
    />
  );
};

export default AssetRequestTable;
