import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetEmployeeTaskReport } from "../../../services/TaskServices";

export const getEmployeeTaskReport = createAsyncThunk(
    "task/data/getEmployeeTaskReport",
    async (data) => {
        const response = await apiGetEmployeeTaskReport(data);
        return response.data;
    }
    );
    export const initialTableData = {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        query: "",
        sort: {
          order: "",
          key: "",
        },
      };
      
      export const initialFilterData = {
        name: "",
        category: ["bags", "cloths", "devices", "shoes", "watches"],
        status: [0, 1, 2],
        productStatus: 0,
      };
      
      const dataSlice = createSlice({
        name: "TaskList/data",
        initialState: {
          loading: false,
          reportList: [],
          tableData: initialTableData,
          filterData: initialFilterData,
        },
        reducers: {
          updateProductList: (state, action) => {
            state.reportList = action.payload;
          },
          setTableData: (state, action) => {
            state.tableData = action.payload;
          },
          setFilterData: (state, action) => {
            state.filterData = action.payload;
          },
        },
        extraReducers: (builder) => {
          builder
            .addCase(getEmployeeTaskReport.fulfilled, (state, action) => {
              state.loading = false;
              state.reportList = action.payload;
            })
            .addCase(getEmployeeTaskReport.pending, (state, action) => {
              state.loading = true;
            })
            .addCase(getEmployeeTaskReport.rejected, (state, action) => {
              state.loading = false;
            })
        },
      });
      
      export const { updateProductList, setTableData, setFilterData } =
        dataSlice.actions;
      
      export default dataSlice.reducer;
