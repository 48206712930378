import ApiService from "./ApiService";

export async function apiGetAllAssetCategory(data) {
  //console.log("params", data);
  return ApiService.fetchData({
    url: "/api/assetCategory/get",
    method: "post",
    data,
  });
}
export async function apipostAssetCategory(data) {
  return ApiService.fetchData({
    url: "/api/assetCategory/create",
    method: "post",
    data,
  });
}

export async function apiputAssetCategory(data) {
  return ApiService.fetchData({
    url: "/api/assetCategory/update",
    method: "put",
    data,
  });
}
