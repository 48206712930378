import React, { useEffect, useState, useCallback } from "react";
import { Button, Table, Pagination, Switch } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getForm, putForm } from "../store/dataSlice";
import { setSelectedForm, toggleNewDialog } from "../store/stateSlice";
import { setTableData } from "../store/dataSlice";
const FormTable = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.form?.data?.formList?.data);

  //console.log(data);

  const onEdit = async (record) => {
    dispatch(setSelectedForm(record));
    dispatch(toggleNewDialog(true));
  };

  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putForm(updatedRecord));
    dispatch(getForm());
  };

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.form.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const fetchData = useCallback(() => {
    dispatch(getForm({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Name</span>,
      dataIndex: "NAME",
      width: 240,
    },
    {
      title: <span className="text-gray-500">Parent Form</span>,
      dataIndex: "FORM_NAME",
    },
    {
      title: <span className="text-gray-500">Link</span>,
      dataIndex: "LINK",
      width: 240,
    },
    {
      title: <span className="text-gray-500">Icon</span>,
      dataIndex: "ICON",
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        scroll={{
          x: 1300,
        }}
        pagination={{
          current: pageIndex,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20],
          onChange: handleTableChange,
          onShowSizeChange: handleTableChange,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default FormTable;
