import { combineReducers } from "@reduxjs/toolkit";
import state from "./stateSlice";
import data from "./dataSlice";


const Holidayreducer = combineReducers({
    state,
    data,
});

export default Holidayreducer;
