import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "clientList/state",
  initialState: {
    deleteConfirmation: false,
    selectedClient: "",
    newDialog: false,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    toggleNewDialog: (state, action) => {
      
      state.newDialog = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedClient,
  toggleNewDialog,
} = stateSlice.actions;

export default stateSlice.reducer;
