import React, { useEffect, useCallback, useState } from "react";
import {
  Button,
  Table,
  Pagination,
  Switch,
  Modal,
  Select,
  DatePicker,
  Space,
  notification,
  Spin,
} from "antd";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  setTableData,
} from "../store/dataSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleNewDialog, toggleNewModel, setSelectedModel } from "../store/stateSlice";
import LeaveFilter from "./LeaveFilter";
import { FaEye } from "react-icons/fa";
import LeaveModel from "./LeaveModel";
const LeaveTable = () => {

  const dispatch = useDispatch();
  const location = useLocation();



  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.leave.data.tableData
  );

  const dialog = useSelector((state) => state.leave.state.newDialog);
  // console.log(dialog);
  const data = useSelector((state) => state.leave.data.leaveList.data);

  const loading = useSelector((state) => state.leave.data.loading);



  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };



  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(toggleNewDialog(false));
  }, [location.pathname]);



  const columns = [
    {
      title: <span className="text-gray-800">Action</span>,
      dataIndex: "action",
      fixed: "left",
      align: "center",
      width: 110,
      render: (_, record) => (
        <>

          {record.APPROVAL_STATUS === "P" ? (
            <span
              onClick={() => onView(record)}
              className="text-xl text-[#096CAE] cursor-pointer"
            >
              <FaEye />
            </span>
          ) : null}




        </>
      ),
    },
    {
      title: <span className="text-gray-800">Employee Name</span>,
      dataIndex: "EMPLOYEE_NAME",
      fixed: "left",

      width: 250,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },



    {
      title: <span className="text-gray-800">Applied Date</span>,
      dataIndex: "APPLIED_DATE",
      width: 150,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Date</span>,
      dataIndex: "DATE",
      width: 150,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    
    {
      title: <span className="text-gray-800">Leave Type Name</span>,
      dataIndex: "LEAVE_TYPE_NAME",
      width: 250,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Leave Mode</span>,
      dataIndex: "LEAVE_MODE",
      width: 180,
      render: (text) => {
        let style;
        let displayText;

        if (text === "F") {
          style = {
            color: "#01a5a5",
            backgroundColor: "#b4fffa",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Full Day";
        } else if (text === "H") {
          style = {
            color: "#2196f3", // White text color for better contrast
            backgroundColor: "#bae7ff", // Steel blue for Half Day
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Half Day";
        }
        return <span style={style}>{displayText}</span>;
      },
    },
    {
      title: <span className="text-gray-800">Half Day Session</span>,
      dataIndex: "HALF_DAY_SESSION",
      width: 150,
      render: (text) => {
        let style;
        let displayText;

        if (text === "S") {
          style = {
            color: "#82d9b3",
            backgroundColor: "#dafbf1",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Second Session";
        } else if (text === "F") {
          style = {
            color: "#82d9b3",
            backgroundColor: "#dafbf1",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "First Session";
        } 

        return <span style={style}>{displayText}</span>;
      },
    },
    {
      title: <span className="text-gray-800">Reason</span>,
      dataIndex: "REASON",
      width: 280,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    }, 
    {
      title: <span className="text-gray-800">Remark</span>,
      dataIndex: "REMARK",
      width: 260,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Approval Status</span>,
      dataIndex: "APPROVAL_STATUS",
      width: 180,
      render: (text) => {
        let style;
        let displayText;

        if (text === "P") {
          style = {
            color: "#f3dc93",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Pending";
        } else if (text === "A") {
          style = {
            color: "#82d9b3",
            backgroundColor: "#dafbf1",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Approved";
        } else if (text === "R") {
          style = {
            color: "#ff6b6b",
            backgroundColor: "#ffd5d5",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Rejected";
        }

        return <span style={style}>{displayText}</span>;
      },
    },
    {
      title: <span className="text-gray-800">Approved Date</span>,
      dataIndex: "APPROVED_DATE",
      width: 200,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Approved By</span>,
      dataIndex: "APPROVED_BY_NAME",
      width: 250,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Reason</span>,
      dataIndex: "REASON",
      width: 400,

      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: <span className="text-gray-800">Department</span>,
      dataIndex: "DEPARTMENT",
      width: 200,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
  ];
  const ApRj = useSelector(
    (state) => state.leave.state.newModel
  );


  const onView = (record) => {
    dispatch(setSelectedModel(record));
    dispatch(toggleNewModel(true));
  };

  const handleCloseModal = () => {
    dispatch(setSelectedModel(""));
    dispatch(toggleNewModel(false));
  };


  return (
    <>

      <div>

        <LeaveFilter />





        <div style={{ marginBottom: "16px" }}>
          {loading ? (
            <div className="w-full flex justify-center h-60 items-center">
              <Spin size="large" />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 1300 }}
              pagination={{
                current: pageIndex,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 20],
                onChange: handleTableChange,
                onShowSizeChange: handleTableChange,
              }}
              onChange={handleTableChange}
            />
          )}

        </div>


        <Modal
          title={
            <span
              style={{
                color: "#096CAE",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FaUser className="mr-2" />
              ...
            </span>
          }
          open={ApRj}
          footer={null}
          style={{ top: "3%" }}
          // width={700}
          onCancel={handleCloseModal}
        >
          <LeaveModel />
        </Modal>


      </div>
    </>
  );
};

export default LeaveTable;
