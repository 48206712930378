import { combineReducers } from "@reduxjs/toolkit";
import state from "./stateSlice";
import data from "./dataSlice";

const Salaryslipreducer = combineReducers({
  state,
  data,
});

export default Salaryslipreducer;
