import ApiService from "./ApiService";

export async function apiGetAllOrder(data) {
  return ApiService.fetchData({
    url: "/api/order/get",
    method: "post",
    data,
  });
}

export async function apiPutOrder(data) {
  return ApiService.fetchData({
    url: "/api/order/update",
    method: "post",
    data,
  });
}

export async function apiGetAllOrderDetails(data) {
  return ApiService.fetchData({
    url: "/api/orderDetails/get",
    method: "post",
    data,
  });
}
