import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Dropdown,
  Upload,
  Row,
  Col,
  Button,
  Switch,
  Select,
  notification,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import BaseService from "../../../services/BaseService";
import { toggleNewDialog } from "../store/stateSlice";
import {
  postOrganisation,
  putOrganisation,
  getOrganisation,
  getStateId,
  putOrganisationimg,
} from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

import axios from "axios";

const OrganisationForm = () => {
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const selectedOrganisation = useSelector(
    (state) => state.organisation.state.selectedOrganisation
  );

  const stateType = useSelector(
    (state) => state?.organisation?.data?.stateList?.data
  );

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const { Option } = Select;

  const validationSchema = Yup.object({
    ORGANISATION: Yup.string().required("Required"),
    GST_NO: Yup.string().required("Required"),
    REGISTRATION_NO: Yup.string().required("Required"),
    ADDRESS: Yup.string().required("Required"),
    CITY: Yup.string().required("Required"),
    TEHSIL: Yup.string().required("Required"),
    DISTRICT: Yup.string().required("Required"),
    STATE_ID: Yup.string().required("Required"),
    COUNTRY: Yup.string().required("Required"),
    PINCODE: Yup.string().required("Required"),
    MOBILE_NO: Yup.string().required("Required"),
    EMAIL_ID: Yup.string().required("Required"),
    STATUS: Yup.string().required("Required"),
    ORG_IMG: Yup.string().required("Required"),
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedOrganisation) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    dispatch(getStateId());
  }, []);

  const [stateid, setStateid] = useState([]);

  useEffect(() => {
    const fetchGSTType = () => {
      const response = getStateId();
      setStateid(response.data);
    };

    fetchGSTType();
  }, []);

  const initialValues = selectedOrganisation || {
    ORGANISATION: "",
    GST_NO: "",
    REGISTRATION_NO: "",
    ADDRESS: "",
    CITY: "",
    TEHSIL: "",
    DISTRICT: "",
    STATE_ID: "",
    COUNTRY: "",
    PINCODE: "",
    MOBILE_NO: "",
    EMAIL_ID: "",
    STATUS: true,
    ORG_IMG: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log("Form values:", values);
    try {
      const action = edit
        ? await dispatch(putOrganisation(values))
        : await dispatch(postOrganisation(values));

      if (action.payload.code < 300) {
        dispatch(toggleNewDialog(false));
        dispatch(getOrganisation());
        //console.log("Form submitted successfully!");
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const [progresses, setProgresses] = useState({});
  const [percentCompletedTotal, setPercentCompletedTotal] = useState(0);
  const [GobalLabel, setGobalLabel] = useState("");

  const phot_url = BaseService.defaults.baseURL;

  const handleFileUpload = async (file, endpoint, label) => {
    setGobalLabel(label);

    //console.log("file", file);

    // Generate the formatted date and time string
    const now = new Date();
    const formattedDate = `${String(now.getFullYear()).slice(-2)}${String(
      now.getMonth() + 1
    ).padStart(2, "0")}${String(now.getDate()).padStart(2, "0")}${String(
      now.getHours()
    ).padStart(2, "0")}${String(now.getMinutes()).padStart(2, "0")}${String(
      now.getSeconds()
    ).padStart(2, "0")}${String(
      Math.floor(now.getMilliseconds() / 10)
    ).padStart(2, "0")}`;

    // Get the file extension from the original file
    const fileExtension = file.name.split(".").pop();

    // Combine the formatted date and time string with the file extension
    const newFilename = `${formattedDate}.${fileExtension}`;

    // Create FormData and append the new filename
    const formData = new FormData();
    formData.append("Image", file, newFilename);

    const config = {
      headers: {
        apikey: "hjh4653dsiivy457468asdfe",
        token: localStorage.getItem("token"),
      },
      // onUploadProgress: (progressEvent) => {
      //     const loaded = progressEvent.loaded || 0;
      //     const total = progressEvent.total || file.size;
      //     const percentCompleted = Math.round((loaded * 100) / total);
      //     setPercentCompletedTotal(percentCompleted);
      //     setProgresses((prevProgresses) => ({
      //         ...prevProgresses,
      //         [label]: percentCompleted,
      //     }));
      // },
    };

    try {
      const response = await axios.post(
        `${phot_url}/upload/orgImg`,
        formData,
        config
      );

      if (response.status === 200) {
        if (response.data.code === 200) {
          //console.log("Successful img upload..", response);
          return newFilename; // Return the new filename here
        } else {
          //console.log("error img upload..");
          return false;
        }
      } else {
        //console.log("error img upload..");
        return false;
      }
    } catch (error) {
      //console.log("error img upload..");
      return false;
    }
  };

  return (
    <>
      {JSON.stringify(stateid)}

      <div className="mt-5">
        {/* <h2 className="mb-2">Add Distributor</h2> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            isSubmitting,
            setFieldValue,
          }) => (
            <FormikForm>
              <Row gutter={16}>
                <Col span={8}>
                  {stateid?.map((item) => (
                    <div key={item}>{/* Render item details here */}</div>
                  ))}
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.ORGANISATION && errors.ORGANISATION
                        ? errors.ORGANISATION
                        : ""
                    }
                    validateStatus={
                      touched.ORGANISATION && errors.ORGANISATION
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Organisation Name </span>
                    <Input
                      name="ORGANISATION"
                      placeholder="ORGANISATION"
                      value={values.ORGANISATION}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.GST_NO && errors.GST_NO ? errors.GST_NO : ""}
                    validateStatus={
                      touched.GST_NO && errors.GST_NO ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Gst No</span>
                    <Input
                      name="GST_NO"
                      placeholder="GST_NO"
                      value={values.GST_NO}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.REGISTRATION_NO && errors.REGISTRATION_NO
                        ? errors.REGISTRATION_NO
                        : ""
                    }
                    validateStatus={
                      touched.REGISTRATION_NO && errors.REGISTRATION_NO
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Registration No</span>
                    <Input
                      name="REGISTRATION_NO"
                      placeholder="REGISTRATION_NO"
                      value={values.REGISTRATION_NO}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.COUNTRY && errors.COUNTRY ? errors.COUNTRY : ""
                    }
                    validateStatus={
                      touched.COUNTRY && errors.COUNTRY ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Country</span>
                    <Input
                      name="COUNTRY"
                      placeholder="COUNTRY"
                      value={values.COUNTRY}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.STATE_ID && errors.STATE_ID ? errors.STATE_ID : ""
                    }
                    validateStatus={
                      touched.STATE_ID && errors.STATE_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">State </span>

                    <Select
                      showSearch
                      placeholder="Select State"
                      onChange={(value) =>
                        handleChange({
                          target: { name: "STATE_ID", value },
                        })
                      }
                      value={values.STATE_ID || undefined}
                      filterOption={filterOption}
                      className="h-[40px] rounded"
                    >
                      {stateType?.map((STATE_ID) => (
                        <Option
                          key={STATE_ID.ID}
                          value={STATE_ID.ID}
                          className="text-gray-400"
                        >
                          {STATE_ID.NAME}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.DISTRICT && errors.DISTRICT ? errors.DISTRICT : ""
                    }
                    validateStatus={
                      touched.DISTRICT && errors.DISTRICT ? "error" : undefined
                    }
                  >
                    <span className="text-xs">District</span>
                    <Input
                      name="DISTRICT"
                      placeholder="DISTRICT"
                      value={values.DISTRICT}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.TEHSIL && errors.TEHSIL ? errors.TEHSIL : ""}
                    validateStatus={
                      touched.TEHSIL && errors.TEHSIL ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Tehsil</span>
                    <Input
                      name="TEHSIL"
                      placeholder="TEHSIL"
                      value={values.TEHSIL}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.CITY && errors.CITY ? errors.CITY : ""}
                    validateStatus={
                      touched.CITY && errors.CITY ? "error" : undefined
                    }
                  >
                    <span className="text-xs">City</span>
                    <Input
                      name="CITY"
                      placeholder="City"
                      value={values.CITY}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.ADDRESS && errors.ADDRESS ? errors.ADDRESS : ""
                    }
                    validateStatus={
                      touched.ADDRESS && errors.ADDRESS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Address</span>
                    <Input
                      name="ADDRESS"
                      placeholder="ADDRESS"
                      value={values.ADDRESS}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.PINCODE && errors.PINCODE ? errors.PINCODE : ""
                    }
                    validateStatus={
                      touched.PINCODE && errors.PINCODE ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Pincode</span>
                    <Input
                      name="PINCODE"
                      placeholder="PINCODE"
                      value={values.PINCODE}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.MOBILE_NO && errors.MOBILE_NO
                        ? errors.MOBILE_NO
                        : ""
                    }
                    validateStatus={
                      touched.MOBILE_NO && errors.MOBILE_NO
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Mobile No</span>
                    <Input
                      name="MOBILE_NO"
                      placeholder="MOBILE_NO"
                      value={values.MOBILE_NO}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.EMAIL_ID && errors.EMAIL_ID ? errors.EMAIL_ID : ""
                    }
                    validateStatus={
                      touched.EMAIL_ID && errors.EMAIL_ID ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Email</span>
                    <Input
                      name="EMAIL_ID"
                      placeholder="EMAIL_ID"
                      value={values.EMAIL_ID}
                      onChange={handleChange}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.ORG_IMG && errors.ORG_IMG ? errors.ORG_IMG : ""
                    }
                    validateStatus={
                      touched.ORG_IMG && errors.ORG_IMG ? "error" : undefined
                    }
                  >
                    <span className="text-xs block">Profile Photo</span>
                    <Upload
                      name="ORG_IMG"
                      customRequest={({ file, onSuccess }) => {
                        handleFileUpload(
                          file,
                          "your-upload-endpoint",
                          "ORG_IMG"
                        ).then((newFilename) => {
                          if (newFilename) {
                            onSuccess("ok");
                            setFieldValue("ORG_IMG", newFilename); // Save the new filename in form values
                          }
                        });
                      }}
                      listType="picture"
                      className="!rounded"
                      style={{ padding: "8px" }}
                    >
                      <Button
                        style={{ width: "240px", marginTop: "10px" }}
                        icon={<UploadOutlined />}
                      >
                        Click to upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    className="flex flex-col"
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Status</span>

                    <div style={{ marginTop: 8 }}>
                      <Switch
                        defaultChecked
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        style={{ width: 50 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end ">
                    <Button
                      onClick={handleCancel}
                      type="primary"
                      className="mr-4 mt-6"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default OrganisationForm;
