import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "eomList/state",
  initialState: {
    deleteConfirmation: false,
    selectedEom: "",
    newDialog: false,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedEom: (state, action) => {
      state.selectedEom = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
  },
});

export const { toggleDeleteConfirmation, setSelectedEom, toggleNewDialog } =
  stateSlice.actions;

export default stateSlice.reducer;
