import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'employeetargetList/state',
    initialState: {
        deleteConfirmation: false,
        selectedEmployeeTarget: '',
        selectedEmployeeTargetInner: '',
        newDialog: false,
        newDialogFilter: false,
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedEmployeeTarget: (state, action) => {
            state.selectedEmployeeTarget = action.payload
        },
        setSelectedEmployeeTargetInner: (state, action) => {
            state.selectedEmployeeTargetInner = action.payload
        },
        toggleNewDialog: (state, action) => {
            state.newDialog = action.payload
        },
        toggleFilter: (state, action) => {
            state.newDialogFilter = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedEmployeeTarget, toggleNewDialog, setSelectedEmployeeTargetInner, toggleFilter } =
    stateSlice.actions

export default stateSlice.reducer


