import { useNavigate } from "react-router-dom";
import { apiLogout } from "../../services/AuthService";
import { persistor } from "../../store/index";

const logout = async () => {
  try {
    const userData = JSON.parse(localStorage.getItem("UserData"));
    const EMP_ID = userData[0].EMP_ID;
    const action = await apiLogout({ EMP_ID });

    if (action.data.code === 200) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("UserData");
      // localStorage.removeItem("ROLE_ID");
      localStorage.clear();
      persistor.flush();
      persistor.purge();

      persistor.pause();

      window.location.href = "/login";
    }

    // Redirect to the login page
  } catch (error) {
    console.error("Logout failed", error);
  }
};

export default logout;
