import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import OrganisationForm from "./Components/OrganisationForm";
import OrganisationTable from "./Components/OrganisationTable";
import { injectReducer } from "../../store";
import Organisationreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedOrganisation, toggleImageBox, setSelectedOrganisationImage } from "./store/stateSlice";
import OrganisationSearch from "./Components/OrganisationSearch";
import { getOrganisation } from "./store/dataSlice";
import OrganisationImage from "./Components/OrganisationImage";
injectReducer("organisation", Organisationreducer);




const Organisation = () => {
  const dialog = useSelector((state) => state.organisation.state.newDialog);
  const imageDialogs = useSelector((state) => state.organisation.state.imageDialog);
  const AddEdit = useSelector((state) => state.organisation.state.selectedOrganisation);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedOrganisation(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); // Close the modal
  };


  const handleCloseModalImage = () => {
    // dispatch(setSelectedOrganisationImage(''));
    dispatch(toggleImageBox(false)); // Close the modal
  };



  const handleRefresh = () => {
    dispatch(getOrganisation());
  };


  useEffect(() => {
    handleRefresh();
  }, [dispatch]);



  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Organisation Detail  </div>
          <div className="flex">
            <div className="flex items-center">
              <OrganisationSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                {/* {JSON.stringify(imageDialogs)} */}
                <p>Add Organisation</p>

              </Button>
            </div>
          </div>

          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Organisation" : "Add New Organisation"}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            width={800}
            onCancel={handleCloseModal}
          >
            <OrganisationForm />
          </Modal>





          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >

                <FaUser className="mr-2" />
                Organisation Image
              </span>
            }
            open={imageDialogs}
            footer={null}
            style={{ top: "3%" }}
            // width={700}
            onCancel={handleCloseModalImage}
          >


            <OrganisationImage />
          </Modal>

        </div>
        <OrganisationTable />
      </div>
    </>
  );
};



export default Organisation;
