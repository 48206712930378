import React, { useEffect, useState } from "react";
import { Button, Modal, Select, DatePicker, Radio, Space } from "antd";
import { FaFilter } from "react-icons/fa";
import TaskForm from "./components/TaskForm";
import TaskTable from "./components/TaskTable";
import TaskSearch from "./components/TaskSearch";

import { injectReducer } from "../../store";
import TaskMasterreducer from "./store";
import { useSelector, useDispatch } from "react-redux";

import { getTask, getEmployee } from "./store/dataSlice";
injectReducer("taskmaster", TaskMasterreducer);

const { Option } = Select;
const TaskMaster = () => {
  const dispatch = useDispatch();

  const employee = useSelector(
    (state) => state.taskmaster.data.taskMasterList.data
  );

  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    PRIORITY: "",
    EMP_ID: null,
    ASSIGNED_BY_ID: null,
    COMPLETION_STATUS: "",
    FROM_DATE: null,
    TO_DATE: null,
  });

  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const handleSubmit = (filters) => {
    dispatch(getTask(filters));
    setShowFilter(false);
  };

  useEffect(() => {
    dispatch(getEmployee());
  }, [dispatch]);

  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Task</div>
          <div className="">
            <div className="flex items-center ">
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
              <TaskSearch />
            </div>
          </div>
        </div>
        <div className="filter mb-4">
          {showFilter && (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4 mb-4">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Employee
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      filterOption={filterOption}
                      mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Employees"
                      onChange={(value) => handleChange("EMP_ID", value)}
                    >
                      {employee.map(
                        ({ ID, FIRST_NAME, MIDDLE_NAME, LAST_NAME }) => {
                          const fullName = [FIRST_NAME, MIDDLE_NAME, LAST_NAME]
                            .filter(Boolean)
                            .join(" ");

                          return fullName ? (
                            <Option key={ID} value={ID}>
                              {fullName}
                            </Option>
                          ) : null;
                        }
                      )}
                    </Select>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    Assigned by
                  </label>
                  <div className="">
                    <Select
                      mode="multiple"
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select a Person"
                      className="w-full h-11 "
                      onChange={(value) =>
                        handleChange("ASSIGNED_BY_ID", value)
                      }
                    >
                      {employee.map(
                        ({ ID, FIRST_NAME, MIDDLE_NAME, LAST_NAME }) => {
                          // Filter out null or undefined values and join the names with a space
                          const fullName = [FIRST_NAME, MIDDLE_NAME, LAST_NAME]
                            .filter(Boolean)
                            .join(" ");

                          // Only render the option if fullName is not empty
                          return fullName ? (
                            <Option key={ID} value={ID}>
                              {fullName}
                            </Option>
                          ) : null;
                        }
                      )}
                    </Select>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    From Date
                  </label>
                  <div className="">
                    <DatePicker
                      className="w-full  h-11"
                      style={{ padding: "0.5rem" }}
                      onChange={(date, dateString) =>
                        handleChange("START_DATETIME", dateString)
                      }
                    />
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    To Date
                  </label>
                  <div className="">
                    <DatePicker
                      className="w-full h-11"
                      style={{ padding: "0.5rem" }}
                      onChange={(date, dateString) =>
                        handleChange("END_DATETIME", dateString)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-end justify-between mb-4 mt-6">
                <div className="grid grid-cols-3 w-full">
                  <div className="col-span-4 md:col-span-1">
                    <label className="block mb-1 font-semibold text-gray-500">
                      Completed Status
                    </label>
                    <div className="">
                      <Radio.Group
                        onChange={(e) =>
                          handleChange("COMPLETION_STATUS", e.target.value)
                        }
                        className="w-full"
                        value={filters.COMPLETION_STATUS}
                      >
                        <Radio value="">All</Radio>
                        <Radio value="P">Pending</Radio>
                        <Radio value="C">Completed</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="col-span-4 md:col-span-1">
                    <label className="block mb-1 font-semibold text-gray-500">
                      Priority
                    </label>
                    <div className="">
                      <Radio.Group
                        onChange={(e) =>
                          handleChange("PRIORITY", e.target.value)
                        }
                        className="w-full"
                        value={filters.PRIORITY}
                      >
                        <Radio value="">All</Radio>
                        <Radio value="3">High</Radio>
                        <Radio value="2">Medium</Radio>
                        <Radio value="1">Low</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      setFilters({
                        PRIORITY: "",
                        EMP_ID: null,
                        ASSIGNED_BY_ID: null,
                        COMPLETION_STATUS: "",
                        START_DATETIME: null,
                        END_DATETIME: null,
                      });
                      setShowFilter(false);
                      dispatch(getTask());
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <TaskTable />
      </div>
    </>
  );
};

export default TaskMaster;
