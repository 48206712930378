import ApiService from "./ApiService";

export async function apiGetAllExpense(data) {

    return ApiService.fetchData({
        url: "/api/expense/get",
        method: "post",
        data,
    });
}

export async function apipostExpense(data) {
    return ApiService.fetchData({
        url: "/api/expense/create",
        method: "post",
        data,
    });
}

export async function apiputExpense(data) {
    return ApiService.fetchData({
        url: "/api/expense/update",
        method: "put",
        data,
    });
}


export async function apiGetExpenseTypeId(data) {

    return ApiService.fetchData({
        url: "/api/expenseType/get",
        method: "post",
        data,
    });
}


