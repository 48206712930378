import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'accountgroupList/state',
    initialState: {
        deleteConfirmation: false,
        selectedAccountgroup: '',
        newDialog: false,
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setselectedAccountgroup: (state, action) => {
            state.selectedAccountgroup = action.payload
        },
        toggleNewDialog: (state, action) => {
            state.newDialog = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setselectedAccountgroup, toggleNewDialog } =
    stateSlice.actions

export default stateSlice.reducer


