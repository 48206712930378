import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "salaryslipList/state",
  initialState: {
    deleteConfirmation: false,
    selectedSalarySlip: "",
    selectedSalarySlipinner: "",
    drawerVisible: false,
    newDialog: false,
    newDialog2: false,

    drowerDialog: false,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedSalarySlip: (state, action) => {
      state.selectedSalarySlip = action.payload;
    },
    setSelectedSalarySlipinner: (state, action) => {
      state.selectedSalarySlipinner = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.drawerVisible = action.payload !== undefined ? action.payload : !state.drawerVisible;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    toggleNewDialog2: (state, action) => {
      state.newDialog2 = action.payload;
    },
    toggleDrower: (state, action) => {
      state.drowerDialog = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  toggleDrower,
  setSelectedSalarySlip,
  toggleNewDialog,
  setSelectedSalarySlipinner,
  toggleNewDialog2,
} = stateSlice.actions;

export default stateSlice.reducer;
