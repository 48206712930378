import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Spin, Table } from "antd";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { setTableData } from "../store/dataSlice";
import { useDispatch } from "react-redux";
import { GrMapLocation } from "react-icons/gr";
import { toggleNewDialog } from "../store/stateSlice";
import { useLocation } from "react-router-dom";

import LocationFilter from "./LocationFilter";


const LocationTable = () => {
  const data = useSelector((state) => state.location.data.locationList?.data);
  const [showDialog, setShowDialog] = useState(false);
  const dialog = useSelector((state) => state?.location?.state?.newDialog);
  const loading = useSelector((state) => state.location.data.loading);

  const location = useLocation();
  const dispatch = useDispatch();
  const [dialogCoordinates, setDialogCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });



  const handleLocationButtonClick = (latitude, longitude) => {
    setDialogCoordinates({ latitude, longitude });
    const convertedLatitude = convertDMSToDD(latitude);
    const convertedLongitude = convertDMSToDD(longitude);
    const url = `http://www.google.com/maps/place/${convertedLatitude},${convertedLongitude}`;
    window.open(url, "_blank");
  };

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.location.data.tableData
  );

  const convertDMSToDD = (coordinate) => {
    const parts = coordinate.split(/[^\d\w\.]+/);
    const degrees = parseInt(parts[0]); // Degrees part
    const minutes = parseInt(parts[1]); // Minutes part
    const seconds = parseFloat(parts[2]); // Seconds part
    // Calculate decimal degrees
    const decimalDegrees = degrees + minutes / 60 + seconds / 3600;
    // Check the hemisphere (N/S or E/W) and adjust the sign accordingly
    const hemisphere = parts[3]; // N, S, E, or W

    if (hemisphere === "S" || hemisphere === "W") {
      return decimalDegrees;
    }
    return decimalDegrees;
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };


  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  // Helper function to format time to 12-hour format
  const formatTime12Hour = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour}:${minutes} ${ampm}`;
  };




  const columns = [
    {
      title: "LOCATION",
      dataIndex: "LOCATION",
      width: 100,
      align: "center",
      render: (location, record) => (
        <GrMapLocation
          icon={faMapLocation}
          className="text-green-400 cursor-pointer text-2xl"
          onClick={() =>
            handleLocationButtonClick(record.LATITUDE, record.LONGITUDE)
          }

        // style={{ textAlign: "center" }}
        />
      ),
    },
    {
      title: "TIME",
      dataIndex: "TIME",
      render: (time) => formatTime12Hour(time),
    },
    {
      title: "EMPLOYEE NAME",
      dataIndex: "EMP_NAME",
    },
    {
      title: "DATE",
      dataIndex: "DATE",
      render: (date) => format(new Date(date), "dd-MM-yyyy"),
    },
  ];



  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(toggleNewDialog(false));
  }, [location.pathname]);





  return (
    <>



      <div>
        <LocationFilter />
        {loading ? (
          <div className="w-full flex justify-center h-60 items-center">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            bordered
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onChange: handleTableChange,
              onShowSizeChange: handleTableChange,
            }}
            onChange={handleTableChange}
          />
        )}
      </div >


      {showDialog && (
        <div className="dialog">
          <h2>Location Details</h2>
          <p>Latitude: {dialogCoordinates.latitude}</p>
          <p>Longitude: {dialogCoordinates.longitude}</p>
          <button onClick={handleCloseDialog}>Close</button>
        </div>
      )
      }
    </>
  );
};

export default LocationTable;
