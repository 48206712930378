import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, Spin, Table } from "antd";
import { format } from "date-fns";
import { setTableData, toggleNewDialog } from "../store/stateSlice";
import AbsentReportFilter from "./AbsentReportFilter";

const AbsentReportTable = () => {
  const data = useSelector(
    (state) => state.absentReport.data.absentReportList?.data
  );
  console.log(data);
  const [showDialog, setShowDialog] = useState(false);
  const dialog = useSelector((state) => state?.absentReport?.state?.newDialog);
  const loading = useSelector((state) => state.absentReport.data.loading);
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.absentReport.data.tableData
  );

  const dispatch = useDispatch();

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "FIRST_NAME",
    },
    {
      title: "Middle Name",
      dataIndex: "MIDDLE_NAME",
    },
    {
      title: "Last Name",
      dataIndex: "LAST_NAME",
    },
    {
      title: "Branch Name",
      dataIndex: "BRANCH_NAME",
    },
    {
      title: "Department Name",
      dataIndex: "DEPARTMENT_NAME",
    },
  ];

  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(toggleNewDialog(false));
  }, [dispatch, pageSize]);

  return (
    <>
      <div>
        <AbsentReportFilter />
        {loading ? (
          <div className="w-full flex justify-center h-60 items-center">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            bordered
            pagination={{
              current: pageIndex,
              pageSize: pageSize,
              total: total,
              showSizeChanger: true,
              pageSizeOptions: [5, 10, 20],
              onChange: handleTableChange,
              onShowSizeChange: handleTableChange,
            }}
            onChange={handleTableChange}
          />
        )}
      </div>

      {showDialog && (
        <div className="dialog">
          <h2>Absent Report Details</h2>
          <button onClick={handleCloseDialog}>Close</button>
        </div>
      )}
    </>
  );
};

export default AbsentReportTable;
