import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "leaveList/state",
  initialState: {
    deleteConfirmation: false,
    selectedLeave: "",
    selectedModel: "",

    newDialog: false,
    newModel: false,
    newSubmitModel: false,


  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedLeave: (state, action) => {
      state.selectedLeave = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    toggleNewModel: (state, action) => {
      state.newModel = action.payload;
    },
    toggleNewModelSubmit: (state, action) => {
      state.newSubmitModel = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
  },
});

export const { toggleDeleteConfirmation, setSelectedLeave, toggleNewDialog, toggleNewModel, setSelectedModel, toggleNewModelSubmit } =
  stateSlice.actions;

export default stateSlice.reducer;
