import React, { useState,useEffect } from "react";
import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";

import { injectReducer } from "../../store";
import ItemCategoryreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedItemCategory } from "./store/stateSlice";

import { getItemCategory } from "./store/dataSlice";
import Item from "antd/es/list/Item";
import ItemCategoryForm from "./components/ItemCategoryForm";
import ItemCategoryTable from "./components/ItemCategoryTable";
import ItemCategorySearch from "./components/ItemCategorySearch";
injectReducer("itemCategory", ItemCategoryreducer);
const ItemCategory = () => {
  const dialog = useSelector((state) => state.itemCategory.state.newDialog);
  const selectedItemCategory = useSelector((state) => state.itemCategory.state.selectedItemCategory);
  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedItemCategory(null));
    dispatch(toggleNewDialog(true));
  };
  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); // Close the modal
  };
  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Item Category</div>
          <div className="flex">
            <div className="flex items-center">
          <ItemCategorySearch/>
          <Button
            style={{
              backgroundColor: "#096CAE",
              color: "#ffff",
              display: "flex",
              padding: "18px",
              borderRadius: "6px",
            }}
            onClick={onDialog}
          >
            <LuPlus />
            <p>Add Item Category</p>
          </Button>
          </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                 {selectedItemCategory ?'Edit Item Category': 'Add New Item Category'}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <ItemCategoryForm  />
          </Modal>
        </div>
        <ItemCategoryTable  />
      </div>
    </>
  );
};

export default ItemCategory;