import React, { useCallback, useEffect, useState } from "react";
import { Button, Table, Pagination, Spin ,Switch} from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getRoleAssign, putRoleAssign, setTableData } from "../store/dataSlice";
import { setSelectedRoleAssign, toggleNewDialog } from "../store/stateSlice";

const RoleAssignTable = ({ handleRefresh }) => {
  const dispatch = useDispatch();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.roleAssign.data.tableData
  );
  const loading = useSelector((state) => state.roleAssign.data.loading);

  const fetchData = useCallback(() => {
    dispatch(getRoleAssign({ pageIndex, pageSize }));
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, handleRefresh]);

  const data = useSelector((state) => state.roleAssign.data.roleAssignList.data);

  //console.log(data);

  const onEdit = (record) => {
    dispatch(setSelectedRoleAssign(record));
    dispatch(toggleNewDialog(true));
  };
  const onSwitch = async (record) => {
    const updatedRecord = { ...record, STATUS: record.STATUS === 1 ? 0 : 1 };
    await dispatch(putRoleAssign(updatedRecord));
    dispatch(getRoleAssign());
  };
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    //console.log(current);
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };
  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 100,
      render: (_, record) => (
        <>
          <div className="flex items-center">
            <span
              onClick={() => onEdit(record)}
              className="text-2xl text-[#096CAE] cursor-pointer"
            >
              <MdEdit />
            </span>
            {/* <span className="text-2xl ml-2 text-red-500 cursor-pointer">
              <MdDelete />
            </span> */}
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Employee Name</span>,
      dataIndex: "FIRST_NAME",
      width: 250,
      render: (_, record) =>
        `${record.FIRST_NAME} ${record.MIDDLE_NAME ? record.MIDDLE_NAME : ""} ${
          record.LAST_NAME ? record.LAST_NAME : ""
        }`,
    },
 
    {
      title: <span className="text-gray-500">Role Name</span>,
      dataIndex: "ROLE_NAME",
   
    },
    {
      title: <span className="text-gray-500">Department</span>,
      dataIndex: "DEPARTMENT",
    },
    {
      title: <span className="text-gray-500">Branch</span>,
      dataIndex: "BRANCH_NAME",
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Switch
          checked={record.STATUS === 1}
          onChange={() => onSwitch(record)}
        />
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          onChange={handleTableChange}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
        />
      )}
    </>
  );
};

export default RoleAssignTable;
