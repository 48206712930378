import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllDashboardCount,
  apiGetEmployeeSummaryReport,
  apiGetAttendanceDateWiseReport,

  apiGetLeaves,

} from "../../../services/DashboardService";


export const getLeavesdata = createAsyncThunk(
  "dashboard/data/getLeavesdata",
  async (data) => {
    const response = await apiGetLeaves(data);
    return response.data;
  }
);


export const getDashboardCount = createAsyncThunk(
  "dashboard/data/getDashboardCount",
  async (data) => {
    const response = await apiGetAllDashboardCount(data);
    return response.data;
  }
);

export const getEmployeeSummaryReport = createAsyncThunk(
  "dashboard/data/getEmployeeSummaryReport",
  async (data) => {
    const response = await apiGetEmployeeSummaryReport(data);
    return response.data;
  }
);

export const getAttendanceDateWiseReport = createAsyncThunk(
  "dashboard/data/getAttendanceDateWiseReport",
  async (data) => {
    const response = await apiGetAttendanceDateWiseReport(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "dashboardList/data",
  initialState: {
    loading: false,
    dashboardList: [],
    employeeSummaryList: [],
    leaves: [],

    attendanceDateWiseReportList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.dashboardList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },



  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCount.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardList = action.payload;
      })
      .addCase(getDashboardCount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDashboardCount.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getEmployeeSummaryReport.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeSummaryList = action.payload;
      })

      .addCase(getLeavesdata.fulfilled, (state, action) => {
        state.loading = false;
        state.leaves = action.payload;
      })

      .addCase(getAttendanceDateWiseReport.fulfilled, (state, action) => {
        state.loading = false;
        state.attendanceDateWiseReportList = action.payload;
      });
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
