import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllOrder,
  apiPutOrder,
  apiGetAllOrderDetails,
} from "../../../services/OrderService";

export const getOrder = createAsyncThunk("order/data/get", async (data) => {
  const response = await apiGetAllOrder(data);
  return response.data;
});

export const putOrder = createAsyncThunk("order/data/put", async (data) => {
  const response = await apiPutOrder(data);
  return response.data;
});

export const getOrderDetails = createAsyncThunk(
  "details/data/get",
  async (data) => {
    const response = await apiGetAllOrderDetails(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const dataSlice = createSlice({
  name: "orderList/data",
  initialState: {
    loading: false,
    orderList: [],
    details: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.orderList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.orderList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getOrder.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getOrderDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOrderDetails.rejected, (state, action) => {
        state.loading = false;
      })

      // .addCase(postState.fulfilled, (state, action) => {})
      .addCase(putOrder.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
