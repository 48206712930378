import ApiService from "./ApiService";

export async function apiGetAllCart(data) {
  return ApiService.fetchData({
    url: "/api/cart/get",
    method: "post",
    data,
  });
}

export async function apiPostCart(data) {
  return ApiService.fetchData({
    url: "/api/cart/create",
    method: "post",
    data,
  });
}

export async function apiPutCart(data) {
  return ApiService.fetchData({
    url: "/api/cart/update",
    method: "put",
    data,
  });
}
