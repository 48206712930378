import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetLeave,
  apiGetDepartment,
  apiGetBranch,
  apiGetEmployee,
  apiGetApproveLeave,
  apiGetRejectLeave,
} from "../../../services/LeaveService";


export const postApproved = createAsyncThunk(
  "leave/data/postApproved",
  async (data) => {
    const response = await apiGetApproveLeave(data);
    return response.data;
  }
);
export const postRejected = createAsyncThunk(
  "leave/data/postRejected",
  async (data) => {
    const response = await apiGetRejectLeave(data);
    return response.data;
  }
);


export const getLeave = createAsyncThunk("leave/data/get", async (data) => {
  const response = await apiGetLeave(data);
  return response.data;
});

export const getDepartment = createAsyncThunk(
  "leave/data/getDepartment",
  async (data) => {
    const response = await apiGetDepartment(data);
    return response.data;
  }
);

export const getBranch = createAsyncThunk(
  "leave/data/getBranch",
  async (data) => {
    const response = await apiGetBranch(data);
    return response.data;
  }
);

export const getEmployee = createAsyncThunk(
  "leave/data/getEmployee",
  async (data) => {
    const response = await apiGetEmployee(data);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const dataSlice = createSlice({
  name: "leaveList/data",
  initialState: {
    loading: false,
    leaveList: [],
    departmentList: [],
    branchList: [],
    employeeList: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.leaveList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeave.fulfilled, (state, action) => {
        state.loading = false;
        state.leaveList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getLeave.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLeave.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.departmentList = action.payload;
      })
      .addCase(getBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.branchList = action.payload;
      })
      .addCase(getEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeList = action.payload;
      })
      .addCase(postApproved.fulfilled, (state, action) => { })

      .addCase(postRejected.fulfilled, (state, action) => { });
  },
});

export const { updateProductList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
