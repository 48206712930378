import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "serviceList/state",
  initialState: {
    deleteConfirmation: false,
    selectedService: "",
    newDialog: false,
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
  },
});

export const { toggleDeleteConfirmation, setSelectedService, toggleNewDialog } =
  stateSlice.actions;

export default stateSlice.reducer;
