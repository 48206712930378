import ApiService from "./ApiService";

export async function apiGetAllRoleAssign(data) {
  return ApiService.fetchData({
    url: "/api/roleData/get",
    method: "post",
    data,
  });
}

export async function apiPostRoleAssign(data) {
  return ApiService.fetchData({
    url: "/api/roleData/create",
    method: "post",
    data,
  });
}

export async function apiPutRoleAssign(data) {
  return ApiService.fetchData({
    url: "/api/roleData/update",
    method: "put",
    data,
  });
}
