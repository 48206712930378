import ApiService from "./ApiService";

export async function apiGetAllClient(data) {
    return ApiService.fetchData({
        url:"api/client/get",
        method:"POST",
        data,
    })
}
export async function apiPostAllClient(data) {
    return ApiService.fetchData({
        url:"api/client/create",
        method:"POST",
        data,
    })
}
export async function apiPutAllClient(data) {
    return ApiService.fetchData({
        url:"api/client/update",
        method:"PUT",
        data,
    })
}