import React, { useState,useEffect } from "react";
import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";

import { injectReducer } from "../../store";
import Unitreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedUnit } from "./store/stateSlice";

import { getUnit } from "./store/dataSlice";
import UnitForm from "./components/UnitForm";
import UnitTable from "./components/UnitTable";
import UnitSearch from "./components/UnitSearch";


injectReducer("unit", Unitreducer);
const Unit = () => {
  const dialog = useSelector((state) => state.unit.state.newDialog);
  const selectedUnit = useSelector((state) => state.unit.state.selectedUnit);
  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedUnit(null));
    dispatch(toggleNewDialog(true));
  };
  const handleRefresh = () => {
    dispatch(getUnit());
  };
  useEffect(() => {
    handleRefresh();
  }, [dispatch]);
  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); // Close the modal
  };
  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Unit</div>
          <div className="flex">
            <div className="flex items-center">
          <UnitSearch/>
          <Button
            style={{
              backgroundColor: "#096CAE",
              color: "#ffff",
              display: "flex",
              padding: "18px",
              borderRadius: "6px",
            }}
            onClick={onDialog}
          >
            <LuPlus />
            <p>Add Unit</p>
          </Button>
          </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                 {selectedUnit?'Edit Unit':'Add New Unit'}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <UnitForm handleRefresh={handleRefresh} />
          </Modal>
        </div>
        <UnitTable handleRefresh={handleRefresh} />
      </div>
    </>
  );
};

export default Unit;
