import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import BoxElement from "./components/BoxElement";
// import SalesOverview from "./components/SalesOverview";
import TopsellerTable from "./components/TopsellerTable";
import Dashboardreducer from "./store";
import { injectReducer } from "../../store";
import AttendanceReport from "./components/AttendanceReport";
injectReducer("dashboard", Dashboardreducer);

const Dashboardindex = () => {
  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails[0]?.EMP_ID;

  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it

  const dashboardMenu = menuList?.find((item) => item.title === "Dashboard");

  // if (dashboardMenu) {
  //   console.log('Dashboard is present:', dashboardMenu);
  // } else {
  //   console.log('Dashboard is not present');
  // }

  return (
    <>
      {dashboardMenu ? (
        ROLE_ID === 3 ? (
          <>
            <div class="text-center">
              <p class="text-blue-500 text-2xl font-semibold">
                You are not authorized to access the Dashboard
              </p>
            </div>
          </>
        ) : (
          <>
            <BoxElement />
            <AttendanceReport />
            <TopsellerTable />
          </>
        )
      ) : (
        <div class="text-center">
          <p class="text-blue-500 text-2xl font-semibold">
            You are not authorized to access the Dashboard
          </p>
        </div>
      )}
    </>
  );
};

export default Dashboardindex;
