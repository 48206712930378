import ApiService from "./ApiService";

export async function apiGetAllItem(data) {

    return ApiService.fetchData({
        url: "/api/item/get",
        method: "post",
        data,
    });
}

export async function apiGetUnit(data) {

    return ApiService.fetchData({
        url: "/api/unit/get",
        method: "post",
        data,
    });
}


export async function apiGetCategory(data) {

    return ApiService.fetchData({
        url: "/api/itemCategory/get",
        method: "post",
        data,
    });
}






export async function apipostItem(data) {
    return ApiService.fetchData({
        url: "/api/item/create",
        method: "post",
        data,
    });
}

export async function apiputItem(data) {
    return ApiService.fetchData({
        url: "/api/item/update",
        method: "put",
        data,
    });
}



export async function apigetStateId(data) {
    return ApiService.fetchData({
        url: "/api/state/getStateData",
        method: "post",
        data,
    });
}



export async function apipostItemimg(data) {
    return ApiService.fetchData({
        url: "/upload/orgImg",
        method: "post",
        data,
        headers: {
            "Content-Type": "application/octet-stream",
        },

    });
}

