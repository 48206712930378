import ApiService from "./ApiService";

export async function apiGetAllState(data) {
  return ApiService.fetchData({
    url: "/api/state/get",
    method: "post",
    data,
  });
}

export async function apiPostState(data) {
  return ApiService.fetchData({
    url: "/api/state/create",
    method: "post",
    data,
  });
}

export async function apiPutState(data) {
  return ApiService.fetchData({
    url: "/api/state/update",
    method: "put",
    data,
  });
}
