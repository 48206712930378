import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";

import LeaveTable from "./components/LeaveTable";
import LeaveSearch from "./components/LeaveSearch";
import LeaveFilter from "./components/LeaveFilter";

import { injectReducer } from "../../store";

import Leavereducer from "./store";

import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog } from "./store/stateSlice";
import { getBranch, getDepartment, getEmployee } from "./store/dataSlice";
injectReducer("leave", Leavereducer);

const Leave = () => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);

  const dialog = useSelector((state) => state.leave.state.newDialog);

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };
  let ROLE_ID = localStorage.getItem("ROLE_ID");
  const userData = localStorage.getItem("UserData");
  const roleDetails = JSON.parse(userData);
  const EmpId = roleDetails[0]?.EMP_ID;
  const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it

  return (
    <>


      {ROLE_ID != 3 && ROLE_ID != 4 ? (
        <div className="bg-white m-4 p-8 rounded-xl">
          <div className="flex justify-between mb-6">
            <div className="text-xl font-bold text-[#414141]">Leave </div>
            <div className="flex">
              <div className="flex items-center">
                <div
                  className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                  onClick={handleFilterClick}
                >
                  <FaFilter />
                </div>
                {/* <LeaveSearch /> */}
              </div>
            </div>
          </div>
          {/* {showFilter && <LeaveFilter />} */}
          <LeaveTable />
        </div>
      ) : null}



    </>
  );
};

export default Leave;
