import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'locationList/state',
    initialState: {
        deleteConfirmation: false,
        selectedTask: '',
        newDialog: false,
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedTask: (state, action) => {
            state.selectedTask = action.payload
        },
        toggleNewDialog: (state, action) => {
            state.newDialog = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedTask,toggleNewDialog } =
    stateSlice.actions

export default stateSlice.reducer