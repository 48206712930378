import ApiService from "./ApiService";

export async function apiGetAllItemCategory(data) {

    return ApiService.fetchData({
      url: "/api/itemCategory/get",
      method: "post",
      data,
    });
  }
  
  export async function apiPostItemCategory(data) {
    return ApiService.fetchData({
      url: "/api/itemCategory/create",
      method: "post",
      data,
    });
  }
  
  export async function apiPutItemCategory(data) {
    return ApiService.fetchData({
      url: "/api/itemCategory/update",
      method: "put",
      data,
    });
  }