import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Tag, Button } from "antd";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerDetails, setTableData } from "../store/dataSlice";
import { notification } from "antd";

const CustomerDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const { record } = location.state;

  const [activeStatus, setActiveStatus] = useState("");
  const customerDetails = useSelector(
    (state) => state.customer.data.customerDetails.data
  );

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.customer.data.tableData
  );

  const fetchData = useCallback(() => {
    if (record?.ID) {
      dispatch(
        getCustomerDetails({
          CUSTOMER_ID: record.ID,
          pageIndex,
          pageSize,
          IS_CONVERTED: activeStatus || null,
        })
      );
    }
  }, [pageIndex, pageSize, activeStatus]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!record) {
    navigate("/customer");
  }

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const handleStatusChange = (newStatus) => {
    setActiveStatus(newStatus);
    // dispatch(getCustomerDetails({ IS_CONVERTED: newStatus || null }));
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "CUSTOMER_NAME",
      width: 220,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "SERVICE_NAME",
      width: 220,

      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "AMOUNT",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Description",
      dataIndex: "DESCRIPTION",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Date",
      dataIndex: "INTERESTED_DATETIME",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },

    {
      title: "Action Date",
      dataIndex: "CONVERTED_DATETIME",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "IS_CONVERTED",
      render: (text) => {
        let style;
        let displayText;

        if (text === "P") {
          style = {
            color: "#f3dc93",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Pending";
        } else if (text === "A") {
          style = {
            color: "#2196f3",
            backgroundColor: "#e3f2fd",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Closed";
        } else if (text === "R") {
          style = {
            color: "#ff6b6b",
            backgroundColor: "#fdfbee",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Rejected";
        } else if (text === "C") {
          style = {
            color: "#82d9b3",
            backgroundColor: "#dafbf1",
            padding: "5px",
            borderRadius: "4px",
            fontWeight: "bold",
          };
          displayText = "Completed";
        }

        return <span style={style}>{displayText}</span>;
      },
    },
  ];

  return (
    <>
      <div
        style={{ marginBottom: "16px" }}
        className="m-4 p-10 bg-white rounded-lg shadow"
      >
        <div className="mb-4 flex justify-between">
          <div className=" flex gap-2 ">
            <Button
              onClick={() => handleStatusChange("")}
              style={{
                backgroundColor: activeStatus === "" ? "#1890ff" : "",
                color: activeStatus === "" ? "#fff" : "",
              }}
            >
              All
            </Button>
            <Button
              onClick={() => handleStatusChange("P")}
              style={{
                backgroundColor: activeStatus === "P" ? "#1890ff" : "",
                color: activeStatus === "P" ? "#fff" : "",
              }}
            >
              Pending
            </Button>
            <Button
              onClick={() => handleStatusChange("A")}
              style={{
                backgroundColor: activeStatus === "A" ? "#1890ff" : "",
                color: activeStatus === "A" ? "#fff" : "",
              }}
            >
              Closed
            </Button>
            <Button
              onClick={() => handleStatusChange("R")}
              style={{
                backgroundColor: activeStatus === "R" ? "#1890ff" : "",
                color: activeStatus === "R" ? "#fff" : "",
              }}
            >
              Rejected
            </Button>
          </div>
          <div className="justify-end">
            <Button
              onClick={() => navigate("/customer")}
              style={{
                backgroundColor: "#1890ff",
                color: "#fff",
              }}
            >
              <RiArrowGoBackFill />
              Go Back
            </Button>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={customerDetails}
          bordered
          scroll={{ x: 1300 }}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default CustomerDetails;
