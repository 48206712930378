import React, { useEffect, useCallback } from "react";
import { Table, Select, Switch } from "antd";
import { CgFileDocument } from "react-icons/cg";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { getState, setTableData } from "../store/dataSlice";
import { setSelectedDistributor, toggleNewDialog } from "../store/stateSlice";

const StateTable = () => {
  const dispatch = useDispatch();
  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.state.data.tableData
  );
  const fetchData = useCallback(() => {
    dispatch(getState({ pageIndex, pageSize }));
  }, [dispatch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize]);

  const data = useSelector((state) => state.state.data.stateList.data);

  //console.log(data);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const onEdit = (record) => {
    dispatch(setSelectedDistributor(record));
    dispatch(toggleNewDialog(true));
  };

  const columns = [
    {
      title: <span className="text-gray-500">Action</span>,
      dataIndex: "action",
      fixed: "left",
      width: 60,
      align: "center",
      render: (_, record) => (
        <>
          <div className="flex justify-between px-6">
            <span
              onClick={() => onEdit(record)}
              className="text-xl text-[#096CAE] cursor-pointer"
            >
              <FaRegEdit />
            </span>
          </div>
        </>
      ),
    },
    {
      title: <span className="text-gray-500">Name</span>,
      dataIndex: "NAME",
      width: 250,
    },

    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "STATUS",
      width: 250,
      render: (text, record) => {
        const handleChange = (checked) => {
          
          //console.log("Switch Changed to:", checked ? 1 : 0);
        };

        return <Switch checked={text === 1} onChange={handleChange} />;
      },
    },
    {
      title: <span className="text-gray-500">Short Code</span>,
      width: 250,
      dataIndex: "SHORT_CODE",
    },
  ];

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ x: 1300 }}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default StateTable;
