import React from "react";
import { FaFilter } from "react-icons/fa";

import AttendanceTable from "./Components/AttendanceTable";
import { injectReducer } from "../../store";
import Attendancereducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog } from "./store/stateSlice";
import { AiFillFileExcel } from "react-icons/ai";
import * as XLSX from "xlsx";

injectReducer("attendance", Attendancereducer);

const Attendance = () => {
  const dialog = useSelector((state) => state.attendance.state.newDialog);
  const allData = useSelector(
    (state) => state?.attendance?.data?.attendanceList?.data
  );

  const dispatch = useDispatch();

  // filter ----------------------

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };
  const exportToExcel = () => {
    if (allData && allData.length > 0) {
      // Map through allData to transform the data as needed
      const transformedData = allData.map(
        ({
          ID,
          BRANCH_ID,
          DAYIN_IMG_URL,
          DAYOUT_IMG_URL,
          DAYIN_LOCATION,
          DEPARTMENT_ID,
          REPORTING_HEAD_ID,
          TEMPORARY_HEAD_ID,
          EMP_NAME,
          DATE,
          DAYIN_TIME,
          DAYOUT_TIME,
          BRANCH_NAME,
          DEPARTMENT_NAME,
          DAYIN_DISTANCE,
          DAYOUT_DISTANCE,
          DAYIN_REMARK,
          DAYOUT_REMARK,
          DAYIN_DEVICE_ID,
          ATTENDENCE_STATUS,
          ...rest
        }) => ({
          Employee_Name: EMP_NAME,
          Date: DATE,
          DayIn: DAYIN_TIME,
          DayOut: DAYOUT_TIME,
          Branch: BRANCH_NAME,
          Department: DEPARTMENT_NAME,
          DayIn_Distance: DAYIN_DISTANCE,
          DayOut_Distance: DAYOUT_DISTANCE,
          DayIn_Remark: DAYIN_REMARK,
          DayOut_Remark: DAYOUT_REMARK,
          DayIn_Device_ID: DAYIN_DEVICE_ID,
          Attendance_Status:
            ATTENDENCE_STATUS === "S"
              ? "Started"
              : ATTENDENCE_STATUS === "E"
              ? "Ended"
              : ATTENDENCE_STATUS,
        })
      );

      const ws = XLSX.utils.json_to_sheet(transformedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "attendence_data.xlsx");
    } else {
      console.log("No data available to export");
    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">
            Attendance Master
          </div>
          <div className="flex">
            <div className="flex items-center">
            <div
                className="bg-[#096CAE] p-[9px] rounded text-xl mr-2 text-white cursor-pointer"
                onClick={exportToExcel}
              >
                <AiFillFileExcel />
              </div>
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
            </div>
          </div>
        </div>

        <AttendanceTable />
      </div>
    </>
  );
};

export default Attendance;
