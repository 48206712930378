import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Form, Input, Row, Col, Button ,Select,notification,Switch} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
 getUnit,
  postUnit,
  putUnit,
} from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";


const UnitForm = () => {
  const [edit, setEdit] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const selectedUnit = useSelector(
    (state) => state.unit.state.selectedUnit
  );

  const validationSchema = Yup.object({
    NAME: Yup.string().required("Required"),
    STATUS: Yup.string().required("Required"),
    SHORT_CODE: Yup.string().required("Required") 
  
  });

  const handleCancel = () => {
    dispatch(toggleNewDialog(false));
  };

  useEffect(() => {
    if (selectedUnit) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedUnit]);
  


  const initialValues = selectedUnit || {
    NAME: "",
    STATUS:true,
    SHORT_CODE:""
  };
  const handleSubmit = async (values,{setSubmitting}) => {
    //console.log(values);
    try {
      const action = edit
        ? await dispatch(putUnit(values))
        : await dispatch(postUnit(values));

      if (action.payload.status < 300) {
        dispatch(toggleNewDialog(false));
        dispatch(getUnit());
        //console.log("Form submitted successfully!");
        api.success({
          message: "Form Submitted Successfully.",
        });
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-4">
        {/* <h2 className="mb-2">Add Distributor</h2> */}
        {contextHolder}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form
              className=""
              onFinish={handleSubmit}
              onFinishFailed={(errorInfo) => {
                console.error("Failed:", errorInfo);
              }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.NAME && errors.NAME
                        ? errors.NAME
                        : ""
                    }
                    validateStatus={
                      touched.NAME && errors.NAME
                        ? "error"
                        : undefined
                    }
                  >
                    <span className="text-xs">Name</span>
                    <Input
                      name="NAME"
                      placeholder="Name"
                      value={values.NAME}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
             
              
               
                <Col span={24}>
                  <Form.Item
                    className="flex flex-col"
                    help={
                      touched.SHORT_CODE && errors.SHORT_CODE ? errors.SHORT_CODE : ""
                    }
                    validateStatus={
                      touched.SHORT_CODE && errors.SHORT_CODE ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Short Code</span>
                    <Input
                      name="SHORT_CODE"
                      placeholder="SHORT CODE"
                      value={values.SHORT_CODE}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="!rounded"
                      style={{ padding: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  className="flex flex-col"
                  help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                  validateStatus={
                    touched.STATUS && errors.STATUS ? "error" : undefined
                  }
                >
                  <span className="text-xs">Status</span>

                  <div style={{ marginTop: 8 }}>
                    <Switch
                      defaultChecked
                      checked={values.STATUS}
                      onChange={(checked) => setFieldValue("STATUS", checked)}
                      style={{ width: 50 }}
                    />
                  </div>
                </Form.Item>
                
           
             
              
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end">
                    <Button
                      onClick={handleCancel}
                      type="primary"
                      className="mr-4"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {edit ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UnitForm;
