import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllEmployeeId, apiGetAllDepartmentId, apiGetLocation, apiGetAllBranchId,
} from "../../../services/LocationServices";

export const getLocation = createAsyncThunk(
  "/api/location/get",
  async (data) => {
    const response = await apiGetLocation(data);
    return response.data;
  }
);
export const getEmployeeId = createAsyncThunk(
  "/api/employee/getEmployeeData",
  async (data) => {
    const response = await apiGetAllEmployeeId(data);
    //console.log("response.data", response.data);
    return response.data;
  }
);
export const getDepartmentId = createAsyncThunk(
  "department/data/getDepartmentId",
  async (data) => {
    const response = await apiGetAllDepartmentId(data);
    return response.data;
  }
);

export const getBranchId = createAsyncThunk(
  "branch/data/getBranchId",
  async (data) => {
    const response = await apiGetAllBranchId(data);
    return response.data;
  }
);
export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "locationList/data",
  initialState: {
    loading: false,
    employeeIdList: [],
    employeeList: [],
    departmentIdList: [],
    locationList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.employeeList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.locationList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getLocation.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLocation.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getEmployeeId.fulfilled, (state, action) => {
        state.employeeIdList = action.payload;
      })

      .addCase(getDepartmentId.fulfilled, (state, action) => {
        state.departmentIdList = action.payload;
      })

      .addCase(getBranchId.fulfilled, (state, action) => {
        state.branchIdList = action.payload;
      })

  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;