import ApiService from "./ApiService";

export async function apiGetAllUnit(data) {

  return ApiService.fetchData({
    url: "/api/unit/get",
    method: "post",
    data,
  });
}

export async function apipostUnit(data) {
  return ApiService.fetchData({
    url: "/api/unit/create",
    method: "post",
    data,
  });
}

export async function apiputUnit(data) {
  return ApiService.fetchData({
    url: "/api/unit/update",
    method: "put",
    data,
  });
}


