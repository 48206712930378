import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BaseService from "../../../services/BaseService";
import axios from "axios";
import { Spin, Button } from "antd";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { FaArrowsRotate } from "react-icons/fa6";

const AttendanceImage = () => {
  const img = useSelector((state) => state.attendance.state.selectedImage1);
  let url2;
  if (img.count === 1) {
    url2 = "/static/dayinImg/";
  }
  if (img.count === 2) {
    url2 = "/static/dayoutImg/";
  }

  const [imageUrls, setImageUrls] = useState({});
  const [loading, setLoading] = useState(true);
  const [rotation, setRotation] = useState(0);

  const fetchImage = async () => {
    const config = {
      responseType: "blob",
    };

    try {
      const response = await axios.get(
        `${BaseService.defaults.baseURL}${url2}${img.image}`,
        config
      );

      const url = URL.createObjectURL(response.data);
      setImageUrls((prevUrls) => ({ ...prevUrls, [img.image]: url }));
    } catch (error) {
      console.error("Error fetching image:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchImage();
  }, [img]);

  const rotateImage = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  return (
    <div className="mt-5">
      <center>
        {loading ? (
          <Spin size="large" />
        ) : (
          <>
            <Zoom>
              <div
                style={{
                  width: "50%",
                  height: "50%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={imageUrls[img.image]}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transform: `rotate(${rotation}deg)`,
                    transition: "transform 0.3s",
                  }}
                />
              </div>
            </Zoom>
            <Button onClick={rotateImage} style={{ marginTop: "10px" }}>
              <FaArrowsRotate className="text-[blue]" />
            </Button>
          </>
        )}
      </center>
    </div>
  );
};

export default AttendanceImage;
