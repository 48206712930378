import ApiService from "./ApiService";

export async function apiGetAllAssetRequest(data) {
  return ApiService.fetchData({
    url: "/api/assetRequest/get",
    method: "post",
    data,
  });
}

export async function apipostAssetRequest(data) {
  return ApiService.fetchData({
    url: "/api/assetRequest/create",
    method: "post",
    data,
  });
}

export async function apiputAssetRequest(data) {
  return ApiService.fetchData({
    url: "/api/assetRequest/update",
    method: "put",
    data,
  });
}
