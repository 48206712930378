import React, { useEffect, useState } from "react";
import { Menu, Flex } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMenu } from "./store/dataSlice";
import { injectReducer } from "../../store";
import Menureducer from "./store";
import logo from "../../assets/images/logo/ra-masale.png";

import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";

import * as GrIcons from "react-icons/gr";
import * as FaIcons from "react-icons/fa";
import * as Fa6Icons from "react-icons/fa6";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as SiIcons from "react-icons/si";
import * as MdIcons from "react-icons/md";
import * as FcIcons from "react-icons/fc";

// import * as Icons from "react-icons/io";

injectReducer("menu", Menureducer);

const Icons = {
  ...IoIcons,
  ...BsIcons,
  ...GrIcons,
  ...FaIcons,
  ...RiIcons,
  ...Fa6Icons,
  ...BiIcons,
  ...MdIcons,
  ...FcIcons,
};

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [openKeys, setOpenKeys] = useState([]);

  const data = useSelector((state) => state.menu.data.menuList.data);
  // console.log("menue ", data);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const generateMenuItems = (data) => {
    const sortedData = data
      ? [...data].sort((a, b) => a.SEQ_NO - b.SEQ_NO)
      : [];

    return sortedData?.map((item) => {
      let IconComponent = null;
      if (item.icon) {
        IconComponent = Icons[item.icon];
      }
      if (item.children && item.children.length > 0) {
        return {
          key: item.title,
          label: item.title,
          icon: IconComponent ? (
            <IconComponent
              className={`menu-icon ${
                selectedKey === item.link ? "selected" : ""
              }`}
            />
          ) : null,
          children: generateMenuItems(item.children),
        };
      } else {
        return {
          key: item.link,
          label: item.title,
          icon: IconComponent ? (
            <IconComponent
              className={`menu-icon ${
                selectedKey === item.link ? "selected" : ""
              }`}
            />
          ) : null,
        };
      }
    });
  };

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (openKeys.includes(latestOpenKey)) {
      setOpenKeys(openKeys.filter((key) => key !== latestOpenKey));
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Flex align="center" justify="center">
        <div className="logo">
          {/* <img src={logo} width={160} alt="" /> */}
        </div>
      </Flex>
      <Menu
        mode="inline"
        className="menu-bar"
        selectedKeys={[selectedKey]}
        openKeys={openKeys}
        // style={{ width: 300 }}
        onClick={(items) => {
          setSelectedKey(items.key);
          navigate(items.key);
        }}
        onOpenChange={handleOpenChange}
        items={generateMenuItems(data)}
      />
    </>
  );
};
