import React from "react";
import { Button, Modal } from "antd";
import OrderTable from "./components/OrderTable";

import { injectReducer } from "../../store";
import Orderreducer from "./store";

import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedDistributor } from "./store/stateSlice";

injectReducer("order", Orderreducer);

const Order = () => {
  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Order</div>
          <div className="flex">
            {/* <div className="flex items-center">
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Distributor</p>
              </Button>
            </div> */}
          </div>
          {/* <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {selectedDistributor
                  ? "Edit Distributor"
                  : "Add New Distributor"}
              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <DistributorForm />
          </Modal> */}
        </div>
        <OrderTable />
      </div>
    </>
  );
};

export default Order;
