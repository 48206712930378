import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Table, Tag, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, putOrder } from "../store/dataSlice";
import { notification } from "antd";

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { record } = location.state;
  const orderDetails = useSelector((state) => state.order.data.details.data);

  useEffect(() => {
    if (record?.ID) {
      dispatch(getOrderDetails({ ORDER_NO: record.ID }));
    }
  }, [dispatch, record]);

  if (!record) {
    navigate("/order");
  }

  const handleConfirmOrder = async () => {
    const action = await dispatch(
      putOrder({
        ID: record.ID,
        ORDER_STATUS: "A",
        CURRENT_STAGE: "AH",
      })
    );

    //console.log(action);
    if (action.payload.code === 200) {
      api.success({
        message: "Updated Successfully",
      });
      navigate("/order");
    }
  };
  const handleCancelOrder = () => {
    const action = dispatch(
      putOrder({
        ID: record.ID,
        ORDER_STATUS: "R",
      })
    );

    if (action.payload.code === 200) {
      api.success({
        message: "Updated Successfully",
      });
      navigate("/order");
    }
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "ITEM_NAME",
      width: 320,
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Category",
      dataIndex: "CATEGORY_NAME",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Unit",
      dataIndex: "UNIT_NAME",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "QTY",
      render: (text) => (
        <span className="text-gray-500 font-semibold">{text}</span>
      ),
    },
    {
      title: "Price",
      dataIndex: "AMOUNT",
      render: (text) => (
        <span className="text-gray-500 font-semibold">₹{text}</span>
      ),
    },
  ];

  const subTotal =
    orderDetails?.reduce((acc, item) => acc + parseFloat(item.AMOUNT), 0) || 0;
  const grandTotal = subTotal;

  const statusMap = {
    R: { text: "Rejected", color: "#ff6b6b", backgroundColor: "#fdfbee" },
    C: { text: "Completed", color: "#82d9b3", backgroundColor: "#dafbf1" },
    P: { text: "Pending", color: "#f3dc93", backgroundColor: "#fdfbee" },
    A: { text: "Approved", color: "#2196f3", backgroundColor: "#bae7ff" },
  };
  const status = statusMap[record.ORDER_STATUS];

  //console.log(status.text);

  return (
    <div className="m-4 p-10 bg-white rounded-lg shadow">
      {contextHolder}
      <div className="flex justify-between mb-4">
        <div>
          <div className="flex items-center">
            <h2 className="text-xl font-semibold text-zinc-700 mb-1">
              {record.DISTRIBUTOR_NAME}
            </h2>
            <p className="text-zinc-600 ml-2">
              ( {record.ADDRESS} &lt; {record.MOBILE_NO} )
            </p>
          </div>
          <p className="text-zinc-500 mb-1">Order Id: {record.ORDER_NO}</p>
          <p className="text-zinc-500 mb-1">
            Order Date: {record.ORDER_DATE.slice(0, 10)}
          </p>
          <p className="text-zinc-500 mb-1">
            Order Time: {record.ORDER_DATE.slice(11, 19)}
          </p>
        </div>
        <div>
          <Tag
            style={{
              color: status.color,
              backgroundColor: status.backgroundColor,
              padding: "5px 10px",
              borderRadius: "4px",
              fontWeight: "bold",
              border: "none",
            }}
          >
            {status.text}
          </Tag>
        </div>
      </div>
      <Table
        dataSource={orderDetails}
        columns={columns}
        pagination={false}
        bordered
      />
      <div className="flex justify-between items-start mt-6">
        <div className="p-4 border rounded-lg w-1/2">
          <h3 className="text-lg font-semibold text-zinc-600 mb-4">
            Payment Summary
          </h3>
          <div className="text-zinc-500 ">
            <p className="flex justify-between mb-2">
              <span className="text-md">Sub Total</span>
              <span>₹ {subTotal || 0}</span>
            </p>
            <p className="flex justify-between mb-2">
              <span>SGST(19%)</span>
              <span>₹ {orderDetails?.sgst || 0}</span>
            </p>
            <p className="flex justify-between mb-2">
              <span>CGST(19%)</span>
              <span>₹ {orderDetails?.cgst || 0}</span>
            </p>
            <hr />
            <p className="flex justify-between font-semibold mb-2 text-base">
              <span>Grand total</span>
              <span>₹ {grandTotal || 0}</span>
            </p>
          </div>
        </div>
        <div className="flex space-x-4">
          {status.text === "Approve" || status.text === "Rejected" ? (
            <>
              {JSON.stringify(status.text)}
              <Button
                className="border border-blue-500 text-blue-500"
                onClick={handleCancelOrder}
              >
                Reject Order
              </Button>
              <Button type="primary" onClick={handleConfirmOrder}>
                Confirm Order
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
