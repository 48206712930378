import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaRegCalendarCheck } from "react-icons/fa";
import {
  Form,
  Input,
  Dropdown,
  Upload,
  Row,
  Col,
  Button,
  Switch,
  Select,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";

import { DatePicker } from "antd";
import { toggleNewDate, setSelectedDate } from "../store/stateSlice";
import { getHoliday, putHoliday, postHoliday } from "../store/dataSlice";
import moment from "moment";
import dayjs from "dayjs";

const HolidayForm = () => {
  const [edit, setEdit] = useState(false);

  //Make useffect depending upon user has selected date to edit, setedit will be set

  const selectedholiday = useSelector(
    (state) => state.holiday.state.selectedHoliday
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedholiday) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [selectedholiday]);

  const DateValidationSchema = Yup.object({
    DATE: Yup.string().required("Required"),
    NAME: Yup.string().required("Required"),
    //DESCRIPTION IS NOT MANDATORY
    STATUS: Yup.boolean().required("Required"),
  });

  const DateInitialValues = selectedholiday || {
    NAME: "",
    DATE: "",
    DESCRIPTION: "",
    STATUS: true,
  };

  const handleCancel = () => {
    dispatch(toggleNewDate(false));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const action = edit
        ? await dispatch(putHoliday(values))
        : await dispatch(postHoliday(values));

      if (action.payload.code < 300) {
        dispatch(toggleNewDate(false));
        dispatch(getHoliday());
        //console.log("Form submitted successfully!");
      } else {
        console.error(
          "Error occurred during form submission:",
          action.payload.error
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt-5">
        {/* <h2 className="mb-2">Add Distributor</h2> */}
        <Formik
          initialValues={DateInitialValues}
          validationSchema={DateValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            isSubmitting,
            setFieldValue,
          }) => (
            <FormikForm>
              <Row gutter={10}>
                <Form.Item
                  className="flex flex-col w-full mb-4"
                  help={touched.DATE && errors.DATE ? errors.DATE : ""}
                  validateStatus={
                    touched.DATE && errors.DATE ? "error" : undefined
                  }
                >
                  <span className="text-xs">Date</span>
                  <Field name="DATE">
                    {({ field, form }) => (
                      <DatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date, dateString) => {
                          const Datestring =
                            dayjs(dateString).format("DD/MM/YYYY");
                          form.setFieldValue("DATE", dateString);
                        }}
                        onBlur={form.handleBlur}
                        className="!rounded"
                        style={{ width: "100%", padding: "8px" }}
                      />
                    )}
                  </Field>
                </Form.Item>
              </Row>
              <Row gutter={10}>
                <Form.Item
                  className="flex flex-col w-full mb-4"
                  help={touched.NAME && errors.NAME ? errors.NAME : ""}
                  validateStatus={
                    touched.NAME && errors.NAME ? "error" : undefined
                  }
                >
                  <span className="text-xs">Name</span>
                  <Input
                    name="NAME"
                    placeholder="NAME"
                    value={values.NAME}
                    onChange={handleChange}
                    className="!rounded"
                    style={{ padding: "8px" }}
                  />
                </Form.Item>
              </Row>
              <Row gutter={10}>
                <Form.Item
                  className="flex flex-col w-full"
                  help={
                    touched.DESCRIPTION && errors.DESCRIPTION
                      ? errors.DESCRIPTION
                      : ""
                  }
                  validateStatus={
                    touched.DESCRIPTION && errors.DESCRIPTION
                      ? "error"
                      : undefined
                  }
                >
                  <span className="text-xs">
                    Description{" "}
                    <span className="text-xs text-blue-700">*optional</span>
                  </span>
                  <TextArea
                    name="DESCRIPTION"
                    placeholder="DESCRIPTION"
                    value={values.DESCRIPTION}
                    onChange={handleChange}
                    className="!rounded"
                    style={{ padding: "8px" }}
                    maxLength={150}
                    rows={4}
                  />
                </Form.Item>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    className="flex flex-col mb-4"
                    help={touched.STATUS && errors.STATUS ? errors.STATUS : ""}
                    validateStatus={
                      touched.STATUS && errors.STATUS ? "error" : undefined
                    }
                  >
                    <span className="text-xs">Status</span>

                    <div style={{ marginTop: 8 }}>
                      <Switch
                        defaultChecked
                        checked={values.STATUS}
                        onChange={(checked) => setFieldValue("STATUS", checked)}
                        style={{ width: 20 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item className="flex justify-end ">
                    <Button onClick={handleCancel} className="mr-4 mt-6">
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      {edit ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default HolidayForm;
