import React from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";

const TaskTable = () => {
  const dataSource = useSelector((state) => state.task.data.reportList?.data);
  //console.log(dataSource);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "EMPLOYEE_NAME",
      key: "EMPLOYEE_NAME",
    },

    {
      title: "Total Pending",
      dataIndex: "TOTAL_PENDING_TASK",
      key: "TOTAL_PENDING",
    },
    {
      title: "Total Completed",
      dataIndex: "TOTAL_COMPLETED_TASK",
      key: "TOTAL_COMPLETED",
    },
    {
      title: "Total Hold",
      dataIndex: "TOTAL_HOLD_TASK",
      key: "TOTAL_HOLD",
    },
    {
      title: "Total In Progress",
      dataIndex: "TOTAL_INPROGRESS_TASK",
      key: "TOTAL_INPROGRESS",
    },
  ];
  return (
    <>
      <Table dataSource={dataSource} columns={columns} className="mt-10" />;
    </>
  );
};

export default TaskTable;
