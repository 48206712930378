import ApiService from "./ApiService";

export async function apiGetAllattendence(data) {
    //console.log("params", data);
    return ApiService.fetchData({
        url: "/api/attendence/get",
        method: "post",
        data,
    });
}



export async function apiGetBranch(data) {
    //console.log("params", data);
    return ApiService.fetchData({
        url: "/api/branch/getBranchData",
        method: "post",
        data,
    });
}



export async function apiGetDepartment(data) {
    //console.log("params", data);
    return ApiService.fetchData({
        url: "/api/department/getDepartmentData",
        method: "post",
        data,
    });
}


export async function apiGetEmployee(data) {
    //console.log("params", data);
    return ApiService.fetchData({
        url: "/api/employee/getEmployeeData",
        method: "post",
        data,
    });
}





// export async function apipostPrice(data) {
//     return ApiService.fetchData({
//         url: "/api/priceList/addBulk",
//         method: "post",
//         data,
//     });
// }

// export async function apiputPrice(data) {
//     return ApiService.fetchData({
//         url: "/api/priceListDetails/update",
//         method: "put",
//         data,
//     });
// }


// export async function apiGetAllPriceTable(data) {
//     return ApiService.fetchData({
//         url: "/api/priceList/get",
//         method: "Post",
//         data,
//     });
// }


// export async function apiGetpriceListDetails(data) {
//     return ApiService.fetchData({
//         url: "/api/priceListDetails/get",
//         method: "Post",
//         data,
//     });
// }


