import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetAllHoliday, apipostHoliday, apiputHoliday } from "../../../services/HolidayService";

export const getHoliday = createAsyncThunk(
    "holiday/data/getHoliday",
    async (data) => {
        const response = await apiGetAllHoliday(data);
        return response.data;
    }
);

export const postHoliday = createAsyncThunk(
    "holiday/data/postHoliday",
    async (data) => {
        const response = await apipostHoliday(data);
        return response.data;
    }
);

export const putHoliday = createAsyncThunk(
    "holiday/data/putHoliday",
    async (data) => {
        const response = await apiputHoliday(data);
        return response.data;
    }
);


export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: "",
    sort: {
        order: "",
        key: "",
    },
};


const dataSlice = createSlice({
    name: "holiday/data",
    initialState: {
        holidayLoading: false,
        holidayList: [],
        tableData: initialTableData,
       
    },
    reducers: {
        setTableData: (state, action) => {
            state.tableData = { ...state.tableData, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getHoliday.fulfilled, (state, action) => {
                state.holidayLoading = false;
                state.holidayList = action.payload;
                state.tableData.total = action.payload.count;
            })
            .addCase(getHoliday.pending, (state, action) => {
                state.holidayLoading = true;
            })
            .addCase(getHoliday.rejected, (state, action) => {
                state.holidayLoading = false;
            })
          .addCase(postHoliday.fulfilled, (state, action) => { 
          
          })
          .addCase(putHoliday.fulfilled, (state, action) => {

          });
    },
});

export const { setTableData } = dataSlice.actions;

export default dataSlice.reducer;