import React from "react";
import { Modal } from "antd";

const DescriptionModal = ({ isVisible, record, onClose }) => {
  const getCompletionStatusStyle = (status) => {
    const isPending = status === "P";
    return {
      color: isPending ? "#f3dc93" : "#82d9b3",
      backgroundColor: isPending ? "#fdfbee" : "#dafbf1",
      padding: "5px",
      borderRadius: "4px",
      fontWeight: "bold",
    };
  };

  return (
    <Modal
      title="Task Details"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      className="custom-modal"
    >
      {record && (
        <div className="p-4">
          <p className="mb-2">
            <strong className="text-gray-600">Assigned to:</strong>{" "}
            <span className="text-gray-900">{record.EMPLOYEE_NAME}</span>
          </p>
          <p className="mb-2">
            <strong className="text-gray-600">Assigned by:</strong>{" "}
            <span className="text-gray-900">{record.ASSIGNED_BY_NAME}</span>
          </p>
          <p className="mb-2">
            <strong className="text-gray-600">Start Time:</strong>{" "}
            <span className="text-gray-900">{record.START_DATETIME}</span>
          </p>
          <p className="mb-2">
            <strong className="text-gray-600">End Time:</strong>{" "}
            <span className="text-gray-900">{record.END_DATETIME}</span>
          </p>
          <p className="mb-2">
            <strong className="text-gray-600">Completion Status:</strong>{" "}
            <span style={getCompletionStatusStyle(record.COMPLETION_STATUS)}>
              {record.COMPLETION_STATUS === "P" ? "Pending" : "Completed"}
            </span>
          </p>
          <p className="mb-2">
            <strong className="text-gray-600">Description:</strong>
          </p>
          <p className="bg-gray-100 p-3 rounded text-gray-900">
            {record.DESCRIPTION}
          </p>
        </div>
      )}
    </Modal>
  );
};

export default DescriptionModal;
