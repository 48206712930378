import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  Form,
  Input,
  Dropdown,
  Upload,
  Row,
  Col,
  Button,
  Switch,
  Select,
} from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import BaseService from "../../../services/BaseService";

import {
  postOrganisation,
  putOrganisation,
  getOrganisation,
  getStateId,
  putOrganisationimg,
} from "../store/dataSlice";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import {
  toggleNewDialog,
  setSelectedOrganisation,
  toggleImageBox,
  setSelectedOrganisationImage,
} from "../store/stateSlice";

import axios from "axios";

const OrganisationImage = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.organisation.state.newDialog);
  const imageDialogs = useSelector(
    (state) => state.organisation.state.imageDialog
  );
  const img = useSelector(
    (state) => state.organisation.state.selectedOrganisationImage
  );

  const [imageUrls, setImageUrls] = useState({});

  const fetchImage = async () => {
    const config = {
      headers: {
        apikey: "hjh4653dsiivy457468asdfe",
        token: localStorage.getItem("token"),
      },
      responseType: "blob",
    };

    try {
      const response = await axios.get(
        `${BaseService.defaults.baseURL}/static/orgImg/${img}`,
        config
      );

      const url = URL.createObjectURL(response.data);
      //console.log("image response = ", response);

      setImageUrls((prevUrls) => ({ ...prevUrls, [img]: url }));
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    fetchImage();
    // //console.log("render images .......................................")
  }, [img]);

  return (
    <>
      <div className="mt-5">
        {/* {JSON.stringify(img)} */}

        <center>
          <img
            src={imageUrls[img]}
            alt="Organisation Image Not Found.."
            style={{ width: "50%", height: "50%", objectFit: "cover" }}
          />
        </center>
      </div>
    </>
  );
};

export default OrganisationImage;
