import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";

import {
  Button,
  Table,
  Pagination,
  Switch,
  Modal,
  Select,
  DatePicker,
  Space,
  notification,
  Spin,
} from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getAttendance } from "../store/dataSlice";
import { setSelectedAttendance, toggleNewDialog } from "../store/stateSlice";
import { setTableData } from "../store/dataSlice";
import { FaImage, FaRegImage } from "react-icons/fa6";
import { setSelectedImage1, toggleImageBox } from "../store/stateSlice";
import { FaUser } from "react-icons/fa";
import AttendenceImage from "../Components/AttendenceImage";
import { getBranch, getEmployee, grtDepartment } from "../store/dataSlice";
import AttendanceFilter from "./AttendanceFilter";
const { RangePicker } = DatePicker;

const AttendanceTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state.attendance.data.loading);
  const [api, contextHolder] = notification.useNotification();

  const data = useSelector(
    (state) => state?.attendance?.data?.attendanceList?.data
  );
  console.log(data);

  const { pageIndex, pageSize, total } = useSelector(
    (state) => state.attendance.data.tableData
  );

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    dispatch(setTableData({ pageIndex: current, pageSize: pageSize }));
  };

  const convertTo12HourFormat = (time) => {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    const period = +hours < 12 ? "AM" : "PM";
    const hour = +hours % 12 || 12;
    return `${hour}:${minutes} ${period}`;
  };
  
  const columns = [
    {
      title: <span className="text-gray-500">Day In </span>,
      dataIndex: "action",
      fixed: "left",
      width: 80, // Adjusted to ensure the title fits
      render: (_, record) => (
        <div className="flex items-center ml-2">
          <span
            // onClick={() => onEditImage1(record.DAYIN_IMG_URL)}

            onClick={() => {
              if (record.DAYIN_IMG_URL == null) {
                api.info({
                  // message: "Error",
                  description: "Image Yet To Be Uploaded.",
                });
              } else {
                onEditImage1(record.DAYIN_IMG_URL);
              }
            }}
            className="text-2xl ml-1 text-red-500 cursor-pointer"
          >
            <FaRegImage />
          </span>
        </div>
      ),
    },

    {
      title: <span className="text-gray-500">Day Out </span>,
      dataIndex: "action",
      fixed: "left",
      width: 90, // Adjusted to ensure the title fits
      render: (_, record) => (
        <div className="flex items-center">
          <span
            onClick={() => {
              if (record.DAYOUT_IMG_URL == null) {
                api.info({
                  // message: "Error",
                  description: "Image Yet To Be Uploaded.",
                });
              } else {
                onEditImage2(record.DAYOUT_IMG_URL);
              }
            }}
            className="text-2xl ml-4 text-red-500 cursor-pointer"
          >
            <FaRegImage />
          </span>
        </div>
      ),
    },

    {
      title: <span className="text-gray-500">Employee name</span>,
      dataIndex: "EMP_NAME",
      width: 200,
      // fixed: "left"
    },

    {
      title: <span className="text-gray-500">Date</span>,
      dataIndex: "DATE",
      width: 150,
    },

    {
      title: <span className="text-gray-500">Day In Time</span>,
      dataIndex: "DAYIN_TIME",
      width: 150,
      render: (time) =>
        time ? (
          convertTo12HourFormat(time)
        ) : (
          <span className="text-red-500">{/* not yet defined */}</span>
        ),
    },
    {
      title: <span className="text-gray-500">Day Out Time</span>,
      dataIndex: "DAYOUT_TIME",
      width: 150,
      render: (time) =>
        time ? (
          convertTo12HourFormat(time)
        ) : (
          <span className="text-red-500">{/* To be defined later */}</span>
        ),
    },

    {
      title: <span className="text-gray-500">Branch Name</span>,
      dataIndex: "BRANCH_NAME",
      width: 150,
    },

    {
      title: <span className="text-gray-500">Department Name</span>,
      dataIndex: "DEPARTMENT_NAME",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Day In Distance</span>,
      dataIndex: "DAYIN_DISTANCE",
      width: 150,
      // render: (text) => text ? text : <span className="text-red-500">not yet defined</span>,
    },
    {
      title: <span className="text-gray-500">Day Out Distance</span>,
      dataIndex: "DAYOUT_DISTANCE",
      width: 150,
      // render: (text) => text ? text : <span className="text-red-500">To be defined later</span>,
    },
    {
      title: <span className="text-gray-500">Day In Remark</span>,
      dataIndex: "DAYIN_REMARK",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Day Out Remark</span>,
      dataIndex: "DAYOUT_REMARK",
      width: 150,
      // render: (text) => text ? text : <span className="text-red-500">To be defined later</span>,
    },

    {
      title: <span className="text-gray-500">Day IN Location</span>,
      dataIndex: "DAYIN_LOCATION",
      width: 150,
      render: (text) => {
        if (!text)
          return (
            <span className="text-red-500">{/* Location not found */}</span>
          );
        const [lat, lon] = text.split(/[NS]/).map((coord) => coord.trim());
        const url = `https://www.google.com/maps?q=${lat},${lon}`;
        return (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500"
          >
            Open Map
          </a>
        );
      },
    },
    {
      title: <span className="text-gray-500">Day Out Location</span>,
      dataIndex: "DAYOUT_LOCATION",
      width: 150,
      render: (text) => {
        if (!text)
          return (
            <span className="text-red-500">{/* To be defined later */}</span>
          );
        const [lat, lon] = text.split(/[NS]/).map((coord) => coord.trim());
        const url = `https://www.google.com/maps?q=${lat},${lon}`;
        return (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500"
          >
            Open Map
          </a>
        );
      },
    },
    {
      title: <span className="text-gray-500">Day In Device Id</span>,
      dataIndex: "DAYIN_DEVICE_ID",
      width: 150,
    },
    {
      title: <span className="text-gray-500">Status</span>,
      dataIndex: "ATTENDENCE_STATUS",
      width: 130,
      render: (text) =>
        text === "S" ? (
          <span className="text-green-900 bg-emerald-100 p-2 rounded-md font-bold">Started</span>
        ) : text === "E" ? (
          <span className="text-blue-900 bg-blue-100 p-2 rounded-md font-bold">Ended</span>
        ) : (
          <span className="text-yellow-900 bg-yellow-100 p-2 rounded-md font-bold">Not yet defined</span>
        ),  
    }

  ];

  const imageDialogs = useSelector(
    (state) => state.attendance.state.imageDialog
  );

  const onEditImage1 = (record) => {
    dispatch(
      setSelectedImage1({
        image: record,
        count: 1,
      })
    );
    dispatch(toggleImageBox(true));
  };

  const onEditImage2 = (record) => {
    dispatch(
      setSelectedImage1({
        image: record,
        count: 2,
      })
    );
    dispatch(toggleImageBox(true));
  };

  const handleCloseModalImage = () => {
    dispatch(setSelectedImage1(""));
    dispatch(toggleImageBox(false)); // Close the modal
  };


  useEffect(() => {
    dispatch(setTableData({ pageIndex: 1, pageSize }));
    dispatch(toggleNewDialog(false));
  }, [location.pathname]);

  return (
    <>
      {contextHolder}

      <AttendanceFilter />
      {loading ? (
        <div className="w-full flex justify-center h-60 items-center">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{
            x: 1300,
          }}
          pagination={{
            current: pageIndex,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            onChange: handleTableChange,
            onShowSizeChange: handleTableChange,
          }}
          onChange={handleTableChange}
        />
      )}

      <Modal
        title={
          <span
            style={{
              color: "#096CAE",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaUser className="mr-2" />
            Organisation Image
          </span>
        }
        open={imageDialogs}
        footer={null}
        style={{ top: "3%" }}
        // width={700}
        onCancel={handleCloseModalImage}
      >
        <AttendenceImage />
      </Modal>
    </>
  );
};

export default AttendanceTable;
