import ApiService from "./ApiService";

export async function apiGetAllEom(data) {
  console.log("params", data);
  return ApiService.fetchData({
    url: "/api/eom/get",
    method: "post",
    data,
  });
}
export async function apipostEom(data) {
  return ApiService.fetchData({
    url: "/api/eom/create",
    method: "post",
    data,
  });
}

export async function apiputEom(data) {
  return ApiService.fetchData({
    url: "/api/eom/update",
    method: "put",
    data,
  });
}
export async function apiGetAllEmployeeId(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}
// export async function apiGetAllEmployee(data) {
//   return ApiService.fetchData({
//     url: "/api/employee/get",
//     method: "post",
//     data,
//   });
// }
