import React, { useEffect } from "react";
import { FaFilter } from "react-icons/fa";

import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import EmployeeTargetForm from "./Components/EmployeeTargetForm";
import EmployeeTargetTable from "./Components/EmployeeTargetTable";
import { injectReducer } from "../../store";
import EmployeeTargetreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedEmployeeTarget, toggleFilter } from "./store/stateSlice";
import { getEmployeeTarget } from "./store/dataSlice";
import EmployeeTargetSearch from "./Components/EmployeeTargetSearch";

injectReducer("employeetarget", EmployeeTargetreducer);


const Employeetarget = () => {
  const dialog = useSelector((state) => state.employeetarget.state.newDialog);

  const AddEdit = useSelector((state) => state.employeetarget.state.selectedEmployeeTarget);
  const aaa = String(dialog);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setSelectedEmployeeTarget(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); // Close the modal
  };


  // const handleRefresh = () => {
  //   dispatch(getDesignation());
  // };


  // useEffect(() => {
  //   handleRefresh();
  // }, [dispatch]);




  const dialog2 = useSelector((state) => state.employeetarget.state.newDialogFilter);

  const handleFilterClick = () => {
    if (dialog2) {
      dispatch(toggleFilter(false));

    }
    else {
      dispatch(toggleFilter(true));

    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Employee Target Detail</div>
          <div className="flex">
            <div className="flex items-center">
              {/* <EmployeeTargetSearch /> */}

              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>



              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Employee Target</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Employee Target" : "Add New Employee Target"}

              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            onCancel={handleCloseModal}
          >
            <EmployeeTargetForm />
          </Modal>
        </div>


        <EmployeeTargetTable />


      </div>
    </>
  );
};

export default Employeetarget;
