import ApiService from "./ApiService";

export async function apiGetAllTask(data) {
  return ApiService.fetchData({
    url: "/api/task/get",
    method: "post",
    data,
  });
}

export async function apiGetEmployee(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}
