import React, { useEffect, useState } from "react";
import Taskreducer from "./store";
import { injectReducer } from "../../store";
import TaskTable from "./components/TaskTable";
import TaskCard from "./components/TaskCard";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeTaskReport } from "./store/dataSlice";
import { FaFilter } from "react-icons/fa";
import { Button, Select, DatePicker } from "antd";
injectReducer("task", Taskreducer);
const Task = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployeeTaskReport());
  }, []);
  // const employeeTaskReport = useSelector(
  //   (state) => state.task.data.reportList.data
  // );
  const [filters, setFilters] = useState({
    EMP_ID: null,
    START_DATE: null,
    END_DATE: null,
  });
  const [showFilter, setShowFilter] = useState(false);
  const filterOption = (input, option) => {
    const optionText = option.children;
    return (typeof optionText === "string" ? optionText : optionText.join(""))
      .toLowerCase()
      .includes(input.toLowerCase());
  };
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const handleChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleSubmit = (filters) => {
    dispatch(getEmployeeTaskReport(filters));
    // setShowFilter(false);
  };
  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <TaskCard />
        <div className="flex justify-between mb-6 mt-10">
          <div className="text-xl font-bold !text-[#414141]">
            Employee Task Report
          </div>
          <div className="flex">
            <div className="flex items-center">
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
            </div>
          </div>
        </div>
        <div className="filter mb-4">
          {showFilter && (
            <div className="p-4 bg-white rounded border border-dashed">
              <div className="grid grid-cols-4 gap-4 mb-4">
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    EMPLOYEE NAME
                  </label>
                  <div className="">
                    <Select
                      showSearch
                      filterOption={filterOption}
                      mode="multiple"
                      className="w-full h-11 "
                      placeholder="Select Employee"
                      onChange={(value) => handleChange("EMP_ID", value)}
                    >
                      {/* {employeeTaskReport.map((type) => (
                        <Select.Option key={type.ID} value={type.ID}>
                          {type.EMPLOYEE_NAME}
                        </Select.Option>
                      ))} */}
                    </Select>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    From Date
                  </label>
                  <div className="">
                    <DatePicker
                      className="w-full  h-11"
                      style={{ padding: "0.5rem" }}
                      onChange={(date, dateString) =>
                        handleChange("START_DATE", dateString)
                      }
                    />
                  </div>
                </div>
                <div className="col-span-4 md:col-span-1">
                  <label className="block mb-1 font-semibold text-gray-500">
                    To Date
                  </label>
                  <div>
                    <DatePicker
                      className="w-full h-11"
                      style={{ padding: "0.5rem" }}
                      onChange={(date, dateString) =>
                        handleChange("END_DATE", dateString)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-end justify-between mb-4 mt-6">
                <div className="flex">
                  <Button
                    type="default"
                    className="mr-4 py-4 px-6 border border-blue-500"
                    onClick={() => {
                      setFilters({
                        EMP_ID: null,
                        START_DATE: null,
                        END_DATE: null,
                      });
                      setShowFilter(false);
                      // dispatch(getTask());
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="primary"
                    className="py-4 px-6"
                    onClick={() => {
                      handleSubmit(filters);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <TaskTable />
      </div>
    </>
  );
};

export default Task;
