import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "absentReportList/state",
  initialState: {
    deleteConfirmation: false,
    selectedAbsentReport: "",
    newDialog: false,
    tableData: {
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    },
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      state.deleteConfirmation = action.payload;
    },
    setSelectedAbsentReport: (state, action) => {
      state.selectedAbsentReport = action.payload;
    },
    toggleNewDialog: (state, action) => {
      state.newDialog = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
});

export const {
  toggleDeleteConfirmation,
  setSelectedAbsentReport,
  toggleNewDialog,
  setTableData,
} = stateSlice.actions;

export default stateSlice.reducer;
