import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  apiGetAllItem,
  apipostItem,
  apiputItem,
  apigetStateId,
  apiGetUnit,
  apiGetCategory,
} from "../../../services/ItemService";


export const getUnit = createAsyncThunk(
  "item/data/getUnit",
  async (data) => {
    const response = await apiGetUnit(data);
    return response.data;
  }
);
export const getCategory = createAsyncThunk(
  "item/data/getCategory",
  async (data) => {
    const response = await apiGetCategory(data);
    return response.data;
  }
);

export const getItem = createAsyncThunk(
  "item/data/getItem",
  async (data) => {
    const response = await apiGetAllItem(data);
    return response.data;
  }
);
export const postItem = createAsyncThunk(
  "item/data/postItem",
  async (data) => {
    const response = await apipostItem(data);
    return response.data;
  }
);
export const putItem = createAsyncThunk(
  "item/data/putItem",
  async (data) => {
    const response = await apiputItem(data);
    //console.log(response);
    return response.data;
  }
);


export const getStateId = createAsyncThunk(
  "item/data/getStateId",
  async () => {
    const response = await apigetStateId();
    // //console.log(response);
    return response.data;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "itemList/data",
  initialState: {
    loading: false,
    itemList: [],
    stateList: [],
    unitList: [],
    categoryList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.itemList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };

      // //console.log("action.payload", action.payload);

    },

    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItem.fulfilled, (state, action) => {
        state.loading = false;
        state.itemList = action.payload;
        state.tableData.total = action.payload.count;

      })
      .addCase(getItem.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getItem.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getStateId.fulfilled, (state, action) => {
        state.stateList = action.payload;
      })
      .addCase(getUnit.fulfilled, (state, action) => {
        state.unitList = action.payload;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categoryList = action.payload;
      })
      .addCase(postItem.fulfilled, (state, action) => { })
      .addCase(putItem.fulfilled, (state, action) => { });
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
