import { combineReducers } from "@reduxjs/toolkit";
import state from "./stateSlice";
import data from "./dataSlice";

const EmployeeTargetreducer = combineReducers({
  state,
  data,
});

export default EmployeeTargetreducer;
