import React, { useEffect, useState } from "react";
import AbsentReportreducer from "./store";
import { injectReducer } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import {
  getAbsentReport,
  getBranchId,
  getDepartmentId,
  getEmployeeId,
} from "./store/dataSlice";

import { FaFilter } from "react-icons/fa";
import { Button, Select } from "antd";
import AbsentReportTable from "./Components/AbsentReportTable";
import { toggleNewDialog } from "./store/stateSlice";
injectReducer("absentReport", AbsentReportreducer);

const AbsentReport = () => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getLocation());
  //   dispatch(getBranchId());
  //   dispatch(getEmployeeId());
  //   dispatch(getDepartmentId());
  // }, []);

  const dialog = useSelector((state) => state.absentReport?.state.newDialog);

  const handleFilterClick = () => {
    if (dialog) {
      dispatch(toggleNewDialog(false));
    } else {
      dispatch(toggleNewDialog(true));
    }
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6 mt-10">
          <div className="text-xl font-bold !text-[#414141]">
            Employee Absent Report
          </div>
          <div className="flex">
            <div className="flex items-center">
              <div
                className="bg-[#096CAE] p-[11.5px] rounded mr-4 text-white cursor-pointer"
                onClick={handleFilterClick}
              >
                <FaFilter />
              </div>
            </div>
          </div>
        </div>

        <AbsentReportTable />
      </div>
    </>
  );
};

export default AbsentReport;
