import React, { useEffect, useState } from "react";

import { Button, Modal, Select, DatePicker, Radio, Space } from "antd";
import { LuPlus } from "react-icons/lu";
import { MdOutlineRealEstateAgent } from "react-icons/md";

import StateTable from "./components/StateTable";
import StateForm from "./components/StateForm";

import { injectReducer } from "../../store";
import Statereducer from "./store";

import { useSelector, useDispatch } from "react-redux";

import { toggleNewDialog, setSelectedDistributor } from "./store/stateSlice";

injectReducer("state", Statereducer);

const State = () => {
  const dispatch = useDispatch();

  const dialog = useSelector((state) => state.state.state.newDialog);

  const selectedDistributor = useSelector(
    (state) => state.state.state.selectedDistributor
  );

  const onDialog = () => {
    dispatch(setSelectedDistributor(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false));
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">State</div>
          <div className="flex">
            <div className="flex items-center">
              {/* <DistributorSearch /> */}
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add State</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <MdOutlineRealEstateAgent className="mr-2" />
                {selectedDistributor ? "Edit State" : "Add New State"}
              </span>
            }
            open={dialog}
            footer={null}
            onCancel={handleCloseModal}
          >
            <StateForm />
          </Modal>
        </div>
        <StateTable />
      </div>
    </>
  );
};

export default State;
