import React from "react";
import { Button, Modal } from "antd";
import HolidayForm from "./Components/HolidayForm";
import HolidayTable from "./Components/HolidayTable";
import { setSelectedHoliday, toggleNewDate } from "./store/stateSlice";
import { FaRegCalendarCheck } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import { useSelector, useDispatch } from "react-redux";
import { injectReducer } from "../../store";
import Holidayreducer from "./store";
injectReducer("holiday", Holidayreducer);

const Holiday = () => {
    const datedialog = useSelector((state) => state.holiday.state.DateDialog);
    const AddEdit = useSelector((state) => state.holiday.state.selectedHoliday);

    const dispatch = useDispatch();

    const onNewDate = () => {
        dispatch(toggleNewDate(true))
        dispatch(setSelectedHoliday(null));
    }
    const handleCloseModal = () => {
        dispatch(toggleNewDate(false)); // Close the modal
    };
    return (<>
        <div className="bg-white m-4 p-8 rounded-xl">
            <div className="flex justify-between mb-6">

                <div className="text-xl font-bold !text-[#414141] ">Holiday Detail</div>

                <div className="flex">
                    <div className="flex items-center">
                        {/* <OrganisationSearch /> */}
                        <Button
                            style={{
                                backgroundColor: "#096CAE",
                                color: "#ffff",
                                display: "flex",
                                padding: "18px",
                                borderRadius: "6px",
                            }}
                            onClick={onNewDate}
                        >
                            <LuPlus />
                            {/* {JSON.stringify(imageDialogs)} */}
                            <p>Add Holiday</p>

                        </Button>
                    </div>
                </div>
                <Modal
                    title={
                        <span
                            style={{
                                color: "#096CAE",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {/* <FaUser className="mr-2" /> */}
                            {AddEdit ? "Edit Date" : "Add New date"}
                        </span>
                    }
                    open={datedialog}
                    footer={null}
                    style={{ top: "3%"}}
                    width={400}
                    onCancel={handleCloseModal}
                >
                    <HolidayForm />
                </Modal>
            </div>
            <HolidayTable />
        </div>
    </>)
}

export default Holiday