import ApiService from "./ApiService";

export async function apiGetAllNotification(data) {
  console.log("params", data);
  return ApiService.fetchData({
    url: "/api/notification/get",
    method: "post",
    data,
  });
}
export async function apipostNotification(data) {
  return ApiService.fetchData({
    url: "/api/notification/create",
    method: "post",
    data,
  });
}

export async function apiputNotification(data) {
  return ApiService.fetchData({
    url: "/api/notification/update",
    method: "put",
    data,
  });
}
export async function apiGetAllEmployeeId(data) {
  return ApiService.fetchData({
    url: "/api/employee/getEmployeeData",
    method: "post",
    data,
  });
}

