import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { FaUser, FaSearch, FaFilter } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";

import DepartmentTable from "./components/DepartmentTable";
import DepartmentForm from "./components/DepartmentForm";

import { injectReducer } from "../../store";

import Departmentreducer from "./store";

import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setSelectedDistributor } from "./store/stateSlice";

injectReducer("department", Departmentreducer);

const Department = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.department.state.newDialog);
  const selectedDistributor = useSelector(
    (state) => state.department.state.selectedDistributor
  );

  const onDialog = () => {
    dispatch(setSelectedDistributor(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false));
  };

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Department</div>
          <div className="flex">
            <div className="flex items-center">
              {/* <DistributorSearch /> */}
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Department</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FaUser className="mr-2" />
                {selectedDistributor ? "Edit Department" : "Add New Department"}
              </span>
            }
            open={dialog}
            footer={null}
            onCancel={handleCloseModal}
          >
            <DepartmentForm />
          </Modal>
        </div>
        <DepartmentTable />
      </div>
    </>
  );
};
export default Department;
