import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetAllItemCategory,apiPostItemCategory,apiPutItemCategory } from "../../../services/ItemCategoryService";

export const getItemCategory = createAsyncThunk(
  "itemCategory/data/getItemCategory",
  async (data) => {
    const response = await apiGetAllItemCategory(data);
    return response.data;
  }
);
export const postItemCategory = createAsyncThunk(
  "itemCategory/data/postItemCategory",
  async (data) => {
    const response = await apiPostItemCategory(data);
    return response;
  }
);
export const putItemCategory = createAsyncThunk(
  "itemCategory/data/putItemCategory",
  async (data) => {
    const response = await apiPutItemCategory(data);
    return response;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "itemCategoryList/data",
  initialState: {
    loading: false,
    itemCategoryList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.branchList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItemCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.itemCategoryList = action.payload;
      })
      .addCase(getItemCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getItemCategory.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(postItemCategory.fulfilled, (state, action) => {})
      .addCase(putItemCategory.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
