import ApiService from "./ApiService";

export async function apiGetEmployeetarget(data) {
    //console.log("params", data);
    return ApiService.fetchData({
        url: "/api/reports/getEmployeeTargetReport",
        method: "post",
        data,
    });
}
export async function apiGetAllEmployeeId(data) {
    return ApiService.fetchData({
      url: "/api/employee/getEmployeeData",
      method: "post",
      data,
    });
  }
  
  export async function apiGetAllBranchId(data) {
    return ApiService.fetchData({
      url: "/api/branch/getBranchData",
      method: "post",
      data,
    });
  }
  
  
  
  export async function apiGetAllDepartmentId(data) {
    return ApiService.fetchData({
      url: "/api/department/getDepartmentData",
      method: "post",
      data,
    });
  }
  

