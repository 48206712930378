import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetAllState,
  apiPostState,
  apiPutState,
} from "../../../services/StateService";

export const getState = createAsyncThunk("state/data/get", async (data) => {
  const response = await apiGetAllState(data);
  return response.data;
});

export const postState = createAsyncThunk("state/data/post", async (data) => {
  const response = await apiPostState(data);
  return response.data;
});

export const putState = createAsyncThunk("state/data/put", async (data) => {
  const response = await apiPutState(data);
  return response.data;
});

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const dataSlice = createSlice({
  name: "stateList/data",
  initialState: {
    loading: false,
    stateList: [],
    tableData: initialTableData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.stateList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getState.fulfilled, (state, action) => {
        state.loading = false;
        state.stateList = action.payload;
        state.tableData.total = action.payload.count;
      })
      .addCase(getState.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getState.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(postState.fulfilled, (state, action) => {})
      .addCase(putState.fulfilled, (state, action) => {});
  },
});

export const { updateProductList, setTableData } = dataSlice.actions;

export default dataSlice.reducer;
