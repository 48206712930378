import ApiService from "./ApiService";

export async function apiGetAllDashboardCount(data) {
  return ApiService.fetchData({
    url: "/api/reports/getDashboardSummaryReport",
    method: "post",
    data,
  });
}

export async function apiGetEmployeeSummaryReport(data) {
  return ApiService.fetchData({
    url: "/api/reports/getEmployeeSummaryReport",
    method: "post",
    data,
  });
}


export async function apiGetAttendanceDateWiseReport(data) {
  return ApiService.fetchData({
    url: "/api/reports/getAttendanceDatewiseReport",
    method: "post",
    data,
  });
}


export async function apiGetLeaves(data) {
  return ApiService.fetchData({
    url: "/api/reports/getLeaveDashboardReport",
    method: "post",
    data,
  });
}