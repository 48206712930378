import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGetAllRole,apiputRole,apipostRole,apiRoleDetails,apiSendRole } from "../../../services/RoleService";

export const getRole = createAsyncThunk(
  "role/data/getRole",
  async (data) => {
    const response = await apiGetAllRole(data);
    return response.data;
  }
);
export const postRole = createAsyncThunk(
  "role/data/postRole",
  async (data) => {
    const response = await apipostRole(data);
    return response;
  }
);
export const putRole = createAsyncThunk(
  "role/data/putRole",
  async (data) => {
    const response = await apiputRole(data);
    return response;
  }
);
export const getRoleDetails = createAsyncThunk(
  "role/data/getRoleDetails",
  async (data) => {
    const response = await apiRoleDetails(data);
    return response.data;
  }
);
export const getSendRole = createAsyncThunk(
  "role/data/apiSendRole",
  async (data) => {
    const response = await apiSendRole(data);
    return response;
  }
);

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export const initialFilterData = {
  name: "",
  category: ["bags", "cloths", "devices", "shoes", "watches"],
  status: [0, 1, 2],
  productStatus: 0,
};

const dataSlice = createSlice({
  name: "RoleList/data",
  initialState: {
    loading: false,
    roleList: [],
    roleAssignList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
  },
  reducers: {
    updateProductList: (state, action) => {
      state.branchList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRole.fulfilled, (state, action) => {
        state.loading = false;
        state.roleList = action.payload;
      })
      .addCase(getRole.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRole.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(postRole.fulfilled, (state, action) => {})
      .addCase(putRole.fulfilled, (state, action) => {})
      .addCase(getSendRole.fulfilled, (state, action) => {})
      .addCase(getRoleDetails.fulfilled, (state, action) => {
        state.roleAssignList = action.payload;
      })
  },
});

export const { updateProductList, setTableData, setFilterData } =
  dataSlice.actions;

export default dataSlice.reducer;
