import ApiService from "./ApiService";

export async function apiGetAllHoliday(data) {

    return ApiService.fetchData({
        url: "/api/holiday/get",
        method: "post",
        data,
    });
}

export async function apipostHoliday(data) {
    return ApiService.fetchData({
        url: "/api/holiday/create",
        method: "post",
        data,
    });
}

export async function apiputHoliday(data) {
    return ApiService.fetchData({
        url: "/api/holiday/update",
        method: "put",
        data,
    });
}