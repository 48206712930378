import { combineReducers } from "@reduxjs/toolkit";
import state from "./stateSlice";
import data from "./dataSlice";

const Servicereducer = combineReducers({
  state,
  data,
});

export default Servicereducer;
