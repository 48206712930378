
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Card } from "antd";

import { Form, Input, Row, Col, Button, Switch, notification, } from "antd";
import { Formik, Field, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { Radio } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { toggleNewModel, toggleNewModelSubmit, setSelectedModel } from "../store/stateSlice"
import { postApproved, postRejected } from "../store/dataSlice"
const { TextArea } = Input;

const LeaveModel = () => {


    //    --------------------- ROLE ID SART ----------------------------

    let ROLE_ID = localStorage.getItem("ROLE_ID");
    const userData = localStorage.getItem("UserData");
    const roleDetails = JSON.parse(userData);
    const EmpId = roleDetails[0]?.EMP_ID;

    const filterData1 = JSON.parse(localStorage.getItem("filterData"));
    let BRANCH_ID;
    let DEPARTMENT_ID;
    if (ROLE_ID == 10) {
        BRANCH_ID = filterData1[0]?.BRANCH_ID.split(",")?.map(Number);
        DEPARTMENT_ID = filterData1[0]?.DEPARTMENT_ID.split(",")?.map(Number);
    }

    const menuList = useSelector((state) => state?.menu?.data?.menuList?.data); // this line is important dont remove it




    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();

    const LeaveModel = useSelector(
        (state) => state.leave.state?.selectedModel
    );
    const validationSchema = Yup.object({
        REMARK: Yup.string().required("Required"),
        STATUS: Yup.string().required("Required"),

        // status: Yup.boolean().required("Required"),
    });






    const initialValues = {

        REMARK: "",
        STATUS: "",

    };

    const dialog2 = useSelector((state) => state.leave.state.newSubmitModel);

    //console.log(employeeTaskReport);


    const handleSubmit = async (values, { setSubmitting }) => {

        try {
            let action;

            const updatedValue = {

                APPROVED_BY: EmpId,
                LEAVE_ID: LeaveModel.ID,
                REMARK: values.REMARK,
            };

            if (values.STATUS == "A") {

                action = await dispatch(postApproved(updatedValue))

            }

            if (values.STATUS == "R") {

                action = await dispatch(postRejected(updatedValue))

            }

            if (action.payload.code == 200) {


                if (dialog2) {
                    dispatch(toggleNewModelSubmit(false));
                    dispatch(toggleNewModel(false));


                }
                else {
                    dispatch(toggleNewModelSubmit(true));
                    dispatch(toggleNewModel(false));


                }


                api.success({
                    message: "Form Submitted Successfully.",
                });



            } else {
                console.error(
                    "Error occurred during form submission:",
                    action.payload.error
                );
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        } finally {
            setSubmitting(false);
        }
    };





    const textContainerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState('auto');

    useEffect(() => {
        const adjustHeight = () => {
            if (textContainerRef.current) {
                const scrollHeight = textContainerRef.current.scrollHeight;
                const expectedHeight = 128; // Example expected height in pixels (32 * 4)
                if (scrollHeight > expectedHeight) {
                    setContainerHeight(`${scrollHeight}px`);
                } else {
                    setContainerHeight('auto');
                }
            }
        };

        adjustHeight();
    }, [LeaveModel]);


    return (
        <>
            <div className="shadow-lg rounded">
                <div className="flex h-auto">
                    <div className="p-4 rounded w-full" style={{ height: containerHeight }}>
                        <p className="font-semibold mb-2">Reason</p>
                        <div
                            className={`overflow-y-auto break-words ${containerHeight !== 'auto' ? 'max-h-32' : ''}`}
                            ref={textContainerRef}
                        >
                            {LeaveModel?.REASON || null}

                        </div>
                    </div>
                </div>



                <hr></hr>

                <div className="p-4 w-full  ">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ values, touched, errors, handleChange, setFieldValue, isSubmitting }) => (
                            <FormikForm>

                                <Row gutter={16}>



                                    <Col span={24}>
                                        <Form.Item
                                            className="flex flex-col"
                                            help={
                                                touched.REMARK && errors.REMARK
                                                    ? errors.REMARK
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.REMARK && errors.REMARK
                                                    ? "error"
                                                    : undefined
                                            }
                                        >
                                            <span lassName="font-semibold mb-2">Remark</span>

                                            <TextArea
                                                rows={2}

                                                placeholder="You Can Add Maximum 512 Characters.."
                                                maxLength={6000}
                                                value={values.DESCRIPTION}
                                                onChange={(e) => setFieldValue("REMARK", e.target.value)}
                                            />

                                        </Form.Item>
                                    </Col>

                                </Row>




                                <Row gutter={16}>


                                    <Col span={24}>
                                        <Form.Item
                                            className="flex flex-col"
                                            help={
                                                touched.STATUS && errors.STATUS
                                                    ? errors.STATUS
                                                    : ""
                                            }
                                            validateStatus={
                                                touched.STATUS && errors.STATUS
                                                    ? "error"
                                                    : undefined
                                            }
                                        >
                                            <Radio.Group
                                                onChange={(e) => setFieldValue("STATUS", e.target.value)}

                                            >
                                                <Radio value="A">Approve</Radio>
                                                <Radio value="R">Reject</Radio>

                                            </Radio.Group>

                                        </Form.Item>
                                    </Col>

                                </Row>






                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item className="flex justify-end">
                                            <Button
                                                // onClick={handleCancel}
                                                className="mr-4 py-4 px-6 border border-blue-500"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </ FormikForm>
                        )}
                    </Formik>
                </div  >
            </div>

        </>
    );
};

export default LeaveModel;
