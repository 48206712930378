import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import { FaUser } from "react-icons/fa";
import { LuPlus } from "react-icons/lu";
import AccountgroupForm from "./Components/AccountgroupForm";
import AccountgroupTable from "./Components/AccountgroupTable";
import { injectReducer } from "../../store";
import Accountgroupreducer from "./store";
import { useSelector, useDispatch } from "react-redux";
import { toggleNewDialog, setselectedAccountgroup } from "./store/stateSlice";
import { GetAllAccountgroup } from "./store/dataSlice";
import AccountgroupSearch from "./Components/AccountgroupSearch";

injectReducer("accountgroup", Accountgroupreducer);
const Accountgroup= () => {
  const dialog = useSelector((state) => state.accountgroup.state.newDialog);

  const AddEdit = useSelector((state) => state.accountgroup.state.selectedAccountgroup);
  const aaa = String(dialog);

  const dispatch = useDispatch();
  const onDialog = () => {
    dispatch(setselectedAccountgroup(null));
    dispatch(toggleNewDialog(true));
  };

  const handleCloseModal = () => {
    dispatch(toggleNewDialog(false)); 
  };


  const handleRefresh = () => {
    dispatch(GetAllAccountgroup());
  };


  useEffect(() => {
    handleRefresh();
  }, [dispatch]);

  return (
    <>
      <div className="bg-white m-4 p-8 rounded-xl">
        <div className="flex justify-between mb-6">
          <div className="text-xl font-bold !text-[#414141]">Account Group </div>
          <div className="flex">
            <div className="flex items-center">
              <AccountgroupSearch />
              <Button
                style={{
                  backgroundColor: "#096CAE",
                  color: "#ffff",
                  display: "flex",
                  padding: "18px",
                  borderRadius: "6px",
                }}
                onClick={onDialog}
              >
                <LuPlus />
                <p>Add Account Group</p>
              </Button>
            </div>
          </div>
          <Modal
            title={
              <span
                style={{
                  color: "#096CAE",
                  display: "flex",
                  alignItems: "center",
                }}
                
              >
                <FaUser className="mr-2" />
                {AddEdit ? "Edit Account Group" : "Add New Account Group"}

              </span>
            }
            open={dialog}
            footer={null}
            style={{ top: "3%" }}
            width={400}
            onCancel={handleCloseModal}
          >
            <AccountgroupForm />
          </Modal>
        </div>


        <AccountgroupTable handleRefresh={handleRefresh} />


      </div>
    </>
  );
};

export default Accountgroup;
