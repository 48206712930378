import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'attendanceList/state',
    initialState: {
        deleteConfirmation: false,
        selectedAttendance: '',
        selectedImage1: '',
        selectedImage2: '',
        newDialog: false,
        imageDialog: false,

    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        setSelectedAttendance: (state, action) => {
            state.selectedAttendance = action.payload
        },
        toggleNewDialog: (state, action) => {
            state.newDialog = action.payload
        },
        setSelectedImage1: (state, action) => {
            state.selectedImage1 = action.payload
        },
        setSelectedImage2: (state, action) => {
            state.selectedImage2 = action.payload
        },
        toggleImageBox: (state, action) => {
            state.imageDialog = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, setSelectedAttendance, toggleNewDialog, setSelectedImage1, setSelectedImage2, toggleImageBox } =
    stateSlice.actions

export default stateSlice.reducer


