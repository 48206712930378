import ApiService from "./ApiService";

export async function apiGetAllEmployeeType(data) {
  //console.log("params", data);
  return ApiService.fetchData({
    url: "/api/employeeType/get",
    method: "post",
    data,
  });
}
export async function apipostEmployeeType(data) {
  return ApiService.fetchData({
    url: "/api/employeeType/create",
    method: "post",
    data,
  });
}

export async function apiputEmployeeType(data) {
  return ApiService.fetchData({
    url: "/api/employeeType/update",
    method: "put",
    data,
  });
}
