import ApiService from "./ApiService";

export async function apiGetAllDepartment(data) {
  return ApiService.fetchData({
    url: "/api/department/get",
    method: "post",
    data,
  });
}

export async function apiPostDepartment(data) {
  return ApiService.fetchData({
    url: "/api/department/create",
    method: "post",
    data,
  });
}

export async function apiPutDepartment(data) {
  return ApiService.fetchData({
    url: "/api/department/update",
    method: "put",
    data,
  });
}

export async function apiGetState(data) {
  return ApiService.fetchData({
    url: "/api/state/getStateData",
    method: "post",
    data,
  });
}
